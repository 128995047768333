(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name ctr.local-entrega.service:LocalEntregaService
     * 
     * @descripition
     * Serviço de local de entrega
     * 
     * @requires $q
     * @requires Restangular
     **/
    angular
        .module('core.local-entrega')
        .service('core.LocalEntregaService', LocalEntregaService);

    LocalEntregaService.$inject = [
        '$q',
        'Restangular',
        'ui.components.EventEmitter'
    ];

    function LocalEntregaService($q,
                                 Restangular,
                                 EventEmitter) {

        var meta;
        var uri = 'locaisentrega';

        var service = new EventEmitter();

        _.extend(service, LocalEntregaService);

        service.get = get;
        service.getDefault = getDefault;
        service.getList = getList;
        service.remove = remove;
        service.save = save;
        service.emitDirty = emitDirty;
        service.getTypes = getTypes;

        return service;

        /**
         * @ngdoc method
         * @name ctr.local-entrega.LocalEntregaService#get
         * @methodOf ctr.local-entrega.service:LocalEntregaService
         * @description Retorna o local de entrega especificado pelo id.
         * @param {Number} id identificação do local de entrega
         * @returns {promise} promise
         **/
        function get(id) {
            return Restangular.one(uri, id).get();
        }

        /**
         * @ngdoc method
         * @name ctr.local-entrega.LocalEntregaService#getList
         * @methodOf ctr.local-entrega.service:LocalEntregaService
         * @description Listagem de locais de entrega
         * @param {string} filter Filtro
         * @returns {promise} promise
         **/
        function getList(params) {
            params = params || {};
            params.offset = params.offset || 0;
            return Restangular.one(uri)
                .get(params);
        }

        /**
         * @ngdoc method
         * @name ctr.local-entrega.LocalEntregaService#getMetadata
         * @methodOf ctr.local-entrega.service:LocalEntregaService
         * @description Retorna os metadados trazidos pelo verbo HTTP OPTIONS
         * @returns {promise} promise
         **/
        function getMetadata() {
            var deferred = $q.defer();
            if (!meta) {
                Restangular.all(uri).options().then(function(data) {
                    meta = data;
                    deferred.resolve(meta);
                }, function(data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }

        /**
         * @ngdoc method
         * @name ctr.local-entrega.LocalEntregaService#getDefault
         * @methodOf ctr.local-entrega.service:LocalEntregaService
         * @description Retorna a representação default de um local de entrega
         * @returns {promise} promise
         **/
        function getDefault() {
            return getMetadata().then(function(data) {
                return angular.copy(data.representation.default);
            });
        }

        /**
         * @ngdoc method
         * @name ctr.local-entrega.LocalEntregaService#getTypes
         * @methodOf ctr.local-entrega.service:LocalEntregaService
         * @description Retorna as especificações dos campos de um local de entrega
         * @returns {promise} promise
         **/
        function getTypes() {
            return getMetadata().then(function(data) {
                return angular.copy(data.types);
            });
        }

        /**
         * @ngdoc method
         * @name ctr.local-entrega.LocalEntregaService#save
         * @methodOf ctr.local-entrega.service:LocalEntregaService
         * @description Salva o local de entrega
         * @param {LocalEntrega} localEntrega Objeto local de entrega para salvar
         * @returns {promise} promise
         **/
        function save(data) {
            var rest = Restangular.all(uri);
            return !data.id ?
                rest.post(data).then(emitDirty) :
                rest.customPUT(data, data.id).then(emitDirty);
        }

        /**
         * @ngdoc method
         * @name ctr.local-entrega.LocalEntregaService#remove
         * @methodOf ctr.local-entrega.service:LocalEntregaService
         * @description Remove o local de entrega
         * @returns {promise} promise
         **/
        function remove(id) {
            return Restangular.one(uri, id)
                .remove()
                .then(emitDirty);
        }

        function emitDirty(data) {
            service.emit('dirty');
            return data;
        }
    }
})();