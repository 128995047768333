(function () {
    'use strict';

    angular
        .module('core.marca')
        .config(configuration);

    configuration.$inject = [
        'bfcStateProvider'
    ];

    function configuration(
        stateProvider) {

        var marca = {
            route: {
                url: '/marca',
                views: {
                    '@main': {
                        templateUrl: 'marca.html',
                        controller: 'core.MarcaController',
                        controllerAs: 'marca'
                    }
                }
            }
        };

        stateProvider
            .from('core')
            .create('core-marca', marca.route);
    }
})();