(function() {
    'use strict';
    /**
     * @ngdoc overview
     * @name contratos-core.unidade-medida
     * @description
     * # contratos-core.unidade-medida
     * Módulo de Unidade de Medida
     */
    angular
            .module('core.moedas', ['core', 'core.commons']);
})();
