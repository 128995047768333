(function () {
    'use strict';
    /**
     * @ngdoc controller
     * @name contratos-core.tipo-comprovante.controller:TipoComprovanteCadController
     * @description Controller do cadastro de tipos de comprovantes
     **/
    angular
            .module('core.tipo-comprovante')
            .controller('core.TipoComprovanteCadController', TipoComprovanteCadController);

    TipoComprovanteCadController.$inject = [
        '$rootScope',
        '$scope',
        '$modalInstance',
        'bfc.Notification',
        'bfc.Focus',
        'core.TrackerFactory',
        'core.TipoComprovanteService',
        'CamposAdicionaisService',
        'CPA_CONSTANTS'
    ];

    function TipoComprovanteCadController(
            $rootScope,
            $scope,
            $modalInstance,
            Notification,
            Focus,
            TrackerFactory,
            TipoComprovanteService,
            CamposAdicionaisService,
            CPA_CONSTANTS
            ) {

        var viewModel = this;

        var metodos = {
            salvar: salvar,
            excluir: excluir,
            desabilitaSalvar: desabilitaSalvar,
            getAcao: getAcao,
            temTipoComprovante: temTipoComprovante,
            addTipo: addTipo
        };

        var propriedades = {
            argumentos: TipoComprovanteCadController.$inject
        };

        _.extend(this, metodos);
        _.extend(this, propriedades);

        inicializaLoadings();
        inicializar();

        function inicializar() {
            initEnums();
            inicializarTipoComprovante();
            inicializaCamposAdicionais();
          viewModel.visivel = 1;
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init([
                'iniciaTipoComprovante',
                'salvar',
                'salvarAdicionar',
                'excluir',
                'CamposAdicionais'
            ]);
        }

        function initEnums() {
            viewModel.tipoComprovantePadrao = TipoComprovanteService.getTipoComprovantePadrao();
        }

        function inicializarTipoComprovante() {
            var promise;
            if ($modalInstance.params.identificador_cadastro) {
                promise = TipoComprovanteService.get($modalInstance.params.identificador_cadastro);
                promise.then(function (tipoComprovante) {
                    viewModel.tipoComprovante = tipoComprovante;
                });
            } else {
                promise = TipoComprovanteService.getDefault();
                promise.then(function (tipoComprovanteDefault) {
                    viewModel.tipoComprovante = tipoComprovanteDefault;
                    viewModel.tipoComprovante.definidaUsuario = true;
                    if ($modalInstance.params.params) {
                        viewModel.tipoComprovante.descricao = $modalInstance.params.params.descricao;
                    }

                });
            }

            viewModel.loadings.iniciaTipoComprovante.addPromise(promise);
            return promise;
        }

        function inicializaCamposAdicionais() {
            var promise = CamposAdicionaisService.get(CPA_CONSTANTS.Cadastros.TIPO_COMPROVANTE, $modalInstance.params.identificador_cadastro);
            promise.then(function(response) {
                viewModel.campoAdicional = response.data;
            });
            return promise;
        }

        function excluir() {
            $rootScope.$broadcast('tipoComprovante:actionRemove', {id: viewModel.tipoComprovante.id});
            fechar();
        }

        function salvar(continuar) {
            var tracker = continuar ? viewModel.loadings.salvarAdicionar : viewModel.loadings.salvar;

            if (viewModel.campoAdicional)
                viewModel.tipoComprovante.campoAdicional = viewModel.campoAdicional;

            tracker.addPromise(TipoComprovanteService.salvar(viewModel.tipoComprovante).then(function (tipoComprovante) {
                if (continuar) {
                    if (viewModel.form) {
                        viewModel.form.$setPristine();
                        viewModel.form.$setUntouched();
                    }
                    $modalInstance.params.identificador_cadastro = undefined;
                    inicializar();
                } else {
                    fechar(tipoComprovante);
                }
                showNotificationSave();
            }));
        }

        function showNotificationSave() {
            var palavra;
            if (temTipoComprovante()) {
                palavra = "alterado";
            } else {
                palavra = "adicionado";
            }
            Notification.publish('Tipo de comprovante ' + palavra + ' com sucesso', 'success');
        }

        function desabilitaSalvar() {
            return (viewModel.form.$dirty && viewModel.form.$invalid)
                || (viewModel.form.$pristine && !temTipoComprovante())
                || !CamposAdicionaisService.isFormValid(viewModel.campoAdicional);
        }

        function fechar(tipoComprovante) {
            $modalInstance.close(tipoComprovante);
        }

        function getAcao() {
            return $modalInstance.params.identificador_cadastro ? "Editando" : "Adicionando";
        }

        function temTipoComprovante() {
            return $modalInstance.params.identificador_cadastro || (viewModel.tipoComprovante && viewModel.tipoComprovante.id);
        }

        function addTipo(tipoComprovante){
            viewModel.tipoComprovante.idTipoCtb = tipoComprovante.id;
            viewModel.tipoComprovante.descricao = tipoComprovante.descricao;
            viewModel.tipoComprovante.tipo = tipoComprovante.tipo.key;
        }
    }
})();
