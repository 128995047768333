(function() {
    'use strict';

    /**
     * @ngdoc overview
     * @name contratos-core.local-entrega
     * @module contratos-core.local-entrega
     *
     * @description
     * Módulo de Local de Entrega.
     *
     * @example
     * ```
     * angular
     *      .module('contratos-core.local-entrega', []);
     * ```
     *
     */
    angular
            .module('core.local-entrega', ['core', 'core.commons']);
})();
