(function() {
	'use strict';
	/**
	 * @ngdoc overview
	 * @name contratos-core.material
	 * @description
	 * # contratos-core.material
	 * Módulo de materiais
	 **/

	angular
			.module('core.material', ['core', 'core.commons', 'core.grupos', 'core.unidade-medida', 'core.natureza-despesa', 'core.classes']);
})();
