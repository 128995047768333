(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name contratos-core.prazo-entrega.service:PrazoEntregaCadService
   * @description Serviço para abrir o cadastro de Prazos de entrega
   * @requires bfc.dialog.Dialog
   **/
  angular
    .module('core.prazo-entrega')
    .service('core.PrazoEntregaCadService', PrazoEntregaCadService);

  PrazoEntregaCadService.$inject = ['core.AcoesCadastrosModalService'];

  function PrazoEntregaCadService(AcoesCadastrosModalService) {

    var modal = {
      templateUrl: 'prazo-entrega-cad/prazo-entrega-cad.html',
      controller: 'core.PrazoEntregaCadController',
      controllerAs: 'vm',
      params: {
        identificador_cadastro: null
      }
    };

    var service = {
      cadastrar: cadastrar,
      editar: editar,
      onAdd: onAdd
    };

    angular.extend(this, service);

    function abrir(modal) {
      return AcoesCadastrosModalService.abrir(modal);
    }

    function cadastrar() {
      AcoesCadastrosModalService.cadastrar(modal);
    }

    function editar(tipoDocumento) {
      AcoesCadastrosModalService.editar(modal, tipoDocumento);
    }

    function onAdd(params) {
      return AcoesCadastrosModalService.onAdd(modal, params);
    }

  }
})();
