(function () {
  'use strict';

  angular.module('core.material')
    .directive('coreSelectNaturezaDespesaMaterial', selectNaturezaDespesaDirective);

  function selectNaturezaDespesaDirective() {
    var directive = {
      restrict: 'E',
      templateUrl: 'material-cad/select-natureza-despesa.directive.html',
      scope: {
        ngModel: '=ngModel',
        bfRequired: '@?',
        onSelect: '&',
        bfmask: '=?',
        placeholder: '=?'
      },
      controller: SelectNaturezaDespesaCtrl,
      controllerAs: 'SelectNaturezaDespesaCtrl'
    };

    SelectNaturezaDespesaCtrl.$inject = [
      '$injector',
      '$scope',
      'core.MaterialService'];

    function SelectNaturezaDespesaCtrl($injector,
                                       $scope,
                                       MaterialService) {

      var viewModel = this;

      viewModel.bfRequired = $scope.bfRequired == 'true';

      var propriedadesPublicas = {
        search: _search,
        select: _select,
        inputKeyUp: _inputKeyUp,
        numeroKeyUp: _numeroKeyUp,
        zeroFill: _zeroFill
      };

      _.extend(viewModel, propriedadesPublicas);

      _search();

      function _zeroFill() {
        if ($scope.ngModel && $scope.ngModel.numeroNaturezaDespesa) {
          var value = $scope.ngModel.numeroNaturezaDespesa;

          $scope.ngModel.numeroNaturezaDespesa =
            $scope.ngModel.numeroNaturezaDespesa
            + new Array((14 - String(value).length) + (/\./.test(value) ? 2 : 1)).join('0');
        }else{
          $scope.$parent.cadastro.material.materialComplemento.naturezaDespesaContabil.descricao = undefined;
        }
      }

      function _search() {
        viewModel.naturezas = [];

        if (!$scope.ngModel || !$scope.ngModel.numeroNaturezaDespesa)
          return;

        viewModel.showAutoComplete = true;

        var params = {
          filter: 'numero like "%' + $scope.ngModel.numeroNaturezaDespesa + '%"'
        };

        return MaterialService.getListNaturezaDespesa(params).then(function (naturezas) {
          viewModel.naturezas = naturezas.content;
          if (!viewModel.naturezas.length) {
            viewModel.showAutoComplete = false;
            $scope.$parent.cadastro.material.materialComplemento.naturezaDespesaContabil.descricao = undefined;
          }
        });
      }

      function _select(natureza) {
        if ($scope.onSelect) {
          $scope.onSelect({natureza: natureza});
        }

        viewModel.showAutoComplete = false;
      }

      function _inputKeyUp($event) {
        $event.preventDefault();
        if ($event.keyCode === 40 || $event.keyCode === 38) {
          $('.list-unstyled li:first-child').find('a').focus();
        }
      }

      function _numeroKeyUp($event) {
        $event.preventDefault();
        if ($event.keyCode === 40) {
          $($event.target).parent().next().find('a').focus();
        } else if ($event.keyCode === 38) {
          $($event.target).parent().prev().find('a').focus();
        }
      }
    }

    return directive;
  }
})();
