(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name contratos-core.marca.MarcaSelect
     * @descripition
     * Serviço de cargo do Select2
     * @requires bfc.Select2Config
     * @requires MarcaService
     **/
    angular
        .module('core.marca')
        .service('core.MarcaSelect', MarcaSelect);

    MarcaSelect.$inject = [
        'bfc.Select2',
        'core.MarcaService',
        'CONFIG_SELECT2'
    ];

    function MarcaSelect(Select2,
                         MarcaService,
                         CONFIG_SELECT2) {

        angular.extend(this, {
            select2Config: select2Config
        });

        function select2Config(options) {

            var methods = {
                formatValue: formatValue,
                formatResult: formatResult,
                formatSelection: formatSelection,
                initSelection: initSelection,
                id: id,
                result: _.debounce(result, CONFIG_SELECT2.DELAY),
                minimumInputLength: CONFIG_SELECT2.MINIMUMINPUT,
                maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT('MARCA'),
                onAdd: onAdd,
                dropdownAutoWidth: false
            };

            function formatResult(marca) {
                return marca.text ? marca.text : marca.descricao;
            }

            function formatSelection(marca) {
                return marca.descricao;
            }

            function formatValue(marca) {
                if (marca && marca.plain) {
                    return marca.plain();
                }
                return marca;
            }

            function id(marca) {
                return marca.descricao ? marca.descricao : marca.id;
            }

            function initSelection(element, callback) {
                var marca = {
                    descricao: element.val()
                };
                callback(marca);
            }

            function result(params, callback) {
                var filter = '(descricao like "' + encodeURI(params.term) + '")',
                    result = MarcaService.getList(filter);

                result.then(function (marcas) {
                    callback({
                        results: marcas,
                        more: marcas.hasNext
                    });
                });
                return result;
            }

            function onAdd(value, target) {
                Select2.set(target, value);
                return false;
            }

            return Select2.create(angular.extend(methods, options));
        }
    }
})();