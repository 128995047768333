(function () {
	'use strict';

	/**
	 * @ngdoc service
	 * @name contratos-core.material.ClassificacaoMaterialSelect
	 * @descripition
	 * Serviço do select2 para classificação do material
	 * @requires bfc.Select2Config
	 * @requires ClassificacaoMaterialSelect
	 **/
	angular
		.module('core.material')
		.service('core.ClassificacaoMaterialSelect', ClassificacaoMaterialSelect);

	ClassificacaoMaterialSelect.$inject = [
		'$q',
		'bfc.Select2',
		'core.MaterialService',
		'CONFIG_SELECT2'
	];

	function ClassificacaoMaterialSelect($q,
								 Select2,
								 MaterialService,
								 CONFIG_SELECT2) {

		this.select2Config = select2Config;

		function select2Config(options) {
			var methods = {
				id: options.id ? options.id : id,
				formatResult: options.formatResult ? options.formatResult : formatResult,
				formatSelection: options.formatSelection ? options.formatSelection : formatSelection,
				formatValue: options.formatValue ? options.formatValue : formatValue,
				initSelection: options.initSelection ? options.initSelection : initSelection,
				result: _.debounce(options.result ? options.result : result, CONFIG_SELECT2.DELAY),
				maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT(),
				dropdownAutoWidth: false
			};

			function id(classificacao) {
				return classificacao.description ? classificacao.description : classificacao.key;
			}

			function formatResult(classificacao) {
				return classificacao.description;
			}

			function formatSelection(classificacao) {
				return classificacao && classificacao.description;
			}

			function formatValue(classificacao) {
				return classificacao.key;
			}

			function initSelection(element, callback) {
				var id = element.val();

				if (id) {
					MaterialService.getClassificacao(id).then(callback);
				}
			}

			function result(params, callback) {
				var filter = params && params.term;
				var result = MaterialService.getClassificacoes(filter);
				result.then(function (classificacoes) {
					callback({
						results: classificacoes,
						more: classificacoes.hasNext
					});
				});
				return result;
			}

			return Select2.create(angular.extend(methods, options));
		}
	}
})();
