(function () {
    'use strict';

    angular.module('core.responsavel')
        .directive('coreResponsavelSelect', ResponsavelSelectDirective);

    ResponsavelSelectDirective.$inject = ['CONFIG_SELECT2'];

    function ResponsavelSelectDirective(CONFIG_SELECT2) {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-disabled="ngDisabled" bf-required="bfRequired" ng-change="ngChange()" search="vm.search" format-result="vm.formataResultado(data)"></ui-select>',
            scope: {
                ngModel: '=ngModel',
                ngDisabled: '=?',
                bfRequired: '=?',
                hiddenAddValue: '=?',
                ngChange: '&?',
                criterionSearch: '=?',
                multiple: '=?',
                showcomplementoFuncao: '=?',
                responsaveisAtivos: '=?'
            },
            compile: function (element, attributes) {
                var addValue = attributes.hiddenAddValue === 'true';
                if (!addValue) {
                    element.children('ui-select').attr('add-value', 'vm.onAdd');
                }

                element.children('ui-select').attr('bf-required', attributes.bfRequired === 'true' ? 'true' : 'false');

                if (attributes.multiple === 'true') {
                    element.children('ui-select').attr('multiple', 'true');
                }
                element.children('ui-select').attr('minimum-input-length', CONFIG_SELECT2.MINIMUMINPUT);
                element.children('ui-select').attr('debounce-delay', CONFIG_SELECT2.DELAY);
                element.children('ui-select').attr('maximum-input-length', CONFIG_SELECT2.MAXIMUMINPUT('RESPONSAVEL'));
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'bfc.$$PermissionsService',
        'core.ResponsavelService',
        'core.ResponsavelCadModalService',
        '$filter'
    ];

    function Controller($scope, PermissionsService, service, modal, $filter) {
        var vm = this;
        vm.search = search;
        vm.formataResultado = formataResultado;
        vm.onAdd = !PermissionsService.isRevokedOperation('ResponsavelPageMapping', 'criar') && onAdd;
        vm.params = $scope.criterionSearch;
        vm.mostracomplementoFuncao = $scope.showcomplementoFuncao === 'true' ? 'true' : 'false';

        vm.responsaveisAtivos = $scope.responsaveisAtivos === true ? true : false;

        function search(params) {
            var paramsDefault = '(nome like "' + params.term + '" or cpf like "' + params.term + '")';

            params.filter = $scope.criterionSearch ? vm.params({termo: params.term}) : paramsDefault;
            if(vm.responsaveisAtivos && vm.responsaveisAtivos == true){
                params.filter = params.filter + " and ativo = true";
            }

            params.total = false;

            debugger;
            return service.getList(params).then(function (data) {
                return data;
            });
        }

        function formataResultado(value) {
            if (value.pessoa) {
                var nomeCpf = value.pessoa.nome + ' (' + $filter('bfMaskCpfCnpj')(value.pessoa.cpfCnpj) + ')',
                    funcaoComplemento = (value.complementoFuncao || value.funcao) ? ' - ' + formatacomplementoFuncao(value) : '',
                    resultadocomplementoFuncao = nomeCpf + funcaoComplemento;
                return vm.mostracomplementoFuncao ? resultadocomplementoFuncao : nomeCpf;
            }
        }

        function formatacomplementoFuncao(value){
            var funcao = value.funcao ? (value.complementoFuncao ? value.funcao + ' - ' : value.funcao) : '',
                complemento = value.complementoFuncao ? value.complementoFuncao : '',
                resultado = funcao + complemento;
            return resultado;
        }

        function onAdd(nome) {
            var param = {
                nome: nome
            };
            return modal.onAdd(param);
        }
    }
})();
