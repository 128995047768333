(function () {
  'use strict';

  /**
   * @ngdoc controller
   * @name contratos-core.marca.controller:MarcaController
   * @description
   * MarcaController de contratos-core.marca
   * @requires $scope
   * @requires promiseTracker
   * @requires bfc.dialog.Dialog
   * @requires MarcaService
   */

  angular
    .module('core.marca')
    .controller('core.MarcaController', MarcaController);

  MarcaController.$inject = [
    '$scope',
    'promiseTracker',
    '$filter',
    'core.MarcaCadService',
    'core.MarcaService',
    'bfc.Notification'
  ];

  function MarcaController($scope,
                           promiseTracker,
                           $filter,
                           MarcaCadService,
                           MarcaService,
                           Notification) {

    var viewModel = this;

    //Usado para teste Unitário
    viewModel.argumentos = MarcaController.$inject;

    viewModel.add = add;
    viewModel.cancel = close;
    viewModel.close = close;
    viewModel.edit = edit;
    viewModel.remove = remove;
    viewModel.isEmptyList = isEmptyList;
    viewModel.getShowClass = getShowClass;
    viewModel.clean = clean;
    viewModel.isSearchEmpty = isSearchEmpty;
    viewModel.hasProblem = hasProblem;
    viewModel.emUso = emUso;
    viewModel.marcasFiltradas = marcasFiltradas;
    viewModel.hasResultSearch = hasResultSearch;
    viewModel.isListVisible = isListVisible;

    init();

    /*
     * Função inicializadora do controller
     */
    function init() {
      viewModel.tracker = {};
      viewModel.marcas = [];
      viewModel.search = {};
      initMarcaModal();
      initTrackers();
      initListeningEvents();
      marcas();
    }

    function initListeningEvents() {
      listeningMarcaCadList();
      $scope.$on('marca:actionRemove', function(eventos, params){
        angular.element('#btnMarcaControllerExcluir-' + params.id).triggerHandler('click');
        //TODO remover quando tiver paginação
        angular.element('#hrefActionExcluir-' + params.id).triggerHandler('click');
      });
    }

    function listeningMarcaCadList() {
      $scope.$on('marcaCad:list', function (eventos, marca) {
        var marcaExist = _.find(viewModel.marcas, {
          'id': marca.id
        });

        if (angular.isUndefined(marcaExist)) {
          viewModel.marcas.push(marca);
        } else {
          angular.copy(marca, marcaExist);
        }
      });
      $scope.$on('marcaCad:UpdateList', function (eventos, marca) {
        marcas();
      });
      $scope.$on('marcaCadController:UpdateList', function (eventos, args) {
        actionRemove(args);
      });
    }

    function initMarcaModal() {
      viewModel.marcaModal = {
        templateUrl: 'marca-cad/marca-cad.html',
        controller: 'core.MarcaCadController as marcaCad',
        resolve: {
          MarcaId: null
        }
      };
    }

    function marcas() {
      var promise = MarcaService.getList();
      promise.then(function (data) {
        viewModel.marcas = data;
      });
      viewModel.tracker.marcas.addPromise(promise);
    }

    function initTrackers() {
      viewModel.tracker.marcas =
        promiseTracker();
    }

    function actionRemove(marca) {
      if (marca.id) {
        viewModel.marcas = _.reject(viewModel.marcas, {
          'id': marca.id
        });
      }
    }

    function isEmptyList() {
      return viewModel.marcas.length === 0;
    }

    function getShowClass() {
      return {
        show: viewModel.search.value
      };
    }

    function hasResultSearch() {
      return viewModel.marcas && viewModel.marcas.length > 0;
    }

    function clean() {
      viewModel.search.value = '';
    }

    function isListVisible() {
      return viewModel.marcasFiltradas && viewModel.marcasFiltradas.length > 0;
    }

    function marcasFiltradas(searchValue) {
      return $filter('filter')(viewModel.marcas, searchValue) === 0;
    }

    function isSearchEmpty() {
      return viewModel.marcasFiltradas && viewModel.marcasFiltradas.length === 0 && isSearching();
    }

    function isSearching() {
      return !!(viewModel.search && (viewModel.search.filter || viewModel.search.value));
    }

    function hasProblem(marca) {
      return marca.situacaoCadastral === 1;
    }

    /*
     * Operações
     */
    function add() {
      MarcaCadService.create();
    }

    function edit(marca) {
      MarcaCadService.edit({
        resolve: {
          MarcaId: marca.id
        }
      });
    }

    function remove(marca) {
      if (marca.id) {
        var promise = MarcaService.remove(marca.id);
        return promise.then(function () {
          Notification.publish('Marca excluída com sucesso', 'success');
          marcas();
        }, function error() {
          return promise;
        });
      }
    }

    function emUso(uso) {
      return uso;
    }
  }
})();
