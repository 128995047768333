(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name contratos-core.material.TipoSelect
   * @descripition
   * Serviço do select2 para o tipo do material
   * @requires bfc.Select2Config
   * @requires TipoSelect
   **/
  angular
    .module('core.material')
    .service('core.TipoSelect', TipoSelect);

  TipoSelect.$inject = [
    '$q',
    'bfc.Select2',
    'core.MaterialService',
    'CONFIG_SELECT2'
  ];

  function TipoSelect($q,
                      Select2,
                      MaterialService,
                      CONFIG_SELECT2) {

    this.select2Config = select2Config;

    function select2Config(options) {
      var methods = {
        id: options.id ? options.id : id,
        formatResult: options.formatResult ? options.formatResult : formatResult,
        formatSelection: options.formatSelection ? options.formatSelection : formatSelection,
        formatValue: options.formatValue ? options.formatValue : formatValue,
        initSelection: options.initSelection ? options.initSelection : initSelection,
        result: _.debounce(options.result ? options.result : result, CONFIG_SELECT2.DELAY),
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT(),
        dropdownAutoWidth: false
      };

      function id(tipo) {
        return tipo.description ? tipo.description : tipo.key;
      }

      function formatResult(tipo) {
        return tipo.description;
      }

      function formatSelection(tipo) {
        return tipo && tipo.description;
      }

      function formatValue(tipo) {
        return tipo.key;
      }

      function initSelection(element, callback) {
        var id = element.val();

        if (id) {
          MaterialService.getTipo(id).then(callback);
        }
      }

      function result(params, callback) {
        var filter = params && params.term;
        var result = MaterialService.getTipos(filter);
        result.then(function (tipos) {
          callback({
            results: tipos,
            more: tipos.hasNext
          });
        });
        return result;
      }

      return Select2.create(angular.extend(methods, options));
    }
  }
})();
