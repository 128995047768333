(function() {
	'use strict';
	/**
	 * @ngdoc overview
	 * @name contratos-core.responsavel
	 * @description
	 * # contratos-core.responsavel
	 * Módulo reponsável
	 **/
	angular
			.module('core.responsavel', ['core', 'core.commons', 'core.cargo']);
})();
