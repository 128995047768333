(function () {
    'use strict';
    /**
     * @ngdoc service 
     * @name contratos-core.tipo-comprovante.service:TipoComprovanteModalService
     * @description Serviço para abrir o cadastro de tipos de comprovantes
     * @require ModalCadFactory
     **/
    angular
            .module('core.tipo-comprovante')
            .service('core.TipoComprovanteModalService', TipoComprovanteModalService);

    TipoComprovanteModalService.$inject = ['core.AcoesCadastrosModalService'];

    function TipoComprovanteModalService(AcoesCadastrosModalService) {

        var modal = {
            templateUrl: 'tipo-comprovante-cad/tipo-comprovante-cad.html',
            controller: 'core.TipoComprovanteCadController',
            controllerAs: 'tipoComprovanteCad',
            params: {
                identificador_cadastro: null
            },
            size: 'lg'
        };

        var service = {
            cadastrar: cadastrar,
            editar: editar,
            onAdd: onAdd
        };

        angular.extend(this, service);

        function abrir(modal) {
            return AcoesCadastrosModalService.abrir(modal);
        }

        function cadastrar() {
            AcoesCadastrosModalService.cadastrar(modal);
        }

        function editar(tipoComprovante) {
            AcoesCadastrosModalService.editar(modal, tipoComprovante);
        }

        function onAdd(params) {
            return AcoesCadastrosModalService.onAdd(modal, params);
        }

    }
})();