(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name ctr.prazo-entrega.service:LocalEntregaCadService
     *
     * @description
     * Serviço para abrir o cadastro de local de entrega
     *
     * @requires ModalCadFactory
     **/
    angular
            .module('core.local-entrega')
            .service('core.LocalEntregaCadService', LocalEntregaCadService);

    LocalEntregaCadService.$inject = ['core.ModalCadFactory'];

    function LocalEntregaCadService(ModalCadFactory) {

        var modal = {
            templateUrl: 'local-entrega-cad/local-entrega-cad.html',
            controller: 'core.LocalEntregaCadController as localEntregaCad',
            resolve: {
                localEntregaId: null
            }
        };

        var service = {
            cadastrar: cadastrar,
            editar: editar
        };

        angular.extend(this, service);

        function abrir(modal) {
            ModalCadFactory.open(modal);
        }

        function copia(modal) {
            return angular.copy(modal);
        }

        function cadastrar(localEntrega, optionsCad) {
            var m = copia(modal);

            if (localEntrega)
                m.resolve = {
                    localEntrega: localEntrega
                };

            if (optionsCad)
                m.optionsCad = optionsCad;
            abrir(m);
        }

        function editar(localEntrega) {
            var m = copia(modal);
            m.resolve = {
                localEntregaId: localEntrega.id
            };
            abrir(m);
        }
    }
})();
