(function () {
  'use strict';

  /**
   * @ngdoc controller
   * @name contratos-core.cargo.controller:CargoCadController
   *
   * @description
   * Controller responsável pelo cadastro de modelos de documentos
   *
   * @requires $rootScope
   * @requires $q
   * @requires promiseTracker
   * @requires $modalInstance
   * @requires $modalTracker
   * @requires bfc.FocusCtrl
   * @requires OrgaosExternosService
   * @requires OrgaosExternosId
   **/
  angular
    .module('core.cargo')
    .controller('core.CargoCadController', CargoCadController);

  CargoCadController.$inject = [
    '$rootScope',
    '$q',
    '$modalInstance',
    '$modalTracker',
    'core.TrackerFactory',
    'bfc.FocusCtrl',
    'bfc.Notification',
    'core.CargoService',
    'CamposAdicionaisService',
    'CPA_CONSTANTS'
  ];

  function CargoCadController($rootScope,
                              $q,
                              $modalInstance,
                              $modalTracker,
                              TrackerFactory,
                              Focus,
                              Notification,
                              CargoService,
                              CamposAdicionaisService,
                              CPA_CONSTANTS) {
    var viewModel = this;

    viewModel.cancelar = cancelar;
    viewModel.estaAdicionando = estaAdicionando;
    viewModel.salvar = salvar;
    viewModel.existeCargo = existeCargo;
    viewModel.excluir = excluir;
    viewModel.formataResultado = formataResultado;
    viewModel.desabilitaSalvar = desabilitaSalvar;

    inicializaLoadings();
    inicializar();

    function inicializar() {
      viewModel.cargo = {};
      inicializaCargo();
      inicializaCamposAdicionais();
      viewModel.visivel = 1;
    }

    function inicializaCargo() {
      var promise;
      if ($modalInstance.params.CargoId) {
        promise = CargoService.get($modalInstance.params.CargoId);
        promise.then(function (novoCargo) {
          viewModel.cargo = novoCargo;
        });
      } else {
        promise = CargoService.getDefault();
        promise.then(function (novoCargo) {
          viewModel.cargo = novoCargo;
          if($modalInstance.params.params){
           viewModel.cargo.nome = $modalInstance.params.params.nome;
            viewModel.form.$setDirty();
           }
        });
      }
      viewModel.loadings.cargo.addPromise(promise);
    }

    function inicializaLoadings() {
      viewModel.loadings = TrackerFactory.init([
        'salvar',
        'salvarAdicionar',
        'excluir',
        'cargo',
        'camposAdicionais'
      ]);
    }

    function inicializaCamposAdicionais() {
      var id = ($modalInstance.params.CargoId) ? $modalInstance.params.CargoId : null;
      if(CPA_CONSTANTS.Cadastros.CARGO) {
        var promise = CamposAdicionaisService.get(CPA_CONSTANTS.Cadastros.CARGO, id);
        promise.then(function (response) {
          viewModel.campoAdicional = response.data;
        });
        return promise;
      }else{
        viewModel.campoAdicional = undefined;
      }
    }

    function cancelar(cargo) {
      $modalInstance.close(cargo);
    }

    function estaAdicionando() {
      return temCargo() ? 'Editando' : 'Adicionando';
    }

    function temCargo() {
      return (viewModel.cargo.id === undefined) ? $modalInstance.params.CargoId : viewModel.cargo.id;
    }

    function salvar(continuar) {
      var tracker = continuar ? viewModel.loadings.salvarAdicionar : viewModel.loadings.salvar;

      if (viewModel.campoAdicional)
        viewModel.cargo.campoAdicional = viewModel.campoAdicional;

      tracker.addPromise(
        CargoService.save(viewModel.cargo).then(function (cargo) {
          if (continuar) {
            if (viewModel.form) {
              viewModel.form.$setPristine();
              viewModel.form.$setUntouched();
              $modalInstance.params.params = {};
            }
            $modalInstance.params.CargoId = null;
            inicializar();
            Focus.set('cargoCadNome');
          } else {
            cancelar(cargo);
          }
          var mensagem = $modalInstance.params.CargoId ? 'alterado' : 'adicionado';
          Notification.publish('Cargo ' + mensagem + ' com sucesso', 'success');
        })
      );
    }

    function excluir() {
      $rootScope.$broadcast('cargo:actionRemove', { id: viewModel.cargo.id });
      cancelar();
    }

    function existeCargo() {
      return Boolean(temCargo());
    }

    function formataResultado(value) {
      return value.descricao;
    }

    function desabilitaSalvar() {
      return (viewModel.form.$dirty && viewModel.form.$invalid)
            || (viewModel.form.$pristine && !existeCargo())
            || !CamposAdicionaisService.isFormValid(viewModel.campoAdicional);
    }
  }
})();
