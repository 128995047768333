(function() {
	'use strict';

	/**
	 * @ngdoc service
	 * @name ctr.especificacao.service:EspecificacaoService
	 *
	 * @description
	 * Serviço que se comunica com o Back-end de especificações de materiais
	 *
	 * @requires $q
	 * @requires Restangular
	 **/
	angular
			.module('core.especificacao')
			.service('core.EspecificacaoService', EspecificacaoService);

	EspecificacaoService.$inject = ['$q', 'Restangular'];

	function EspecificacaoService($q, Restangular) {
		var meta;

		var service = this;
		var uri = ['materiais', 'especificacoes', 'especificacaounica'];

		this.material = undefined;

		this.get = get;
		this.getList = getList;
		this.getDefault = getDefault;
		this.save = save;
		this.getTypes = getTypes;
		this.remove = remove;
		this.getEspecificacaoUnica = getEspecificacaoUnica;

		function getMaterialId(materialId) {
			if (materialId)
				return materialId;
			else
				return service.materialId;
		}

		function get(materialId, id) {
			return Restangular.one(uri[0], materialId).one(uri[1], id).get();
		}

		function getList(materialId, params) {
			return Restangular.one(uri[0], getMaterialId(materialId)).all(uri[1]).getList(params);
		}		

		function getMetadata() {
			var deferred = $q.defer();
			if (!meta) {
				Restangular.all(uri[1]).options().then(function(data) {
					meta = data;
					deferred.resolve(meta);
				}, function(data) {
					deferred.reject(data);
				});
			} else {
				deferred.resolve(meta);
			}
			return deferred.promise;
		}

		function getDefault() {
			return getMetadata().then(function(data) {
				return angular.copy(data.representation.default);
			});
		}

		function getTypes() {
			return getMetadata().then(function(data) {
				return angular.copy(data.types);
			});
		}

		function save(materialId, data) {
			var rest = Restangular.one(uri[0], getMaterialId(materialId)).all(uri[1]);
			return !data.id ?
					rest.post(data) :
					rest.customPUT(data, data.id);
		}

		function getEspecificacaoUnica(materialId) {
			return Restangular.one(uri[0], materialId).one(uri[2]).get();
		}

		function remove(materialId, id) {
			return Restangular.one(uri[0], getMaterialId(materialId)).one(uri[1], id).remove();
		}
	}
})();
