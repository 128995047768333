(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name contratos-core.prazo-entrega.PrazoEntregaService
   * @descripition
   * Serviço que consome API Rest de Prazos de Entrega
   * @requires Restangular
   * @requires $q
   **/
  angular
    .module('core.prazo-entrega')
    .service('core.PrazoEntregaService', PrazoEntregaService);

  PrazoEntregaService.$inject = [
    '$q',
    'Restangular',
    'core.UtilsService',
    'ui.components.EventEmitter'
  ];

  function PrazoEntregaService($q,
                               Restangular,
                               UtilsService,
                               EventEmitter) {
    var meta;
    var uri = ['prazosentrega'];
    var service = new EventEmitter();

    _.extend(service, PrazoEntregaService);

    service.get = get;
    service.getEnums = getEnums;
    service.getList = getList;
    service.getTipoExecucao = getTipoExecucao;
    service.getTiposExecucao = getTiposExecucao;
    service.getTypes = getTypes;
    service.getRepresentation = getRepresentation;
    service.save = save;
    service.remove = remove;

    return service;

    function get(id) {
      return Restangular.one(uri[0], id).get();
    }

    function getList(params) {
      params = params || {};

      params.offset = params.offset || 0;
      return Restangular.one(uri[0])
        .get(params);
    }

    function getMetadata() {
      var deferred = $q.defer();
      if (!meta) {
        Restangular.all(uri[0]).options().then(function (data) {
          meta = data;
          deferred.resolve(meta);
        }, function (data) {
          deferred.reject(data);
        });
      } else {
        deferred.resolve(meta);
      }
      return deferred.promise;
    }

    function getRepresentation() {
      return getMetadata().then(function (data) {
        return angular.copy(data.representation);
      });
    }

    function getTipoExecucao(key) {
      return getEnums().then(function (enums) {
        return UtilsService.getValueFromKey(enums.TipoExecucaoEnum.values, key);
      });
    }

    function getTiposExecucao(filter) {
      return getEnums().then(function (enums) {
        return UtilsService.getFilteredValues(enums.TipoExecucaoEnum.values, filter);
      });
    }

    function getTypes() {
      return getMetadata().then(function (data) {
        return angular.copy(data.types);
      });
    }

    function getEnums() {
      return getMetadata().then(function (data) {
        return angular.copy(data.enums);
      });
    }

    function save(data) {
      var rest = Restangular.all(uri[0]);
      return !data.id ?
        rest.post(data).then(emitDirty) :
        rest.customPUT(data, data.id).then(emitDirty);
    }

    function remove(data) {
      return Restangular.one(uri, data.id).remove().then(emitDirty);
    }

    function emitDirty(data) {
      service.emit('dirty');
      return data;
    }
  }
})();
