  (function () {
  'use strict';

  angular.module('core.moedas')
    .directive('coreMoedasSelect', moedasSelect);

    moedasSelect.$inject = ['CONFIG_SELECT2'];

  function moedasSelect(CONFIG_SELECT2) {
    return {
      restrict: 'E',
      template: '<ui-select ng-model="ngModel" bf-required="bfRequired" search="vm.search" ng-disabled="ngDisabled" format-result="vm.formatResult(data)"></ui-select>',
      scope: {
        ngModel: '=ngModel',
        bfRequired: '=?',
        ngDisabled: '=?',
        criterionSearch: '=?'
      },
      compile: function (element, attributes) {
        element.children('ui-select').attr('debounce-delay', CONFIG_SELECT2.DELAY);
        element.children('ui-select').attr('minimum-input-length', CONFIG_SELECT2.MINIMUMINPUT);
        element.children('ui-select').attr('maximum-input-length', CONFIG_SELECT2.MAXIMUMINPUT('MOEDAS'));
      },
      controller: Controller,
      controllerAs: 'vm'
    };
  }

  Controller.$inject = [
    '$scope',
    'core.MoedasService'
  ];

  function Controller($scope, service) {
    var vm = this;
    vm.search = search;
    vm.formatResult = formatResult;
    vm.params = $scope.criterionSearch;

    function search(params) {
      var paramsDefault = '(nome like "' + params.term + '")';
      params.filter = $scope.criterionSearch ? vm.params({termo: params.term}) : paramsDefault;
      return service.getList(params).then(function (data) {
        return data;
      });
    }

    function formatResult(value) {
      return value.nome;
    }
  }
})();
