(function() {
    'use strict';

    /**
     * @ngdoc controller
     * @name ctr.local-entrega.controller:LocalEntregaCadController
     *
     * @description
     * Controller responsável pelo cadastro de local de entrega
     *
     * @requires $rootScope
     * @requires $scope
     * @requires $modalInstance
     * @requires $injector
     * @requires $filter
     * @requires $modalTracker
     * @requires $q
     **/
    angular
        .module('core.local-entrega')
        .controller('core.LocalEntregaCadController', LocalEntregaCadController);

    LocalEntregaCadController.$inject = [
        '$rootScope',
        '$scope',
        '$modalInstance',
        '$injector',
        '$filter',
        '$modalTracker',
        '$q'
    ];

    function LocalEntregaCadController($rootScope,
                                       $scope,
                                       $modalInstance,
                                       $injector,
                                       $filter,
                                       $modalTracker,
                                       $q) {

        var viewModel = this;

        var TrackerFactory = $injector.get('core.TrackerFactory'),
            LocalEntregaService = $injector.get('core.LocalEntregaService'),
            Notification = $injector.get('bfc.Notification');

        inicializaLoadings();
        inicializa();

        function inicializa() {
            inicializaMetodos();
            inicializaPropriedades();
            inicializaCadastro();
            inicializaPromises();
        }

        function inicializaPropriedades() {
            viewModel.argumentos = LocalEntregaCadController.$inject;
            viewModel.representation = {};
            viewModel.types = {};
            viewModel.localEntrega = {};
        }

        function inicializaMetodos() {
            viewModel.situacaoCadastro = situacaoCadastro;
            viewModel.desabilitaSalvar = desabilitaSalvar;
            viewModel.emUso = emUso;
            viewModel.salvar = salvar;
            viewModel.existeLocalEntrega = existeLocalEntrega;
            viewModel.excluir = excluir;
        }

        function inicializaPromises() {
            var promise = $q.all([
                inicializaTypes(),
                inicializaCadastro()
            ]);
            viewModel.loadings.inicializaCadastro.addPromise(promise);
            $modalTracker.addPromise(promise);
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init([
                'salvar',
                'salvarAdicionar',
                'inicializaCadastro',
                'cadastro',
                'excluir'
            ]);
        }

        function inicializaCadastro() {
            var promise;
            if ($modalInstance.params.identificador_cadastro) {
                promise = LocalEntregaService.get($modalInstance.params.identificador_cadastro);
                promise.then(function (localEntrega) {
                    viewModel.localEntrega = localEntrega.plain();
                });
            } else {
                promise = LocalEntregaService.getDefault();
                promise.then(function (localEntrega) {
                    viewModel.localEntrega = localEntrega;
                    if($modalInstance.params.params){
                        viewModel.localEntrega.descricao = $modalInstance.params.params.descricao;
                        viewModel.form.$setDirty();
                    }
                });
            }
            viewModel.loadings.inicializaCadastro.addPromise(promise);
            return promise;
        }

        function excluir() {
            $rootScope.$broadcast('localEntrega:actionRemove', {id: viewModel.localEntrega.id});
            cancelar();
        }

        function inicializaTypes() {
            var promise = LocalEntregaService.getTypes();
            promise.then(function(types) {
                viewModel.types = types;
            });
            return promise;
        }

        function desabilitaSalvar() {
            return (viewModel.form.$dirty && viewModel.form.$invalid) || viewModel.form.$pristine;
        }

        function situacaoCadastro() {
            return existeLocalEntrega() ? 'Editando' : 'Adicionando';
        }

        function existeLocalEntrega() {
            return Boolean((viewModel.localEntrega.id === undefined) ? $modalInstance.params.identificador_cadastro : viewModel.localEntrega.id);
        }

        function emUso() {
            return viewModel.localEntrega && viewModel.localEntrega.emUso;
        }

        function cancelar(item) {
            $modalInstance.close(item);
        }

        function salvar(continuar) {
            var tracker = continuar ? viewModel.loadings.salvarAdicionar : viewModel.loadings.salvar;

            tracker.addPromise(
                LocalEntregaService.save(viewModel.localEntrega).then(function (retorno) {
                    $rootScope.$broadcast('localEntrega:refresh');
                    if (continuar) {
                        if (viewModel.form) {
                            viewModel.form.$setPristine();
                            viewModel.form.$setUntouched();
                        }
                        $modalInstance.params.identificador_cadastro = null;
                        inicializaCadastro();
                    } else {
                        cancelar(retorno);
                    }
                    Notification.publish('Local de entrega salvo com sucesso', 'success');
                })
            );
        }
    }
})();