(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name contratos-core.grupos.GruposSelect
   * @description
   * Serviço do select2 para grupos
   * @requires bfc.Select2Config
   * @requires GruposService
   **/

  angular
    .module('core.grupos')
    .service('core.GruposSelect', GruposSelect);

  GruposSelect.$inject = [
    'bfc.Select2',
    'core.GruposService',
    'CONFIG_SELECT2'
  ];

  function GruposSelect(Select2,
                        GruposService,
                        CONFIG_SELECT2) {

    this.select2Config = select2Config;

    function select2Config(options) {
      var metodos = {
        formatResult: formatResult,
        formatSelection: formatSelection,
        formatValue: formatValue,
        initSelection: initSelection,
        result: _.debounce(result, CONFIG_SELECT2.DELAY),
        minimumInputLength : CONFIG_SELECT2.MINIMUMINPUT,
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT('GRUPOS'),
        dropdownAutoWidth: false
      };

      function formatResult(grupos) {
        return getNomeExibicao(grupos);
      }

      function formatSelection(grupos) {
        return getNomeExibicao(grupos);
      }

      function formatValue(grupos) {
        return grupos.id;
      }

      function initSelection(element, callback) {
        var id = $(element).val();

        if (id) {
          GruposService.get(id).then(callback);
        }
      }

      function result(params, callback) {
        var filter = 'descricao like "' + encodeURI(params.term) + '"';
        //TODO inserir filtro
        var result = GruposService.getList();
        result.then(function (grupos) {
          callback({
            results: grupos,
            more: grupos.hasNext
          });
        });
        return result;
      }

      function getNomeExibicao(grupos) {

        if (grupos.text) {
          return grupos.text;
        }

        if (grupos) {
          return grupos.descricao;
        }
      }

      return Select2.create(angular.extend(metodos, options));
    }
  }
})();
