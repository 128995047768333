(function () {
    'use strict';
    /**
     * @ngdoc controller
     * @name contratos-core.TipoComprovante.controller:TipoComprovanteController
     * @description Controller da página principal de tipos de comprovantes
     **/

    angular
            .module('core.tipo-comprovante')
            .controller('core.TipoComprovanteController', TipoComprovanteController);
    TipoComprovanteController.$inject = [
        '$rootScope',
        '$q',
        '$scope',
        'core.TrackerFactory',
        'core.TipoComprovanteService',
        'core.TipoComprovanteModalService',
        'bfc.Notification'
    ];

    function TipoComprovanteController(
            $rootScope,
            $q,
            $scope,
            TrackerFactory,
            TipoComprovanteService,
            TipoComprovanteModalService,
            Notification) {

        var viewModel = this;
        viewModel.service = TipoComprovanteService;

        //Usado para teste Unitário
        viewModel.argumentos = TipoComprovanteController.$inject;

        viewModel.cadastrar = cadastrar;
        viewModel.editar = editar;
        viewModel.excluir = excluir;

        inicializa();

        function inicializa() {
            inicializaPropriedades();
            inicializaLoadings();
            initEnums();
            escutarEventos();
        }

        function escutarEventos() {
            $scope.$on('tipoComprovante:actionRemove', function (eventos, params) {
              angular.element('#hrefActionExcluir-' + params.id).triggerHandler('click');
            });
        }
      
        
        function initEnums() {
            viewModel.tipoComprovantePadrao = TipoComprovanteService.getTipoComprovantePadrao();
        }

        function inicializaPropriedades() {
            viewModel.tipoComprovante = [];
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init(['tiposComprovante', 'getList', 'exclusao']);
        }

        function cadastrar() {
            TipoComprovanteModalService.cadastrar();
        }

        function editar(item) {
            TipoComprovanteModalService.editar(item);
        }

        function excluir(item) {
            var promise = TipoComprovanteService.excluir(item.id);
            return promise.then(function () {
                Notification.publish('Tipo de comprovante excluído com sucesso', 'success');
            }, function error() {
                return promise;
            });
        }
    }
})();