(function() {
	'use strict';
	/**
	 * @ngdoc service
	 * @name contratos-core.material.MaterialService
	 * @description
	 * Serviço que se comunica com o Back-end de materiais
	 * @requires $q
	 * @requires Restangular
	 **/

	angular
		.module('core.material')
		.service('core.MaterialService', MaterialService);

	MaterialService.$inject = [
		'$q',
		'Restangular',
		'core.UtilsService',
		'ui.components.EventEmitter',
		'bfc.Notification',
		'CamposAdicionaisService'
	];

	function MaterialService(
		$q,
		Restangular,
		UtilsService,
		EventEmitter,
		Notification,
		CamposAdicionaisService
	) {
		var meta;
		var uri = ['materiais', 'proximo-codigo', 'situacao', 'campos-adicionais'];

		var service = new EventEmitter();
		_.extend(service, MaterialService);

		service.get = get;
		service.getClassificacao = getClassificacao;
		service.getClassificacoes = getClassificacoes;
		service.getList = getList;
		service.getDefault = getDefault;
		service.getEnums = getEnums;
		service.save = save;
		service.getTipo = getTipo;
		service.getTipos = getTipos;
		service.getTypes = getTypes;
		service.remove = remove;
		service.emitDirty = emitDirty;
		service.mensagemExclusao = mensagemExclusao;
		service.getTipoCombustivel = getTipoCombustivel;
		service.getTiposCombustiveis = getTiposCombustiveis;
		service.getListNaturezaDespesa = getListNaturezaDespesa;
		service.validaCampoAlterado = validaCampoAlterado;
		service.getCodigoSequencial = getCodigoSequencial;
		service.getEspecificacaoCodigoBarras = getEspecificacaoCodigoBarras;

		service.alteraSituacao = alteraSituacao;
        service.getCamposAdicionais = getCamposAdicionais;

		return service;

		function get(id) {
			return Restangular.one(uri[0], id).get();
		}

		function getClassificacao(key) {
			return getEnums().then(function(enums) {
				return UtilsService.getValueFromKey(enums.ClassificacaoMaterialEnum.values, key);
			});
		}

		function getClassificacoes(filter) {
			return getEnums().then(function(enums) {
				return UtilsService.getFilteredValues(enums.ClassificacaoMaterialEnum.values, filter);
			});
		}

		function getTipo(key) {
			return getEnums().then(function(enums) {
				return UtilsService.getValueFromKey(enums.TipoMaterialEnum.values, key);
			});
		}

		function getTipos(filter) {
			return getEnums().then(function(enums) {
				return UtilsService.getFilteredValues(enums.TipoMaterialEnum.values, filter);
			});
		}

		function getTipoCombustivel(key) {
			return getEnums().then(function(enums) {
				return UtilsService.getValueFromKey(enums.TipoCombustivelEnum.values, key);
			});
		}

		function getTiposCombustiveis(filter) {
			return getEnums().then(function(enums) {
				return UtilsService.getFilteredValues(enums.TipoCombustivelEnum.values, filter);
			});
		}

		function selectSort(sort) {
			if (sort.indexOf('codigoMaterial') !== -1) {
				sort = sort.indexOf('desc') === -1 ? 'codigoMaterialOrder desc' : 'codigoMaterialOrder asc';
			}

			return sort;
		}

		function getList(params) {
			params = params || {};
			params.offset = params.offset || 0;
			params.sort = params.sort ?  selectSort(params.sort) : 'descricao asc';
			return Restangular.one(uri[0]).get(params).then(function (data) {
				if (params.offset > 0 && data.total <= params.offset) {
					params.offset = 0;
					data = getList(params);
				}
				return data;
			});
		}

		function validaCampoAlterado(material) {
			var rest = Restangular.all(uri[0] + '/alterado');
			return rest.post(material);
		}

		function getListNaturezaDespesa(params) {
			params = params || {};

			params.offset = params.offset || 0;

			return Restangular.one(uri[0] + '/naturezadespesactb').get({
				offset: params.offset,
				limit: params.limit,
				filter: params.filter,
				sort: params.sort
			}).then(function (data) {
				if (params.offset > 0 && data.total <= params.offset) {
					params.offset = 0;
					data = getList(params);
				}
				return data;
			});
		}

		function getMetadata() {
			var deferred = $q.defer();
			if (!meta) {
				Restangular.all(uri[0]).options().then(function(data) {
					meta = data;
					deferred.resolve(meta);
				}, function(data) {
					deferred.reject(data);
				});
			} else {
				deferred.resolve(meta);
			}
			return deferred.promise;
		}

		function getDefault() {
			return getMetadata().then(function(data) {
				return angular.copy(data.representation.default);
			});
		}

		function getEnums() {
			return getMetadata().then(function(data) {
				return angular.copy(data.enums);
			});
		}

		function getTypes() {
			return getMetadata().then(function(data) {
				return angular.copy(data.types);
			});
		}

		function save(cadastro, anexos) {
			var rest = Restangular.one(uri[0]);
			var formData = new FormData();
			cadastro.arquivos = anexos;
			formData.append(uri[0], angular.toJson(Restangular.stripRestangular(cadastro)));
			if (anexos) {
				angular.forEach(anexos, function (file) {
					if (file.file) {
						formData.append('files', file.file, file.unique);
					}
				});
			}
			return rest.withHttpConfig({ transformRequest: angular.identity }).customPOST(formData, '', undefined, { 'Content-Type': undefined }).then(emitDirty);
		}

		function alteraSituacao(data) {
            var rest = Restangular.one(uri[0], uri[2]);
            return rest.customPUT(data).then(emitDirty);
        }

		function remove(id) {
			return Restangular.one(uri[0], id).remove().then(emitDirty);
		}

		function emitDirty(data) {
			service.emit('dirty');
			return data;
		}

		function mensagemExclusao() {
			Notification.publish('Material excluído com sucesso', 'success');
		}

		function getCodigoSequencial(id){
			var novaUrl = id ? uri[0] + '/' + id + '/especificacoes' : uri[0];
			return Restangular.one(novaUrl)
				.one(uri[1])
				.get();
		}

		function getEspecificacaoCodigoBarras(params){
            params = params || {};
			params.offset = params.offset || 0;

            return Restangular.one(uri[0]).one('especificacoes').one('codigos-barras').get({
                offset: params.offset,
                limit: params.limit,
                filter: params.filter,
                sort: params.sort
            }).then(function (data) {
                if (params.offset > 0 && data.total <= params.offset) {
                    params.offset = 0;
                    data = getEspecificacaoCodigoBarras(params);
                }

                return data;
            });
		}

        function getCamposAdicionais(materialId) {
            return CamposAdicionaisService.get({
                path: uri[0] + '/' + uri[3],
                origemId: materialId
            }).then(function(response) {
                return response.data;
            });
        }
	}
})();
