(function () {
    'use strict';
    angular
        .module('core.responsavel')
        .controller('core.ResponsavelDesativarModalController', ResponsavelDesativarModalController);

    ResponsavelDesativarModalController.$inject = [
        '$modalInstance',
        'core.ResponsavelService',
        'bfc.Notification',
    ];

    function ResponsavelDesativarModalController(
        $modalInstance,
        ResponsavelService,
        Notification) {


        var viewModel = this;
        viewModel.ativarDesativarRegistros = ativarDesativarRegistros;


        function ativarDesativarRegistros(data, status) {       
            const ids = $modalInstance.params.listaIds
            const promise = ResponsavelService.ativarDesativarEmLote(ids, data, status)
            promise.then(function() {
                const acao = status ? 'inativados' : 'ativados' ;
                Notification.publish('Responsáveis ' + acao + ' com sucesso', 'success');
                $modalInstance.params.status.search()
            });
        } 
    }
})();
