(function () {
  'use strict';
  /**
   * @ngdoc controller
   * @name contratos-core.classes.controller:ClassesCadController
   * @description Controller responsável pelo cadastro de classes
   **/
  angular
    .module('core.classes')
    .controller('core.ClassesCadController', ClassesCadController);

  ClassesCadController.$inject = [
    // 1°: Dependências de serviços do Angular
    '$rootScope',
    '$q',
    '$modalTracker',
    '$modalInstance',
    // 2°: Dependências do BFC
    'bfc.FocusCtrl',
    // 3°: Dependências de ctr.utils
    'core.TrackerFactory',
    // 4°: Dependências do Projeto
    'core.ClassesService',
    'classeIndex',
    'classeObj',
    'grupoId',
    'optionsCad',
    'fnCad'
  ];

  function ClassesCadController($rootScope,
                                $q,
                                $modalTracker,
                                $modalInstance,
                                Focus,
                                TrackerFactory,
                                ClassesService,
                                classeIndex,
                                classeObj,
                                grupoId,
                                optionsCad,
                                fnCad) {
    var viewModel = this;
    var classeDefault = {};
    var CAD_MODE = {
      NENHUM: {value: 0, text: "Cadastro de"},
      ADICIONANDO: {value: 1, text: "Adicionando"},
      EDITANDO: {value: 2, text: "Editando"},
      VISUALIZANDO: {value: 3, text: "Visualizado"}
    };

    //Usado para teste Unitário
    viewModel.argumentos = ClassesCadController.$inject;

    // Métodos do Controller
    viewModel.getCadMode = getCadMode;
    viewModel.isEditing = isEditing;
    viewModel.cancel = cancel;
    viewModel.save = save;
    viewModel.excluir = excluir;
    viewModel.isAddNew = isAddNew;
    viewModel.emUso = emUso;
    viewModel.addNaturezaDespesa = addNaturezaDespesa;
    viewModel.checkNaturezaDespesa = checkNaturezaDespesa;

    viewModel.contabilDisponivel = false;

    // Chamada de função inicializadora do Controller
    initTrackers();
    init();

    function init() {
      viewModel.modal = {};
      initProperties();
      initModal();
    }

    function initProperties() {
      viewModel.classe = {};
    }

    function initTrackers() {
      viewModel.tracker = TrackerFactory.init(['save', 'saveAdd', 'excluir']);
    }

    function initTypes() {

      var promise = ClassesService.getTypes(grupoId);
      promise.then(function (data) {
        viewModel.classesProperties = data;
      });
      return promise;
    }

    function initModal() {
      var promise = $q.all([
        initTypes(),
        initClasse()
      ]);
      $modalTracker.addPromise(promise);
    }

    function initClasse() {
      viewModel.cadMode = CAD_MODE.NENHUM;
      var promise;
      if (classeObj) {
        viewModel.classe = classeObj;
        viewModel.cadMode = CAD_MODE.EDITANDO;
      } else {
        promise = ClassesService.getDefault(grupoId);
        promise.then(function (classe) {
          if (optionsCad && optionsCad.classe) {
            classeDefault = angular.copy(_.extend(classe, optionsCad.classe));
            viewModel.form.$setDirty();
          } else {
            classeDefault = angular.copy(classe);
          }
          viewModel.classe = classeDefault;
        });
        viewModel.cadMode = CAD_MODE.ADICIONANDO;
      }
      viewModel.grupoId = grupoId;
      return promise;
    }

    function getCadMode() {
      return viewModel.cadMode.text;
    }

    function isEditing() {
      return (viewModel.cadMode === CAD_MODE.EDITANDO);
    }

    function cancel() {
      $modalInstance.close();
    }

    function excluir(classe) {
      var promiseFake = ClassesService.promiseFake();
      promiseFake.then(function () {
        $rootScope.$broadcast('grupoClassesCad:Remove', classe, classeIndex);
        cancel();
      });
      viewModel.tracker.excluir.addPromise(promiseFake);
    }

    function save(addNew) {
      var tracker = addNew ? viewModel.tracker.saveAdd : viewModel.tracker.save;
      var promiseFake;
      if (angular.isFunction(fnCad)) {
        var promise = ClassesService.save(viewModel.grupoId, viewModel.classe);
        var classeBusca = viewModel.classe[(viewModel.classe.length - 1)];
        promise.then(function (classe) {
          fnCad(classe);
        });
        tracker.addPromise(promise);
      }

      promiseFake = ClassesService.promiseFake(viewModel.grupoId, viewModel.classe);
      promiseFake.then(function () {
        if (isEditing()) {
          $rootScope.$broadcast('grupoClassesCad:UpdateClasse', viewModel.classe, classeIndex);
        } else {
          $rootScope.$broadcast('grupoClassesCad:AddClasse', viewModel.classe);
        }
        if (addNew) {
          if (viewModel.form) {
            viewModel.form.$setPristine();
            viewModel.form.$setUntouched();
          }
          optionsCad = undefined;
          classeObj = null;
          init();
          Focus.set('lbClasseCadControllerDescricao');
        } else {
          cancel();
        }
      });
      tracker.addPromise(promiseFake);
    }

    function isAddNew() {
      return angular.isDefined(fnCad);
    }

    function emUso() {
      return viewModel.classe.emUso;
    }

    function addNaturezaDespesa(natureza) {
      viewModel.classe.classeComplemento.naturezaDespesaContabil.codNaturezaDespesa = natureza.id;
      viewModel.classe.classeComplemento.naturezaDespesaContabil.descricao = natureza.descricao;
      viewModel.classe.classeComplemento.naturezaDespesaContabil.numeroNaturezaDespesa = natureza.numero;

    }

    function checkNaturezaDespesa() {
      if (!viewModel.classe.classeComplemento ||
        (viewModel.classe.classeComplemento && !viewModel.classe.classeComplemento.numeroNaturezaDespesa)) {
        var classeComplemento = {
          numeroNaturezaDespesa: undefined
        };
        viewModel.classe.classeComplemento = classeComplemento;
        ClassesService.get(viewModel.classe.grupo.id, viewModel.classe.id).then(function (selectedClass) {
          if (selectedClass.classeComplemento && selectedClass.classeComplemento.numeroNaturezaDespesa) {
            viewModel.classe.classeComplemento.numeroNaturezaDespesa =
              selectedClass.classeComplemento.numeroNaturezaDespesa;
          }
        });
      }
    }
  }
})();
