(function() {
	'use strict';

	angular
	  .module('core.material')
	  .service('core.MaterialCadService', MaterialCadService);

	MaterialCadService.$inject = ['core.ModalCadServiceFactory'];

	function MaterialCadService(ModalCadServiceFactory) {

		var service = ModalCadServiceFactory.create({
			templateUrl: 'material-cad/material-cad.html',
            controller: 'core.MaterialCadController as materialCad',
            resolve: {
            	MaterialId: null
            }
		});

		_.extend(this, service);
	}
})();
