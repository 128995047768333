(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name contratos-core.natureza-despesa.NaturezaDespesaService
     * @description
     * Serviço que se comunica com o back-end de natureza de despesas
     * @requires $q
     * @requires Restangular
     **/
    angular
            .module('core.natureza-despesa')
            .service('core.NaturezaDespesaService', NaturezaDespesaService);

    NaturezaDespesaService.$inject = [
        '$q',
        'core.PlanejamentoResources',
        'Restangular'
    ];

    function NaturezaDespesaService(
            $q,
            PlanejamentoResources,
            Restangular
            ) {
        //var ano = bfcApplicationContext.get('exercicio');

        var meta;
        var uri = ['configuracoes-naturezas-despesa/exercicios/', '/naturezas-despesa'/*, 'naturezadespesactb'*/];
        var uri_options = 'naturezas-despesa/options'; //TEMP: pegar options
        var service = this;

        var service = {
            get: get,
            getDefault: getDefault,
            getList: getList,
            getTypes: getTypes,
            isContabilidadeDisponivel: isContabilidadeDisponivel,
            //getListNaturezaDespesa: getListNaturezaDespesa
        };

        return service;

        function getExercicio(exercicio) {
            if (exercicio)
                return exercicio;
            else
                return service.exercicio;
        }

        function get(numero, exercicio) {
            return PlanejamentoResources.one(uri[0] + getExercicio(exercicio) + uri[1] + _getFilter(numero)).get().then(function(natureza) {
                return natureza[0];
            });
        }

        function getList(filter, exercicio) {
            //var promise = PlanejamentoResources.one(uri).getList({
            return PlanejamentoResources.all(uri[0] + getExercicio(exercicio) + uri[1]).getList({
                filter: filter,
                offset: 0,
                limit: 999
            });
            return promise;
        }

/*
        function getListNaturezaDespesa(params) {
            params = params || {};

            params.offset = params.offset || 0;

            return Restangular.one('naturezadespesa/' + uri[2]).get({
                offset: params.offset,
                limit: params.limit,
                filter: params.filter,
                sort: params.sort
            }).then(function (data) {
                if (params.offset > 0 && data.total <= params.offset) {
                    params.offset = 0;
                    data = getList(params);
                }
                return data;
            });
        }
*/        

        function isContabilidadeDisponivel(exercicio) {
            var promise = $q.defer();
            var p = PlanejamentoResources.one(uri[0] + getExercicio(exercicio) + uri[1]).get({
                filter: null,
                offset: 0,
                limit: 999
            });
            p.then(function(resposta) {
                if (_.isUndefined(resposta) || _.isNull(resposta) || _.isEmpty(resposta)) {
                    promise.resolve(false);
                } else {
                    promise.resolve(true);
                }
            });
            return promise.promise;
        }

        function getMetadata() {
            var deferred = $q.defer();
            if (!meta) {
                PlanejamentoResources.all(uri_options).then(function (data) {
                 meta = data;
                 deferred.resolve(meta);
                 }, function (data) {
                 deferred.reject(data);
                 });
                PlanejamentoResources.all(uri[0] + service.exercicio + uri[1]).options().then(function(data) {
                    meta = data;
                    deferred.resolve(meta);
                }, function(data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }

        function getDefault() {
            return getMetadata().then(function(data) {
                return angular.copy(data.representation.default);
            });
        }

        function getTypes() {
            return getMetadata().then(function(data) {
                return angular.copy(data.types);
            });
        }

        function _getFilter(numero) {
            return '?filter=(descricao+like+%22%25%25' + numero + '%25%25%22+or+numero+like+%22%25' + numero + '%25%22)&limit=999&offset=0';
        }
    }
})();
