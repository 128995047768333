(function() {
	'use strict';
	angular
			.module('core.material')
			.service('core.MaterialCadModalService', MaterialCadModalService);

	MaterialCadModalService.$inject = ['core.AcoesCadastrosModalService'];

	function MaterialCadModalService(AcoesCadastrosModalService) {

		var modal = {
			templateUrl: 'material-cad/material-cad.html',
			controller: 'core.MaterialCadController',
			controllerAs: 'cadastro',
			params: {
				identificador_cadastro: null
			},
			size:'lg'
		};

		var service = {
			cadastrar: cadastrar,
			editar: editar,
			onAdd: onAdd
		};

		angular.extend(this, service);

		function abrir(modal) {
			return AcoesCadastrosModalService.abrir(modal);
		}

		function cadastrar() {
			AcoesCadastrosModalService.cadastrar(modal);
		}

		function editar(material) {
			AcoesCadastrosModalService.editar(modal, material);
		}

		function onAdd(params) {
			return AcoesCadastrosModalService.onAdd(modal, params);
		}
	}
})();