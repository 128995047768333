(function () {
    'use strict';

    angular
        .module('core.material')
        .controller('core.MaterialCadController', MaterialCadController);

    MaterialCadController.$inject = [
        '$injector',
        '$rootScope',
        '$scope',
        '$q',
        '$timeout',
        'bfc.FocusCtrl',
        '$modalInstance',
        '$modalTracker',
        'bfc.Notification',
        'core.Select2Factory',
        'core.UnidadeMedidaService',
        'core.CodigoBarraCadModalService',
        'core.ClassesService',
        'core.ClassesSelect',
        'core.ClassesCadModalService',
        'core.MaterialService',
        'core.ClassificacaoMaterialSelect',
        'core.TipoSelect',
        'core.TipoCombustivelSelect',
        'ContratosConfirmService',
        'core.UnidadeMedidaModalService',
        'CONFIG_SELECT2',
        'core.TrackerFactory',
        'CamposAdicionaisService',
        'CPA_CONSTANTS',
        'bfc.$$PermissionsService'
    ];

    function MaterialCadController($injector,
                                   $rootScope,
                                   $scope,
                                   $q,
                                   $timeout,
                                   Focus,
                                   $modalInstance,
                                   $modalTracker,
                                   Notification,
                                   Select2Factory,
                                   UnidadeMedidaService,
                                   CodigoBarraCadModalService,
                                   ClassesService,
                                   ClassesSelect,
                                   ClassesCadModalService,
                                   MaterialService,
                                   ClassificacaoMaterialSelect,
                                   TipoSelect,
                                   TipoCombustivelSelect,
                                   ConfirmService,
                                   UnidadeMedidaModalService,
                                   CONFIG_SELECT2,
                                   TrackerFactory,
                                   CamposAdicionaisService,
                                   CPA_CONSTANTS,
                                   PermissionsService) {

        var viewModel = this;

        viewModel.CONFIG_SELECT2 = CONFIG_SELECT2;

        var materialDefault = {};

        //Usado para teste Unitário
        viewModel.argumentos = MaterialCadController.$inject;
        viewModel.isAdd = isAdd;
        viewModel.fechar = fechar;
        viewModel.save = save;
        viewModel.changeClasse = changeClasse;
        viewModel.changeEstocavel = changeEstocavel;
        viewModel.hasGrupo = hasGrupo;
        viewModel.checkNaturezaDespesa = checkNaturezaDespesa;
        viewModel.addEspecificacao = addEspecificacao;
        viewModel.duplicarEspecificacao = duplicarEspecificacao;
        viewModel.removeEspecificacao = removeEspecificacao;
        viewModel.cancelRemoveEspecificacao = cancelRemoveEspecificacao;
        viewModel.hasEspecificacaoId = hasEspecificacaoId;
        viewModel.isEmptyEspecificacoes = isEmptyEspecificacoes;
        viewModel.emUso = emUso;
        viewModel.salvarMaterial = salvarMaterial;
        viewModel.excluir = excluir;
        viewModel.existeMaterial = existeMaterial;
        viewModel.buscarUnidadeMedida = buscarUnidadeMedida;
        viewModel.formataResultado = formataResultado;
        viewModel.formataSelecao = formataSelecao;
        viewModel.adicionarNovaUnidade = adicionarNovaUnidade;
        viewModel.desabilitaEstocavel = desabilitaEstocavel;
        viewModel.desabilitaSalvar = desabilitaSalvar;
        viewModel.desabilitaSalvarAdicionar = desabilitaSalvarAdicionar;
        viewModel.editando = editando;
        viewModel.isTipoMaterial = isTipoMaterial;
        viewModel.isTipoBemPermanente = isTipoBemPermanente;
        viewModel.habilitaTipoCombustivel = habilitaTipoCombustivel;
        viewModel.changeClassificacao = changeClassificacao;
        viewModel.addNaturezaDespesa = addNaturezaDespesa;
        viewModel.openCodigoBarra = openCodigoBarra;
        viewModel.inicializarEspecificacao = inicializarEspecificacao;

        initTrackers();
        init();

        function init() {
            viewModel.arquivos = [];
            viewModel.material = {};
            //viewModel.material.classe = {};
            //viewModel.material.classe.grupo = {};
            viewModel.select2 = {};
            initListeningEvents();
            initSelect2();
            initPromises();
            inicializaCamposAdicionais();
            viewModel.visivel = 1;
        }

        function initPromises() {
            var promise = $q.all([
                initMaterial(),
                initTypes(),
                initEnums()
            ]);
            promise.then(function () {
                $timeout(function () {
                    if ($modalInstance.params.identificador_cadastro) {
                        Focus.set('descricaoMaterialCadControllerDescricao');
                    } else {
                        document.getElementById('descricaoMaterialCadControllerDescricao').setAttribute('autofocus', true);
                    }
                }, 5);

            });
            $modalTracker.addPromise(promise);
        }

        function abriuInserirNovaEspecificacao() {
            if ($modalInstance.params) {
                if ($modalInstance.params.params) {
                    // abriu o cadastro ao tentar inserir uma nova especificação de material e por isso enviou o código do material
                    if ($modalInstance.params.params.idMaterial) return true
                }
            }
            return false;
        }

        function getIdMaterialAbrirInsercaoNovaEspecificacao() {
            if (abriuInserirNovaEspecificacao()) {
                return $modalInstance.params.params.idMaterial;
            }
            return $modalInstance.params.identificador_cadastro; // não abriu o cadastro ao tentar inserir uma nova especificação de material*/
        }

        function initMaterial() {
            var promise;
            $modalInstance.params.identificador_cadastro = getIdMaterialAbrirInsercaoNovaEspecificacao();
            if ($modalInstance.params.identificador_cadastro) {
                promise = MaterialService.get($modalInstance.params.identificador_cadastro);
                promise.then(function (material) {
                    viewModel.material = material.plain();
                    viewModel.arquivos = material.arquivos;
                    if (verificaClasseGrupo()) {
                        ClassesService.grupoId = viewModel.material.classe.grupo.id;
                    } else {
                        viewModel.material.classe = {};
                        viewModel.material.classe.grupo = {};
                    }
                    //Incluido para nao aparecer quando edita um novo material
                    if (isEmptyEspecificacoes()) {
                        addEspecificacao();
                    }

                    if (abriuInserirNovaEspecificacao()) {
                        addEspecificacao({
                            codigoEspecificacao: getMaxCodigo() + 1,
                            descricao: $modalInstance.params.params.descricao
                        });
                    }

                });
            } else {
                promise = MaterialService.getDefault();
                promise.then(function (material) {
                    viewModel.material = material;
                    viewModel.arquivos = material.arquivos;
                    MaterialService.getCodigoSequencial().then(function (codigoMaterial) {
                        viewModel.material.codigoMaterial = codigoMaterial;
                    });
                    if ($modalInstance.params.params) {
                        if ($modalInstance.params.params.descricao) {
                            setTimeout(function() {
                                viewModel.material.descricao = $modalInstance.params.params.descricao;
                            });
                        }
                    }

                    $rootScope.$broadcast('file:refresh');

                    //viewModel.material.classe = {};
                    //viewModel.material.classe.grupo = {};
                    viewModel.material.tipoCombustivel = undefined;

                    //Incluido para nao aparecer quando edita um novo material
                    if (isEmptyEspecificacoes()) {
                        inicializarEspecificacao();
                    }

                });
            }
            Focus.set('descricaoMaterialCadControllerDescricao');
            return promise;
        }

        function initListeningEvents() {
            // Escuta evento de foco do modal de Cadastro de Grupo e Classe
            // Para quando for uma classe ser focado no campo classe
            $scope.$on('gruposCad:focus', function (event) {
                Focus.set('lbMaterialCadControllerClasse');
            });
        }

        function inicializaCamposAdicionais() {
            var id = hasMaterial();
            var promise = CamposAdicionaisService.get(CPA_CONSTANTS.Cadastros.MATERIAL_SERVICO, id);
            promise.then(function (response) {
                viewModel.campoAdicional = response.data;
            });
            return promise;
        }

        function initTypes() {
            var promise = MaterialService.getTypes();
            promise.then(function (data) {
                viewModel.materiaisProperties = data.MaterialBO;
                viewModel.especificacaoesProperties = data.MaterialEspecificacaoBO;
            });
            return promise;
        }

        function initEnums() {
            var promise = MaterialService.getEnums();
            promise.then(function (data) {
                viewModel.tipoMaterial = data.TipoMaterialEnum.values;
                viewModel.classificacaoMaterial = data.ClassificacaoMaterialEnum.values;
                viewModel.material.classificacao = viewModel.classificacaoMaterial[0].key;
            });
            return promise;
        }

        function verificaClasseGrupo() {
            return viewModel.material.classe && viewModel.material.classe.grupo ? true : false;

        }

        function initTrackers() {
            viewModel.tracker = TrackerFactory.init([
                'save',
                'saveAdd',
                'excluir'
            ]);
        }

        function initSelect2() {
        var permissao = !PermissionsService.isRevokedOperation('GrupoPageMapping', 'criar');
            var select2s = [
                {
                    element: 'tipoCombustivel',
                    select2: TipoCombustivelSelect
                },
                {
                    element: 'classificacao',
                    select2: ClassificacaoMaterialSelect
                },
                {
                    element: 'tipo',
                    select2: TipoSelect
                }
            ];

            if(permissao){
                var novoElemento = {
                                       element: 'classes',
                                       select2: ClassesSelect,
                                       options: {
                                           onAdd: function (value, target) {
                                               var optionsCad = {
                                                   classe: {
                                                       descricao: value
                                                   }
                                               };
                                               var grupoId = viewModel.material.classe.grupo.id;
                                               ClassesCadModalService.cadastrarExterno(target, optionsCad, grupoId);
                                               return false;
                                           }
                                       }
                                   }

                select2s.push(novoElemento);
            }else{
                var novoElemento = {
                 element: 'classes',
                 select2: ClassesSelect
                 }
                select2s.push(novoElemento);
            }
            viewModel.select2 = Select2Factory.init(select2s);
        }

        function hasMaterial() {
            return (viewModel.material.id === undefined) ? $modalInstance.params.identificador_cadastro : viewModel.material.id;
        }

        function existeMaterial() {
            return Boolean(hasMaterial());
        }

        function isAdd() {
            return hasMaterial() ? 'Editando' : 'Adicionando';
        }

        function isEmptyEspecificacoes() {
            return viewModel.material && viewModel.material.especificacoes && viewModel.material.especificacoes.length === 0;
        }

        function fechar(material) {
            $modalInstance.close(material);
        }

        function buscarUnidadeMedida(params) {
            var id = Number(params.term.replace(/[^\w]/g, ''));
            params.filter = ' ((nome like "' + params.term + '")';
            params.filter += ' or (simbolo like "' + params.term + '")';
            if (angular.isNumber(id) && !isNaN(id) && id.length < 13) {
                busca.filter += ' or (id = ' + id + ')';
            }
            params.filter += ')  and (ativo = true)';

            return UnidadeMedidaService.getList(params).then(function (data) {
                return data;
            });
        }

        function formataResultado(value) {
            return getNomeExibicao(value);
        }

        function formataSelecao(unidadeMedida) {
            return getSimbolo(unidadeMedida);
        }

        function getNomeExibicao(unidadeMedida) {
            if (unidadeMedida.text) {
                return unidadeMedida.text;
            }
            if (unidadeMedida) {
                return unidadeMedida.descricao ? unidadeMedida.descricao : unidadeMedida.simbolo + ' - ' + unidadeMedida.nome;
            }
        }

        function getSimbolo(unidadeMedida) {
            if (unidadeMedida.text) {
                return unidadeMedida.text;
            }
            if (unidadeMedida) {
                return unidadeMedida.simbolo;
            }
        }

        function adicionarNovaUnidade(unidadeMedida) {
            var param = {
                nome: unidadeMedida
            };
            return UnidadeMedidaModalService.onAdd(param);
        }

        /*
         * INICIO DA VERIFICAÇÃO DAS MENSAGENS
         */

        //Funcao que realiza a busca nos resultados das classes, para retornar a controller que chamou o objeto correto
        function getIndexEspecificacao(material, busca) {
            var result;
            for (var iEspecificacao = 0; iEspecificacao <= material.especificacoes.length; iEspecificacao++) {
                if (angular.equals(material.especificacoes[iEspecificacao].descricao, busca.descricao)) {
                    result = material.especificacoes[iEspecificacao];
                    break;
                }
            }
            return result;
        }

        function verificaCampoAlterado() {
            var listaCampos = ['especificacaoMaterialCadController-', 'unidadeCompraMaterialCadController-'];
            var result = false;
            var listaCamposVerificar = [];
            for (var iEspec = 0; iEspec < viewModel.material.especificacoes.length; iEspec++) {
                for (var i = 0; i < listaCampos.length; i++) {
                    var a = listaCampos[i].replace(/-/g, '-' + iEspec);
                    //Se especificacao estiver em uso adiciona a lista
                    //Se campo ja existir na lista de campos nao adicionar na outra listagem
                    if (listaCamposVerificar.indexOf(a) === -1) {
                        if (viewModel.material.especificacoes[iEspec].emUso || (listaCampos[i] === a && viewModel.material.emUso)) {
                            listaCamposVerificar.push(a);
                        }
                    }
                }
            }
            angular.forEach(viewModel.form, function (data, index) {
                if (listaCamposVerificar.indexOf(index) >= 0) {
                    if (data.$dirty) {
                        result = true;
                    }
                }
            });
            return result;
        }

        function salvarMaterial(continuar) {
            var tracker = continuar ? viewModel.tracker.saveAdd : viewModel.tracker.save;
            var promise = MaterialService.validaCampoAlterado(viewModel.material);

            promise.then(function (data) {
                if ((data.materialUtilizado || data.materialUtilizado && verificaCampoAlterado()) && $modalInstance.params.identificador_cadastro) {
                    ConfirmService.confirm({
                        title: 'Confirmar alteração',
                        body: 'Este material/serviço já está sendo utilizado. Ao prosseguir com a alteração, outros registros poderão ser impactados. Você deseja confirmar a alteração?'
                    }, function () {
                        save(continuar);
                    }, function () {
                        fechar();
                    });
                } else {
                    save(continuar);
                }
            });
            tracker.addPromise(promise);
            return promise;
        }

        function save(continuar) {
            var tracker = continuar ? viewModel.tracker.saveAdd : viewModel.tracker.save;

            //Incluido checagem para enviar para o back apenas o value false.
            if (viewModel.material.estocavel.key === 'NAO') {
                viewModel.material.estocavel = false;
            }
            if (viewModel.material.classificacao !== 'COMBUSTIVEL') {
                setTipoCombustivelDefault();
            }

            if (viewModel.campoAdicional)
                viewModel.material.campoAdicional = viewModel.campoAdicional;

            if (viewModel.material.ativo) {
                viewModel.material.dataInativacao = undefined;
            }

            var promise = MaterialService.save(viewModel.material, viewModel.arquivos);
            var especificacaoBusca = viewModel.material.especificacoes[(viewModel.material.especificacoes.length - 1)];

            promise.then(function (material) {

                if ($modalInstance.params.identificador_cadastro) {
                    //Realiza um get no grupo para retornar todas as classes
                    MaterialService.get($modalInstance.params.identificador_cadastro).then(function (materialEspecificacao) {
                        if (abriuInserirNovaEspecificacao()) {
                            material = getIndexEspecificacao(materialEspecificacao, especificacaoBusca);
                            fechar(material);
                        }
                    });
                }

                // Caso seja para Salvar e Adicionar novo...
                if (continuar) {
                    $modalInstance.params.identificador_cadastro = undefined;
                    if ($modalInstance.params.params) {
                        if ($modalInstance.params.params.descricao) {
                            $modalInstance.params.params.descricao = undefined;
                        }
                    }
                    if (viewModel.form) {
                        viewModel.form.$setPristine();
                        viewModel.form.$setUntouched();
                    }
                    $modalInstance.params.identificador_cadastro = undefined;
                    init();
                    Focus.set('descricaoMaterialCadControllerDescricao');
                } else {
                    if (!abriuInserirNovaEspecificacao()) {
                        fechar(material);
                    }
                }
                Notification.publish('Material ' + ($modalInstance.params.identificador_cadastro ? 'alterado' : 'adicionado') + ' com sucesso', 'success');
            });
            tracker.addPromise(promise);
        }

        function changeClasse() {
            if (verificaClasseGrupo()) {
                hasGrupo();
                ClassesService.setGrupoId(viewModel.material.classe.grupo.id);
                //ClassesService.grupoId = viewModel.material.classe.grupo.id;
                //viewModel.material.classe.id = undefined;
            }
        }

        function limpaClasse(id, param) {
            ClassesService.setGrupoId(id);
            if (viewModel.material.classe && param) {
                viewModel.material.classe.id = undefined;
                viewModel.material.classe.descricao = undefined;
            }
        }

        $scope.$watch(
            'cadastro.material.classe.grupo',
            function changeValue(newValue, oldValue) {
                if (newValue && oldValue || newValue && !oldValue) {
                    if (newValue && !oldValue) {
                        limpaClasse(viewModel.material.classe.grupo.id, false);
                    } else {
                        limpaClasse(viewModel.material.classe.grupo.id, true);
                    }
                } else if (!newValue) {
                    limpaClasse(0, true);
                }
            }
        );

        function hasGrupo() {
            if (verificaClasseGrupo()) {
                return !viewModel.material.classe.grupo.id;
            }
        }

        function changeEstocavel() {
            switch (viewModel.material.tipoMaterial) {
                case 'SERVICO':
                    //Inserido desta forma para poder aparecer quando alterar o tipo.
                    //viewModel.material.estocavel = {key: "NAO", value: "false", description: "Não"};
                    viewModel.material.estocavel = false;
                    setClassificacaoDefault();
                    setTipoCombustivelDefault();
                    break;
                case 'MATERIAL':
                    viewModel.material.estocavel = false;
                    if (viewModel.material.classificacao !== 'COMBUSTIVEL') {
                        setTipoCombustivelDefault();
                    }

                    break;
                case 'PERMANENTE':
                    viewModel.material.estocavel = false;
                    setClassificacaoDefault();
                    setTipoCombustivelDefault();
                    break;
                default:
                    return viewModel.material.estocavel = false;
            }
        }

        function habilitaTipoCombustivel() {
            return viewModel.material.classificacao === 'COMBUSTIVEL';
        }

        function isTipoMaterial() {
            return viewModel.material.tipoMaterial === 'MATERIAL';
        }

        function isTipoBemPermanente() {
            return viewModel.material.tipoMaterial === 'PERMANENTE';
        }

        function checkNaturezaDespesa() {
            if (!viewModel.material.materialComplemento ||
                (viewModel.material.materialComplemento && !viewModel.material.materialComplemento.numeroNaturezaDespesa)) {
                var materialComplemento = {
                    numeroNaturezaDespesa: undefined
                };
                viewModel.material.materialComplemento = materialComplemento;
                ClassesService.get(viewModel.material.classe.grupo.id, viewModel.material.classe.id).then(function (selectedClass) {
                    if (selectedClass.classeComplemento && selectedClass.classeComplemento.numeroNaturezaDespesa) {
                        //viewModel.material.materialComplemento.numeroNaturezaDespesa =
                        //selectedClass.classeComplemento.numeroNaturezaDespesa;
                        viewModel.material.materialComplemento.naturezaDespesaContabil = selectedClass.classeComplemento.naturezaDespesaContabil;
                    }
                });
            }
        }

        function setClassificacaoDefault() {
            if (viewModel.classificacaoMaterial)
                viewModel.material.classificacao = viewModel.classificacaoMaterial[0].key;
        }

        function setTipoCombustivelDefault() {
            viewModel.material.tipoCombustivel = 'NAO_APLICA';
        }

        function changeClassificacao() {
            if (viewModel.material.classificacao === 'COMBUSTIVEL') {
                viewModel.material.tipoCombustivel = undefined;
            }
        }

        function desabilitaEstocavel() {
            return viewModel.material && viewModel.material.tipoMaterial === 'SERVICO';
        }

        function inicializarEspecificacao(){
            var especificacao = {
                codigoEspecificacao: getMaxCodigo() + 1,
                descricao: undefined,
                unidadeMedida: undefined
            };
            viewModel.material.especificacoes.push(especificacao);
        }

        function addEspecificacao(especificacao) {
            if (angular.isUndefined(especificacao)) {
                especificacao = {
                    codigoEspecificacao: getMaxCodigo() + 1,
                    descricao: undefined,
                    unidadeMedida: undefined
                };
            }

            if (viewModel.material.especificacoes.length <= 0 && viewModel.material.descricao) {
                especificacao.descricao = viewModel.material.descricao;
            }

            var _tamanho = viewModel.material.especificacoes.push(especificacao);
            Focus.set('listMaterialCadControllerEspecificacoes-' + (_tamanho - 1));

        }

        function getMaxCodigo() {
            var map = [];
            if (viewModel.material.especificacoes.length > 0) {
                map = _.map(viewModel.material.especificacoes, 'codigoEspecificacao');
            } else {
                map = [0];
            }
            return parseInt(_.max(map));
        }

        function removeEspecificacao(index, especificacao) {
            actionRemove(index, especificacao);
        }

        function duplicarEspecificacao(especificacao) {
            if (especificacao.descricao) {
                var especificacaoDuplicar = {
                    codigoEspecificacao: getMaxCodigo() + 1,
                    descricao: especificacao.descricao,
                    unidadeMedida: especificacao.unidadeMedida
                };
                addEspecificacao(especificacaoDuplicar);
            } else {
                Notification.publish('É necessário ter uma descrição da especificação para duplicar', 'warn');
            }
        }

        function actionRemove(index, especificacao) {
            if ($modalInstance.params.identificador_cadastro && especificacao.id) {
                especificacao.registro = 'REMOVIDO';
                viewModel.form.$setDirty();
            } else {
                actionRemoveInterface(index);
            }
        }

        function cancelRemoveEspecificacao(index, especificacao) {
            actionCancelRemove(index, especificacao);
        }

        function actionCancelRemove(index, especificacao) {
            if ($modalInstance.params.identificador_cadastro && especificacao.id) {
                especificacao.registro = undefined;
            } else {
                actionRemoveInterface(index);
            }
        }

        function actionRemoveInterface(index) {
            viewModel.material.especificacoes.splice(index, 1);
        }

        function hasEspecificacaoId(especificacao) {
            return especificacao.id;
        }

        function emUso() {
            return viewModel.material.emUso;
        }

        function excluir() {
            $rootScope.$broadcast('material:actionRemove', {id: viewModel.material.id});
            fechar();
        }

        function editando() {
            return $modalInstance.params.identificador_cadastro;
        }

        function desabilitaSalvar() {
            return viewModel.tracker.save.active() ||
                viewModel.tracker.saveAdd.active() ||
                viewModel.tracker.excluir.active() ||
                (viewModel.form.$dirty && viewModel.form.$invalid) ||
                (viewModel.form.$pristine && !editando()) ||
                viewModel.isEmptyEspecificacoes();
        }

        function desabilitaSalvarAdicionar() {
            return viewModel.tracker.save.active() ||
                viewModel.tracker.saveAdd.active() ||
                viewModel.tracker.excluir.active() ||
                (viewModel.form.$dirty && viewModel.form.$invalid) ||
                viewModel.form.$pristine ||
                viewModel.isEmptyEspecificacoes();
        }

        function addNaturezaDespesa(natureza) {
            viewModel.material.materialComplemento.naturezaDespesaContabil.codNaturezaDespesa = natureza.id;
            viewModel.material.materialComplemento.naturezaDespesaContabil.descricao = natureza.descricao;
            viewModel.material.materialComplemento.naturezaDespesaContabil.numeroNaturezaDespesa = natureza.numero;
        }

        function openCodigoBarra(especificacao) {
            CodigoBarraCadModalService.editar(especificacao);
        }

        function preencherPrimeiraEspecificacao(descricaoNova, descricaoAntiga) {
            if (viewModel.material.especificacoes.length === 1) {
                if (viewModel.material.especificacoes[0].descricao === descricaoAntiga) {
                    viewModel.material.especificacoes[0].descricao = descricaoNova;
                }
            }
        }

        $scope.$watch(
            'cadastro.material.descricao',
            function changeValue(newValue, oldValue) {
                if (newValue && oldValue || newValue && !oldValue) {
                    preencherPrimeiraEspecificacao(newValue, oldValue);
                }
            }
        );

    }
})();
