(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name contratos-core.responsavel.ResponsavelSelect
   * @description
   * Serviço para usar o select2 nos responsaveis
   * @requires bfc.Select2Config
   * @requires ResponsavelService
   **/

  angular
    .module('core.responsavel')
    .service('core.ResponsavelPessoaSelect', ResponsavelPessoaSelect);

  ResponsavelPessoaSelect.$inject = [
    'bfc.Select2',
    'core.ResponsavelService',
    'CONFIG_SELECT2'
  ];

  function ResponsavelPessoaSelect(Select2,
                                   ResponsavelService,
                                   CONFIG_SELECT2) {

    angular.extend(this, {
      select2Config: select2Config
    });

    function select2Config(options) {
      var methods = {
        onAdd: onAdd,
        id: options.id ? options.id : id,
        formatResult: options.formatResult ? options.formatResult : formatResult,
        formatSelection: options.formatSelection ? options.formatSelection : formatSelection,
        formatValue: options.formatValue ? options.formatValue : formatValue,
        initSelection: options.initSelection ? options.initSelection : initSelection,
        result: _.debounce(options.result ? options.result : result, CONFIG_SELECT2.DELAY),
        minimumInputLength : CONFIG_SELECT2.MINIMUMINPUT,
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT('PESSOA'),
        dropdownAutoWidth: false
      };

      function formatResult(responsavel) {
        return responsavel.text ? responsavel.text : responsavel.pessoa.nome;
      }

      function formatSelection(responsavel) {
        if (responsavel.pessoa && responsavel.pessoa.nome) {
          return responsavel.pessoa.nome;
        }
        return responsavel.nome;
      }

      function formatValue(responsavel) {
        if (responsavel.nome) {
          responsavel = {
            nome: responsavel.nome
          };
        }
        return responsavel;
      }

      function id(responsavel) {
        if (responsavel.pessoa) {
          return responsavel.pessoa.nome ? responsavel.pessoa.nome : responsavel.pessoa.id;
        }
        return responsavel.nome ? responsavel.nome : responsavel.id;
      }

      function initSelection(element, callback) {
        var id = element.val();

        if (!isNaN(id)) {
          ResponsavelService.getPessoa(id).then(callback);
        } else {
          var responsavel = {
            nome: element.val()
          };
          callback(responsavel);
        }
      }

      function result(params, callback) {
        var filter = params.term.replace(/['"]+/g, ''),
          result = ResponsavelService.getPessoasList(filter);

        result.then(function (responsaveis) {
          callback({
            results: responsaveis,
            more: responsaveis.hasNext
          });
        });
        return result;
      }

      function onAdd(value, target) {
        Select2.set(target, value);
        return false;
      }

      return Select2.create(angular.extend(methods, options));
    }
  }
})();
