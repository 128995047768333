(function () {
    'use strict';
    /**
     * @ngdoc overview
     * @name contratos-core.cargo
     * @description
     * # contratos-core.cargo
     * Módulo de cargo
     **/
    angular
            .module('core.cargo', ['core', 'core.commons']);
})();
