(function() {
    'use strict';
    /**
     * @ngdoc service
     * @name contratos-core.classes.service:ClassesCadModalService
     * @description Serviço para abrir a tela de cadastro de classes
     * @requires ModalCadFactory
     **/
    angular
            .module('core.classes')
            .service('core.ClassesCadModalService', ClassesCadModalService);

    ClassesCadModalService.$inject = ['core.ModalCadFactory'];

    function ClassesCadModalService(ModalCadFactory) {

        var modal = {
            templateUrl: 'classes-cad.html',
            controller: 'core.ClassesCadController as classeCad',
            resolve: {
                classeIndex: null,
                classeObj: null,
                grupoId: null
            }
        };

        var service = {
            cadastrar: cadastrar,
            cadastrarExterno: cadastrarExterno,
            editar: editar
        };

        angular.extend(this, service);

        function abrir(modal) {
            ModalCadFactory.open(modal);
        }

        function cadastrar(grupoId) {
          grupoId = !grupoId ? null : grupoId;
            var m = copia(modal);
            m.resolve = {
                classeIndex: null,
                classeObj: null,
                grupoId: grupoId
            };
            abrir(m);
        }

        function cadastrarExterno(target, optionsCad, grupoId) {
            var m = copia(modal);
            m.resolve = {
                classeIndex: null,
                classeObj: null,
                grupoId: grupoId ? grupoId : null
            };
            if (target) m.selectElem = target;
            if (optionsCad) m.optionsCad = optionsCad;
            abrir(m);
        }

        function copia(modal) {
            return angular.copy(modal);
        }

        function editar(classe, classeIndex, grupoId) {
            var m = copia(modal);
            m.resolve = {
                classeIndex: classeIndex,
                classeObj: classe,
                grupoId: grupoId
            };
            abrir(m);
        }
    }
})();
