(function () {
  'use strict';

  /**
   * @ngdoc controller
   * @name contratos-core.marca.controller:MarcaCadController
   * @description
   * MarcaCadController de contratos-core.marca
   * @requires $rootScope
   * @requires $q
   * @requires promiseTracker
   * @requires $modalInstance
   * @requires $modalTracker
   * @requires Select2Factory
   * @requires MarcaService
   * @requires MarcaId
   */

  angular
    .module('core.marca')
    .controller('core.MarcaCadController', MarcaCadController);

  MarcaCadController.$inject = [
    '$rootScope',
    '$scope',
    '$q',
    'bfc.FocusCtrl',
    'core.ModalCadFactory',
    'promiseTracker',
    '$modalInstance',
    '$modalTracker',
    'bfc.Notification',
    'core.MarcaService',
    'MarcaId',
    'optionsCad',
    'fnCad',
    'core.TrackerFactory'
  ];

  function MarcaCadController($rootScope,
                              $scope,
                              $q,
                              Focus,
                              ModalCadFactory,
                              promiseTracker,
                              $modalInstance,
                              $modalTracker,
                              Notification,
                              MarcaService,
                              MarcaId,
                              optionsCad,
                              fnCad,
                              TrackerFactory) {

    var viewModel = this;
    var marcaDefault = {};

    //Usado para teste Unitário
    viewModel.argumentos = MarcaCadController.$inject;

    viewModel.isAdd = isAdd;
    viewModel.cancel = cancel;
    viewModel.save = save;
    viewModel.changeDescricao = changeDescricao;
    viewModel.isAddNew = isAddNew;
    viewModel.emUso = emUso;
    viewModel.alerta = alerta;
    viewModel.excluir = excluir;
    viewModel.existeMarca = existeMarca;

    initTrackers();
    init();

    function init() {
      viewModel.marca = {};
      viewModel.marca.classe = {};
      viewModel.marca.classe.grupo = {};
      viewModel.select2 = {};
      initProperties();
      initMarca();
      initEnums();
      initListeningEvents();
    }

    function initMarca() {
      var promise;
      if (MarcaId) {
        promise = MarcaService.get(MarcaId);
        promise.then(function (marca) {
          viewModel.marca = marca;
        });
      } else {
        promise = MarcaService.getDefault();
        promise.then(function (marca) {
          if (optionsCad && optionsCad.marca) {
            marcaDefault = angular.copy(_.extend(marca, optionsCad.marca));
          } else {
            marcaDefault = angular.copy(marca);
          }
          viewModel.marca = marca;
        });
      }
      $modalTracker.addPromise(promise);
    }

    function initTypes() {
      var promise = MarcaService.getTypes();
      promise.then(function (data) {

      });
      return promise;
    }

    function initProperties() {
      var promise = $q.all([
        initTypes()
      ]);
      $modalTracker.addPromise(promise);
    }

    function initEnums() {
      var promise = MarcaService.getEnums();
      promise.then(function (data) {

      });
      $modalTracker.addPromise(promise);
    }

    function initTrackers() {
      viewModel.tracker = TrackerFactory.init([
        'save',
        'saveAdd',
        'excluir'
      ]);
    }

    function hasMarca() {
      return (viewModel.marca.id === undefined) ? MarcaId : viewModel.marca.id;
    }

    function existeMarca() {
      return Boolean(hasMarca());
    }

    function isAdd() {
      return hasMarca() ? 'Editando' : 'Adicionando';
    }

    function cancel() {
      $modalInstance.close();
    }

    function sendFocus() {
      $rootScope.$broadcast('marcaCad:focus');
    }

    function sendMarcaList(marca) {
      $rootScope.$broadcast('marcaCad:list', marca);
      $rootScope.$broadcast('marcaCad:UpdateList');
    }

    function save(continuar) {
      var tracker = continuar ? viewModel.tracker.saveAdd : viewModel.tracker.save;

      var promise = MarcaService.save(viewModel.marca);

      promise.then(function (marca) {

        if (MarcaId) {
          //Realiza um get no grupo para retornar todas as classes
          MarcaService.get(MarcaId).then(function (marca) {
            if (angular.isFunction(fnCad)) {
              fnCad(marca);
            }
            sendMarcaList(marcaEspecificacao);
          });
        } else {
          if (angular.isFunction(fnCad)) {
            fnCad(marca);
          }
          sendMarcaList(marca);
        }

        // Caso seja para Salvar e Adicionar novo...
        if (continuar) {
          if (viewModel.form) {
            viewModel.form.$setPristine();
            viewModel.form.$setUntouched();
          }
          // Zera options cad
          optionsCad = undefined;
          // Zera MarcaId
          MarcaId = undefined;
          // Reinicializa dados do controller
          init();

          //Foco no campo descrição
          Focus.set('descricaoMarcaCadControllerDescricao');
        } else {
          // Caso contrário fecha a modal
          cancel();
        }
        showNotificationSave();
      });
      tracker.addPromise(promise);
    }

    function showNotificationSave() {
      if (MarcaId) {
        Notification.publish('Marca alterada com sucesso', 'success');
      } else {
        Notification.publish('Marca adicionada com sucesso', 'success');
      }
    }

    function isAddNew() {
      return angular.isDefined(fnCad);
    }

    function emUso() {
      return viewModel.marca.emUso;
    }

    function excluir() {
      $rootScope.$broadcast('marca:actionRemove', {id: viewModel.marca.id});
      cancel();
    }
  }
})();
