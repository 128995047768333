(function() {
    'use strict';
    angular
        .module('core.material')
        .service('core.DesativarModalService', DesativarModalService);

    DesativarModalService.$inject = ['core.AcoesCadastrosModalService'];

    function DesativarModalService(AcoesCadastrosModalService) {

        var modal = {
            templateUrl: 'desativar-modal/desativar-modal.html',
            controller: 'core.MaterialDesativarController',
            controllerAs: 'desativar'
        };

        var service = {
            cadastrar: cadastrar,
            editar: editar,
            onAdd: onAdd
        };

        angular.extend(this, service);

        function abrir(modal) {
            return AcoesCadastrosModalService.abrir(modal);
        }

        function cadastrar() {
            AcoesCadastrosModalService.cadastrar(modal);
        }

        function editar(material) {
            AcoesCadastrosModalService.editar(modal, material);
        }

        function onAdd(params) {
            return AcoesCadastrosModalService.onAdd(modal, params);
        }
    }
})();