(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ctr.organograma.OrganogramaService
   * @description
   * Serviço que se comunica com o back-end de organogramas
   * @requires $q
   * @requires Restangular
   **/
  angular
    .module('core.classes')
    .service('core.ClassesService', ClassesService);

  ClassesService.$inject = ['$q', 'Restangular', '$timeout'];

  function ClassesService($q, Restangular, $timeout) {
    var meta;
    var uri = ['grupos', 'classes', 'naturezadespesactb'];
    var service = this;

    this.get = get;
    this.getList = getList;
    this.getDefault = getDefault;
//        this.setMetadata = setMetadata;
    this.save = save;
    this.getTypes = getTypes;
    this.remove = remove;
    this.promiseFake = promiseFake;
    this.getGrupoId = getGrupoId;
    this.setGrupoId = setGrupoId;
    this.getListNaturezaDespesa = getListNaturezaDespesa;
    this.getListClassesSelect = getListClassesSelect;
    this.setParams = setParams;

    function getGrupoId(grupoId) {
      if (grupoId)
        return grupoId;
      else
        return service.grupoId;
    }

    function setGrupoId(idGrupo) {
      service.grupoId = idGrupo;
    }

    function get(idGrupo, id) {
      return Restangular.one(uri[0], idGrupo).one(uri[1], id).get();
    }

    function getList(params) {
      params = params || {};
      params.offset = params.offset || 0;
      if (service.grupoId) {
        return Restangular.one(uri[0], service.grupoId)
          .one(uri[1])
          .get(service.params);
      }
      return Restangular
          .one(uri[1])
          .get(params);
    }

    function getListClassesSelect(params, idGrupo) {
      params = params || {};
      params.offset = params.offset || 0;
      return Restangular.one(uri[0], idGrupo)
        .one(uri[1])
        .get(params);
    }

    function getMetadata(idGrupo) {
      var deferred = $q.defer();
      if (!meta) {
        if (!idGrupo) {
          idGrupo = 0; //Hack pra pegar o options da classe quando for um grupo ainda não salvo.
        }
        Restangular.one(uri[0], idGrupo).all(uri[1]).options().then(function (data) {
          meta = data;
          deferred.resolve(meta);
        }, function (data) {
          deferred.reject(data);
        });
      } else {
        deferred.resolve(meta);
      }
      return deferred.promise;
    }

//        function setMetadata(data) {
//            if (!meta) {
//                meta = data;
//            }
//        }

    function getDefault(idGrupo) {
      return getMetadata(idGrupo).then(function (data) {
        return angular.copy(data.representation.default);
      });
    }

    function getTypes(idGrupo) {
      return getMetadata(idGrupo).then(function (data) {
        return angular.copy(data.types);
      });
    }

    function save(grupoId, data) {
      var rest = Restangular.one(uri[0], getGrupoId(grupoId)).all(uri[1]);
      return !data.id ?
        rest.post(data) :
        rest.customPUT(data, data.id);
    }

    function remove(grupoId, id) {
      return Restangular.one(uri[0], getGrupoId(grupoId)).one(uri[1], id).remove();
    }

    function promiseFake() {
      var deferred = $q.defer();
      $timeout(function () {
        deferred.resolve();
      }, 1000);
      return deferred.promise;
    }

    function getListNaturezaDespesa(params) {
      params = params || {};
      params.offset = params.offset || 0;
      return Restangular.one(uri[0], service.grupoId)
        .one(uri[1])
        .one(uri[2])
        .get(params);
    }

    function setParams(params){
      return service.params = params;
    }
  }
})();
