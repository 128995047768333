(function () {
  'use strict';
  angular
    .module('core.responsavel')
    .controller('core.ResponsavelCadController', ResponsavelCadController);

  ResponsavelCadController.$inject = [
    '$q',
    '$modalTracker',
    '$modalInstance',
    '$rootScope',
    'bfc.FocusCtrl',
    'core.TrackerFactory',
    'core.Select2Factory',
    'core.MasksService',
    'core.ResponsavelService',
    'core.ResponsavelPessoaSelect',
    'core.CargoSelect',
    'core.NaturezaCargoSelect',
    'core.ResponsavelInternoSelect',
    'bfc.Notification',
    'CamposAdicionaisService',
    'CPA_CONSTANTS'
  ];

  function ResponsavelCadController($q,
                                    $modalTracker,
                                    $modalInstance,
                                    $rootScope,
                                    Focus,
                                    TrackerFactory,
                                    Select2Factory,
                                    MasksService,
                                    ResponsavelService,
                                    ResponsavelPessoaSelect,
                                    CargoSelect,
                                    NaturezaCargoSelect,
                                    ResponsavelInternoSelect,
                                    Notification,
                                    CamposAdicionaisService,
                                    CPA_CONSTANTS) {

    var viewModel = this;
    var responsavelDefaultOptions = {};

    //Usado para teste Unitário
    viewModel.argumentos = ResponsavelCadController.$inject;

    inicializaTrackers();
    inicializa();

    // Métodos do controller
    viewModel.acaoCad = acaoCad;
    viewModel.adicionando = adicionando;
    viewModel.editando = editando;
    viewModel.buscaPessoa = buscaPessoa;
    viewModel.salvarAdicionar = salvarAdicionar;
    viewModel.salvar = salvar;
    viewModel.excluir = excluir;
    viewModel.desabilitaCpf = desabilitaCpf;
    viewModel.desabilitaSalvar = desabilitaSalvar;
    viewModel.mensagemGravacao = mensagemGravacao;
    viewModel.selecionaPessoa = selecionaPessoa;
    viewModel.alteraDataInativacao = alteraDataInativacao;

    // Métodos inicializadores
    function inicializa() {

      // Propriedades do controller
      viewModel.campos = {
        matricula: true
      };
      viewModel.defaults = {};

      viewModel.responsavel = {};
      viewModel.responsavel.pessoa = {};
      inicializaSelect2();
      inicializaPromises();
      inicializaCamposAdicionais();
      viewModel.visivel = 1;
    }

    function inicializaPromises() {
      var promises = $q.all([
        inicializaResponsavel(),
      ]);
      $modalTracker.addPromise(promises);
    }

    function selecionaPessoa() {
      if (viewModel.pessoaTemplate && viewModel.pessoaTemplate.pessoa) {
        ResponsavelService.getPessoa(viewModel.pessoaTemplate.pessoa.id).then(function (data) {
          viewModel.responsavel = data;
        });
      }
    }

    function inicializaTrackers() {
      viewModel.trackers = TrackerFactory.init(['salvar', 'salvarAdicionar', 'excluir', 'camposAdicionais']);
    }

    function inicializaSelect2() {
      viewModel.select2 = Select2Factory.init([{
        element: 'cargo',
        select2: CargoSelect
      }, {
        element: 'naturezaCargo',
        select2: NaturezaCargoSelect
      }, {
        element: 'nome',
        select2: ResponsavelPessoaSelect
      }, {
        element: 'interno',
        select2: ResponsavelInternoSelect
      }]);
    }

    function inicializaResponsavelId() {
      var promise = ResponsavelService.get($modalInstance.params.identificador_cadastro);
      promise.then(function (responsavel) {
        viewModel.responsavel = responsavel;
        viewModel.telefone = responsavel.pessoa.telefone;
        viewModel.email = responsavel.pessoa.email;
        viewModel.desabilitar = false;
      });
      return promise;
    }

    function inicializaCamposAdicionais() {
      var promise = ResponsavelService.getCamposAdicionais($modalInstance.params.identificador_cadastro)
          .then(function(data) {
            viewModel.campoAdicional = data;
          });

      return promise;
    }

    function inicializaResponsavelDefault() {
      if (viewModel.defaults.reponsavel) {
        var promise = $q.defer();
        promise.resolve(viewModel.defaults.reponsavel);
        return promise.promise;
      } else {
        var promise = ResponsavelService.getDefault();
        promise.then(function (responsavelDefault) {
          viewModel.defaults.responsavel = responsavelDefault;
          viewModel.responsavel = responsavelDefault;
          viewModel.responsavel.pessoa = undefined;
          viewModel.desabilitar = true;
          if ($modalInstance.params.params) {
            if ($modalInstance.params.params.nome) {
              viewModel.responsavel.pessoa = {};
              viewModel.responsavel.pessoa.nome = $modalInstance.params.params.nome;
              viewModel.pessoaTemplate = {};
              viewModel.pessoaTemplate.nome = viewModel.responsavel.pessoa.nome;
            }
          }
        });
      }

      return promise;
    }

    function inicializaResponsavel() {
      return $modalInstance.params.identificador_cadastro ? inicializaResponsavelId() : inicializaResponsavelDefault();
    }

    // Métodos
    function adicionaPromise(tracker, promise) {
      viewModel.trackers[tracker].addPromise(promise);
    }

    function salva(continuar) {
      validaEmail();
      if (viewModel.pessoaTemplate && viewModel.pessoaTemplate.nome) {
        viewModel.responsavel.pessoa.nome = viewModel.pessoaTemplate.nome;
      }
      if (viewModel.campoAdicional) {
        viewModel.responsavel.campoAdicional = viewModel.campoAdicional;
      }

      var tracker = continuar ? viewModel.trackers.salvarAdicionar : viewModel.trackers.salvar;
      var promise = ResponsavelService.save(viewModel.responsavel);
      promise.then(function (responsavel) {
        if (continuar) {
          $modalInstance.params.identificador_cadastro = undefined;
          viewModel.pessoaTemplate.nome = '';
          if ($modalInstance.params.params) {
            if ($modalInstance.params.params.nome) {
              $modalInstance.params.params.nome = undefined;
            }
          }
          inicializaResponsavelDefault();
          if (viewModel.form) {
            viewModel.form.$setPristine();
            viewModel.form.$setUntouched();
          }
          inicializaCamposAdicionais();
          viewModel.visivel = 1;
          viewModel.desabilitar = false;
          Focus.set('lbResponsavelCadControllerNome');
        } else {
          fechar(responsavel);
        }
        mensagemGravacao();
      });
      tracker.addPromise(promise);
    }

    function salvar() {
      salva(false);
    }

    function salvarAdicionar() {
      salva(true);
    }

    function fechar(responsavel) {
      $modalInstance.close(responsavel);
    }

    function excluir() {
      $rootScope.$broadcast('responsavel:actionRemove', {id: viewModel.responsavel.id});
      fechar();
    }

    function validaEmail() {
      if (viewModel.responsavel && viewModel.responsavel.pessoa) {
        if (viewModel.email === undefined) {
          viewModel.email = '';
        }
        if (viewModel.responsavel.pessoa.email === undefined) {
          viewModel.responsavel.pessoa.email = '';
        }
        if ((viewModel.email !== '' && viewModel.responsavel.pessoa.email === '') ||
          (viewModel.email === '' && viewModel.responsavel.pessoa.email === '')) {
          viewModel.responsavel.pessoa.email = null;
        }
        return true;
      }
    }

    function adicionando() {
      return !editando();
    }

    function editando() {
      return viewModel.responsavel && viewModel.responsavel.id;
    }

    function desabilitaSalvar() {
      return (viewModel.form.$dirty && viewModel.form.$invalid)
        || (viewModel.form.$pristine && !editando())
        || !CamposAdicionaisService.isFormValid(viewModel.campoAdicional);
    }

    function acaoCad() {
      return (editando()) ? 'Editando' : 'Adicionando';
    }

    function buscaPessoa() {
      if (viewModel.pessoaTemplate && viewModel.pessoaTemplate.pessoa && viewModel.pessoaTemplate.pessoa.id) {
        ResponsavelService.getPessoa(viewModel.pessoaTemplate.pessoa.id).then(function (busca) {
          if (busca.length !== 0) {
            viewModel.responsavel = busca.plain();
            if (busca.id === undefined) {
              viewModel.responsavel = busca;
              angular.extend(viewModel.responsavel, viewModel.representation);
            }
          }
        });
      }
    }

    function desabilitaCpf() {
      return !viewModel.pessoaTemplate || !(viewModel.responsavel.pessoa && viewModel.responsavel.pessoa.nome);
    }

    function mensagemGravacao() {
      Notification.publish('Responsável ' + ($modalInstance.params.identificador_cadastro ? 'alterado' : 'salvo') + ' com sucesso', 'success');
    }

    function alteraDataInativacao() {
      viewModel.responsavel.dtInativacao = undefined;
    }

  }
})();
