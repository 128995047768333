(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name contratos-core.natureza-despesa.service:NaturezaDespesaSelect
   * @description Serviço do select2 para NaturezaDespesa
   * @requires bfc.Select2Config
   * @requires NaturezaDespesaService
   **/
  angular
    .module('core.natureza-despesa')
    .service('core.NaturezaDespesaSelect', NaturezaDespesaSelect);

  NaturezaDespesaSelect.$inject = [
    'bfc.Select2',
    'core.NaturezaDespesaService',
    'core.ExercicioService',
    'CONFIG_SELECT2'
  ];

  function NaturezaDespesaSelect(Select2,
                                 NaturezaDespesaService,
                                 ExercicioService,
                                 CONFIG_SELECT2) {

    this.select2Config = select2Config;

    function select2Config(options) {
      var metodos = {
        formatResult: formatResult,
        formatSelection: formatSelection,
        formatValue: formatValue,
        initSelection: initSelection,
        result: _.debounce(result, CONFIG_SELECT2.DELAY),
        minimumInputLength : CONFIG_SELECT2.MINIMUMINPUT,
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT('NATUREZA_DESPESA'),
        dropdownAutoWidth: false
      };

      function formatResult(naturezaDespesa) {
        return getNomeExibicao(naturezaDespesa);
      }

      function formatSelection(naturezaDespesa) {
        return getNomeExibicao(naturezaDespesa);
      }

      function formatValue(naturezaDespesa) {
        return naturezaDespesa.numero;
      }

      function initSelection(element, callback) {
        var id = $(element).val();
        if (id) {
          var exercicio = ExercicioService.getExercicioContexto();
          NaturezaDespesaService.get(id, exercicio).then(callback);
        }
      }

      function result(params, callback) {
        var filter;
        if (params.term) {
          filter = '(descricao like "' + encodeURI(params.term)
            + '" or numero like "' + encodeURI(params.term) + '")';
        }
        var exercicio = ExercicioService.getExercicioContexto();
        var result = NaturezaDespesaService.getList(filter, exercicio);
        result.then(function (naturezasDespesa) {
          callback({
            //results: naturezasDespesa.content,
            //more: naturezasDespesa.hasNext
            results: naturezasDespesa,
            more: naturezasDespesa.hasNext
          });
        });
        return result;
      }

      function getNomeExibicao(naturezaDespesa) {
        if (naturezaDespesa.text) {
          return naturezaDespesa.text;
        }

        if (naturezaDespesa) {
          return naturezaDespesa.numeroFormatado;
        }
      }

      return Select2.create(angular.extend(metodos, options));
    }
  }
})();
