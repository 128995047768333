(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name contratos-core.responsavel.ResponsavelInternoSelect
   * @descripition
   * Serviço do select2 para definir se responsavel pertence ou não ao município
   * @requires bfc.Select2Config
   * @requires NaturezaCargoSelect
   **/
  angular
    .module('core.responsavel')
    .service('core.ResponsavelInternoSelect', ResponsavelInternoSelect);

  ResponsavelInternoSelect.$inject = [
    'bfc.Select2',
    'core.YesOrNoService',
    'CONFIG_SELECT2'
  ];

  function ResponsavelInternoSelect(Select2,
                                    YesOrNoService,
                                    CONFIG_SELECT2) {

    var service = {
      select2Config: select2Config,
      getYes: getYes,
      getNo: getNo
    };

    _.extend(this, service);

    function getYes() {
      return YesOrNoService.getYes();
    }

    function getNo() {
      return YesOrNoService.getNo();
    }

    function select2Config(options) {

      var methods = {
        id: options.id ? options.id : id,
        formatResult: options.formatResult ? options.formatResult : formatResult,
        formatSelection: options.formatSelection ? options.formatSelection : formatSelection,
        formatValue: options.formatValue ? options.formatValue : formatValue,
        initSelection: options.initSelection ? options.initSelection : initSelection,
        result: _.debounce(options.result ? options.result : result, CONFIG_SELECT2.DELAY),
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT(),
        dropdownAutoWidth: false,
        allowClear: false
      };


      function id(opcao) {
        return opcao.description ? opcao.description : opcao.key;
      }

      function formatResult(opcao) {
        return opcao.description;
      }

      function formatSelection(opcao) {
        return opcao && opcao.description;
      }

      function formatValue(opcao) {
        return opcao.key === "SIM" ? true : false;
      }

      function initSelection(element, callback) {
        var id = element.val() === '' ? "NAO" : "SIM";
        if (id) {
          YesOrNoService.getYesNo(id).then(callback);
        }
      }

      function result(params, callback) {
        var filter = params && params.term;
        var result = YesOrNoService.getYesOrNo(filter);
        result.then(function (opcao) {
          callback({
            results: opcao,
            more: opcao.hasNext
          });
        });
        return result;
      }

      return Select2.create(angular.extend(methods, options));
    }
  }
})();
