(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name contratos-core.responsavel.service:ResponsavelSelect
   * @description Serviço do select2 para responsavel
   * @requires bfc.Select2Config
   * @requires ResponsavelService
   **/
  angular
    .module('core.responsavel')
    .service('core.ResponsavelSelect', ResponsavelSelect);

  ResponsavelSelect.$inject = [
    'bfc.Select2',
    '$filter',
    'core.ResponsavelService',
    'CONFIG_SELECT2'
  ];

  function ResponsavelSelect(Select2,
                             $filter,
                             ResponsavelService,
                             CONFIG_SELECT2) {

    this.select2Config = select2Config;

    function select2Config(options) {
      var metodos = {
        formatResult: formatResult,
        formatSelection: formatSelection,
        formatValue: formatValue,
        initSelection: initSelection,
        result: _.debounce(result, CONFIG_SELECT2.DELAY),
        minimumInputLength : CONFIG_SELECT2.MINIMUMINPUT,
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT('PESSOA'),
        dropdownAutoWidth: false
      };

      function formatResult(responsavel) {
        return getNomeExibicao(responsavel);
      }

      function formatSelection(responsavel) {
        return getNomeExibicao(responsavel);
      }

      function formatValue(responsavel) {
        return responsavel.id;
      }

      function initSelection(element, callback) {
        var id = $(element).val();

        if (id) {
          ResponsavelService.get(id).then(callback);
        }
      }

      function result(params, callback) {
        if (params.term) {
          params.filter = '(nome like "' + encodeURI(params.term) + '" or cpf like "' + encodeURI(params.term) + '")';
        }
        params.total = false;
        var result = ResponsavelService.getList(params);
        result.then(function (responsavel) {
          callback({
            results: responsavel,
            more: responsavel.hasNext
          });
        });
        return result;
      }

      function getNomeExibicao(responsavel) {
        if (responsavel.text) {
          return responsavel.text;
        }
        if (responsavel) {
          return responsavel.pessoa.nome + ' (' + $filter('bfMaskCpfCnpj')(responsavel.pessoa.cpfCnpj) + ')';
        }
      }

      return Select2.create(angular.extend(metodos, options));
    }
  }
})();
