(function () {
	'use strict';

	angular.module('core.material')
		.directive('coreMaterialSelect', MaterialSelectDirective);

	MaterialSelectDirective.$inject = ['CONFIG_SELECT2'];

	function MaterialSelectDirective(CONFIG_SELECT2) {
		return {
			restrict: 'E',
			template: '<ui-select ng-model="ngModel" ng-disabled="ngDisabled" ng-change="ngChange()" bf-required="bfRequired" search="vm.search" format-selection="vm.formatSelection(data)" title="{{vm.titulo}}" format-result="vm.formataResultado(data)" add-value="onAddBinding()"></ui-select>',
			scope: {
				ngModel: '=ngModel',
				ngDisabled: '=?',
				bfRequired: '=?',
				hiddenAddValue: '=?',
				ngChange: '&?',
				criterionSearch: '=?',
				multiple: '=?',
				search: '=?',
				mostrarAvisoSaldo: '=?'
			},
			compile: function (element, attributes) {
				var MINIMUMINPUT = 1;
				if (attributes.multiple === 'true') {
					element.children('ui-select').attr('multiple', 'true');
				}
				element.children('ui-select').attr('minimum-input-length', MINIMUMINPUT);
				element.children('ui-select').attr('debounce-delay', CONFIG_SELECT2.DELAY);
				element.children('ui-select').attr('maximum-input-length', CONFIG_SELECT2.MAXIMUMINPUT('MATERIAL'));
			},
			controller: Controller,
			controllerAs: 'vm'
		};
	}

	Controller.$inject = [
		'$scope',
		'bfc.$$PermissionsService',
		'core.MaterialService',
		'core.MaterialCadModalService'
	];

	function Controller($scope, PermissionsService, service, modal) {
		var vm = this;
		vm.search = search;
		vm.formataResultado = formataResultado;
		vm.formatSelection = formatSelection;
		vm.onAdd = !PermissionsService.isRevokedOperation('MaterialPageMapping', 'criar') && onAdd;
		vm.params = $scope.criterionSearch;
		vm.searchFunction = $scope.search;
		vm.mostrarAvisoSaldo = $scope.mostrarAvisoSaldo;
		$scope.onAddBinding = onAddBinding;

		function search(params) {
			var paramsDefault = '(ativo=true) and (situacaoCadastral="OK") and ((descricao like "' + params.term + '") or (codigoMaterial like "' + params.term + '") or (unidadeCompra.simbolo like "' + params.term + '"))';
			params.filter = $scope.criterionSearch ? vm.params({termo: params.term}) : paramsDefault;
			params.total = false;
			vm.searchFunction = vm.searchFunction && typeof vm.searchFunction === "function" ? vm.searchFunction : service.getList;
			return vm.searchFunction(params).then(function (data) {
				return data;
			});
		}

		function formataResultado(value) {
			var alerta = '';
			var unidadeMedidaSimbolo = '';
			var codigo = value.codigoMaterial ? value.codigoMaterial + ' - ': '';
			vm.titulo = codigo + value.descricao;

			if (vm.mostrarAvisoSaldo && (value.saldoAtual === 0 || value.saldoNaData === 0)) {
				alerta = ' <i title="Material sem saldo no almoxarifado" class="fa fa-exclamation-triangle tx__yellow"></i>';
			}

			if (value.unidadeCompra && value.unidadeCompra.simbolo) {
				unidadeMedidaSimbolo = '<br><span style="font-size: 80%;">Unidade de medida: ' + value.unidadeCompra.simbolo + '</span>';
		  	}

			return codigo + value.descricao + alerta + unidadeMedidaSimbolo;
		}

		function onAddBinding() {
			if ($scope.hiddenAddValue) {
				return;
			}
			return vm.onAdd;
		}
		
		function formatSelection(value) {
			var codigo = value.codigoMaterial ? value.codigoMaterial + ' - ': '';
			vm.titulo = codigo + value.descricao;
			return codigo + value.descricao ;
		}

		function onAdd(descricao) {
			var param = {
				descricao: descricao
			};
			return modal.onAdd(param);
		}
	}
})();
