(function () {
    'use strict';
    /**
     * @ngdoc controller
     * @name contratos-core.ConfiguracaoEntidade.controller:ConfiguracaoEntidadeCadController
     * @description Controller da configuração de entidade
     **/
    angular
            .module('core.configuracao-entidade')
            .controller('core.ConfiguracaoEntidadeCadController', ConfiguracaoEntidadeCadController);

    ConfiguracaoEntidadeCadController.$inject = [
        '$rootScope',
        '$modalInstance',
        'bfc.Notification',
        'core.TrackerFactory',
        'UserAccessContext',
        'core.ConfiguracaoEntidadeService',
        'CamposAdicionaisService',
        'CPA_CONSTANTS',
        '$q'
    ];

    function ConfiguracaoEntidadeCadController(
            $rootScope,
            $modalInstance,
            Notification,
            TrackerFactory,
            UserAccessContext,
            ConfiguracaoEntidadeService,
            CamposAdicionaisService,
            CPA_CONSTANTS,
            $q) {

        var viewModel = this;

        var metodos = {
            salvar: salvar,
            desabilitaSalvar: desabilitaSalvar,
            getAcao: getAcao,
            temEntidade: temEntidade,
            abreEmails: abreEmails,
            removeEmail: removeEmail,
            temEmails: temEmails,
            abreTelefones: abreTelefones,
            removeTelefone: removeTelefone,
            temTelefones: temTelefones,
            abreEnderecos: abreEnderecos,
            removeEndereco: removeEndereco,
            temEnderecos: temEnderecos,
            tracker: undefined,
            getEnderecoPrincipal: getEnderecoPrincipal
        };

        var propriedades = {
            argumentos: ConfiguracaoEntidadeCadController.$inject
        };

        _.extend(this, metodos);
        _.extend(this, propriedades);

        inicializaLoadings();
        inicializar();

        function inicializar() {
            inicializarConfiguracaoEntidade();
            inicializaCamposAdicionais();
            viewModel.visivel = 1;
            viewModel.brasao = null;
            //viewModel.matriculaCeiCnds = {};
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init([
                'iniciaConfiguracaoEntidade',
                'salvar',
                'anular'
            ]);
        }

        function getEntidadeSelecionada() {
            return UserAccessContext.getUserAccess().values.entity;
        }

        function inicializarConfiguracaoEntidade() {
            var promise;
            promise = ConfiguracaoEntidadeService.get(getEntidadeSelecionada());
            promise.then(function (data) {
                viewModel.entidade = data.plain();
            });
            viewModel.loadings.iniciaConfiguracaoEntidade.addPromise(promise);
        }

        function inicializaCamposAdicionais() {
            var promise = ConfiguracaoEntidadeService.getCamposAdicionais(getEntidadeSelecionada())
                .then(function(data) {
                    viewModel.campoAdicional = data;
                });
            return promise;
        }
        
        /*function excluir() {
            var promise = ConfiguracaoEntidadeService.excluir(viewModel.entidade.id);
            return promise.then(function () {
                Notification.publish('Entidade excluída com sucesso', 'success');
                fechar();
            }, function(){
                return promise;
            });
            
        }*/

        function salvar() {
            var tracker = viewModel.loadings.salvar;
            
            if (viewModel.campoAdicional)
                viewModel.entidade.campoAdicional = viewModel.campoAdicional;

            viewModel.loadings.salvar.addPromise(
                $q.all([
                    salvarEntidade(),
                    salvarBrasao()
                ]).then(function (obj) {
                    fechar(obj);
                    showNotificationSave();
                })
            );
        }

        function salvarBrasao() {
            if (viewModel.brasao) {
                return ConfiguracaoEntidadeService.salvarBrasao(viewModel.entidade.id, viewModel.brasao);
            }

            return $q.resolve();
        }

        function salvarEntidade() {
            return ConfiguracaoEntidadeService.salvar(viewModel.entidade);
        }

        function showNotificationSave() {
            Notification.publish('Entidade alterada com sucesso', 'success');
        }

        function desabilitaSalvar() {
            return (viewModel.form.$dirty && viewModel.form.$invalid) || viewModel.form.$pristine || !CamposAdicionaisService.isFormValid(viewModel.campoAdicional);
        }

        function fechar(obj) {
            $modalInstance.close(obj);
        }

        function getAcao() {
            return $modalInstance.params.identificador_cadastro ? "Editando" : "Adicionando";
        }

        function temEntidade() {
            return $modalInstance.params.identificador_cadastro || (viewModel.entidade && viewModel.entidade.id);
        }

        function abreEmails() {
            Dialog.open({
                templateUrl: 'contratos-core/configuracao-entidade/components/emails/entidades-emails.cad.html',
                controller: 'EntidadesEmailsCtrl',
                controllerAs: 'EntidadesEmailsCtrl',
                resolve: {
                    emails: { dados: viewModel.entidade.emails }
                }
            });
        }

        function temEmails() {
            if (!viewModel.entidade.emails) {
                viewModel.entidade.emails = [];
            }
            return Boolean(viewModel.entidade.emails.length);
        }

        function removeEmail(index) {
            viewModel.entidade.emails = viewModel.entidade.emails.splice(1, index);
        }

        function abreTelefones() {
            Dialog.open({
                templateUrl: 'contratos-core/configuracao-entidade/components/telefones/entidades-telefones.cad.html',
                controller: 'EntidadesTelefonesCtrl',
                controllerAs: 'EntidadesTelefonesCtrl',
                resolve: {
                    telefones: { dados: viewModel.entidade.telefones }
                }
            });
        }

        function temTelefones() {
            if (!viewModel.entidade.telefones) {
                viewModel.entidade.telefones = [];
            }
            return Boolean(viewModel.entidade.telefones.length);
        }

        function removeTelefone(index) {
            viewModel.entidade.telefones = viewModel.entidade.telefones.splice(1, index);
        }

        function abreEnderecos() {
            Dialog.open({
                templateUrl: 'contratos-core/configuracao-entidade/components/enderecos/entidades-enderecos.cad.html',
                controller: 'EntidadesEnderecosCtrl',
                controllerAs: 'EntidadesEnderecosCtrl',
                resolve: {
                    enderecos: { dados: viewModel.entidade.enderecos }
                }
            });
        }

        function temEnderecos() {
            if (!viewModel.entidade.enderecos) {
                viewModel.entidade.enderecos = [];
            }
            return Boolean(viewModel.entidade.enderecos.length);
        }

        function removeEndereco(index) {
            viewModel.entidade.enderecos = viewModel.entidade.enderecos.splice(1, index);
        }

        function getEnderecoPrincipal() {
            return _.first(viewModel.entidade.enderecos);
        }

    }
})();