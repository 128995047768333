(function () {
  'use strict';

  /**
   * @ngdoc controller
   * @name contratos-core.prazo-entrega.controller:PrazoEntregaController
   * @description Controller da página principal de prazo-entrega
   * @requires bfc.dialog.Dialog
   * @requires TrackerFactory
   **/
  angular
    .module('core.prazo-entrega')
    .controller('core.PrazoEntregaController', PrazoEntregaController);

  PrazoEntregaController.$inject = [
    '$scope',
    '$injector'
  ];

  function PrazoEntregaController($scope,
                                  $injector) {

    var viewModel = this;

    var TrackerFactory = $injector.get('core.TrackerFactory'),
      Notification = $injector.get('bfc.Notification'),
      PrazoEntregaService = $injector.get('core.PrazoEntregaService'),
      PrazoEntregaCadService = $injector.get('core.PrazoEntregaCadService');

    inicializaLoadings();
    inicializa();

    function inicializa() {
      inicializaMetodos();
      inicializaPropriedades();
      inicializaEscutaEventos();
    }

    function inicializaEscutaEventos() {
      $scope.$on('prazoEntrega:actionRemove', function (eventos, params) {
        angular.element('#btnPrazoEntregaControllerExcluir-' + params.id).triggerHandler('click');
      });
    }

    function inicializaLoadings() {
      viewModel.loadings = TrackerFactory.init(['prazosEntrega']);
    }

    function inicializaMetodos() {
      viewModel.cadastrar = cadastrar;
      viewModel.editar = editar;
      viewModel.excluir = excluir;
    }

    function inicializaPropriedades() {
      viewModel.argumentos = PrazoEntregaController.$inject;
      viewModel.service = PrazoEntregaService;
    }

    function cadastrar() {
      PrazoEntregaCadService.cadastrar();
    }

    function editar(prazoEntrega) {
      PrazoEntregaCadService.editar(prazoEntrega);
    }

    function excluir(prazoEntrega) {
      var promise = PrazoEntregaService.remove(prazoEntrega);
      return promise.then(function () {
        Notification.publish('Prazo de entrega excluído com sucesso', 'success');
        viewModel.listaBusca.search();
      }, function error() {
        return promise;
      });
    }
  }
})();
