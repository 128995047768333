(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name contratos-core.responsavel.NaturezaCargoSelect
   * @descripition
   * Serviço do select2 para a natureza do cargo
   * @requires bfc.Select2Config
   * @requires NaturezaCargoSelect
   **/
  angular
    .module('core.responsavel')
    .service('core.NaturezaCargoSelect', NaturezaCargoSelect);

  NaturezaCargoSelect.$inject = [
    '$q',
    'bfc.Select2',
    'core.ResponsavelService',
    'core.UtilsService',
    'CONFIG_SELECT2'
  ];

  function NaturezaCargoSelect($q,
                               Select2,
                               ResponsavelService,
                               UtilsService,
                               CONFIG_SELECT2) {

    this.select2Config = select2Config;

    function select2Config(options) {
      var methods = {
        id: options.id ? options.id : id,
        formatResult: options.formatResult ? options.formatResult : formatResult,
        formatSelection: options.formatSelection ? options.formatSelection : formatSelection,
        formatValue: options.formatValue ? options.formatValue : formatValue,
        initSelection: options.initSelection ? options.initSelection : initSelection,
        result: _.debounce(options.result ? options.result : result, CONFIG_SELECT2.DELAY),
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT(),
        allowClear: true
      };

      function id(naturezaCargo) {
        return naturezaCargo.description ? naturezaCargo.description : naturezaCargo.key;
      }

      function formatResult(naturezaCargo) {
        return naturezaCargo.description;
      }

      function formatSelection(naturezaCargo) {
        return naturezaCargo && naturezaCargo.description;
      }

      function formatValue(naturezaCargo) {
        return naturezaCargo.key;
      }

      function initSelection(element, callback) {
        var id = element.val();

        if (id) {
          ResponsavelService.getNaturezaCargo(id).then(callback);
        }
      }

      function result(params, callback) {
        var filter = params && params.term;
        var result = ResponsavelService.getNaturezasCargo(filter);
        result.then(function (cargo) {
          callback({
            results: cargo,
            more: cargo.hasNext
          });
        });
        return result;
      }

      return Select2.create(angular.extend(methods, options));
    }
  }
})();
