(function () {
    'use strict';
    /**
     * @ngdoc overview
     * @name contratos-core.natureza-despesa
     * @description
     * # contratos-core.natureza-despesa
     * Módulo de natureza de despesa
     **/
    angular
            .module('core.natureza-despesa', ['core', 'core.commons', 'core.exercicio', 'core.integracao']);
})();
