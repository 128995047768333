(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ctr.especificacao.service:EspecificacaoSelect
   *
   * @description
   * Serviço para usar select2 para as especificações dos materiais
   *
   * @requires bfc.Select2
   * @requires EspecificacaoService
   **/
  angular
    .module('core.especificacao')
    .service('core.EspecificacaoSelect', EspecificacaoSelect);

  EspecificacaoSelect.$inject = [
    'bfc.Select2',
    'core.EspecificacaoService',
    'CONFIG_SELECT2'
  ];

  function EspecificacaoSelect(Select2,
                               EspecificacaoService,
                               CONFIG_SELECT2) {

    this.select2Config = select2Config;

    function select2Config(options) {
      var metodos = {
        formatResult: formatResult,
        formatSelection: formatSelection,
        formatValue: formatValue,
        initSelection: initSelection,
        result: _.debounce(result, CONFIG_SELECT2.DELAY),
        minimumInputLength : CONFIG_SELECT2.MINIMUMINPUT,
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT('ESPECIFICACAO'),
        dropdownAutoWidth: false
      };

      function formatResult(especificacao) {
        return getDescricaoExibicao(especificacao);
      }

      function formatSelection(especificacao) {
        return getDescricaoExibicao(especificacao);
      }

      function formatValue(especificacao) {
        return especificacao.plain();
        //return especificacao.id;
      }

      function initSelection(element, callback) {
        var id = $(element).val();

        if (id) {
          EspecificacaoService.get(EspecificacaoService.materialId, id).then(callback);
        }
      }

      function result(params, callback) {
        var filter;

        if (params.term) {
          filter = '(descricao like "' +encodeURI(params.term) + '")';
          var id = Number(params.term.replace(/[^\w]/g, ''));
          if (angular.isNumber(id) && !isNaN(id) && id.length < 13) {
            filter += ' or (id = ' + id + ')';
          }
        }

        var result = EspecificacaoService.getList(EspecificacaoService.materialId, filter);
        result.then(function (especificacoes) {
          callback({
            results: especificacoes,
            more: especificacoes.hasNext
          });
        });
        return result;
      }

      function getDescricaoExibicao(especificacao) {

        if (especificacao.text) {
          return especificacao.text;
        }

        if (especificacao) {
          return especificacao.descricao;
        }
      }

      return Select2.create(angular.extend(metodos, options));
    }
  }
})();
