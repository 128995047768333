(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name contratos-core.classes.ClassesSelect
   * @description
   * Serviço do select2 para classes
   * @requires bfc.Select2Config
   * @requires ClassesSelect
   **/

  angular
    .module('core.classes')
    .service('core.ClassesSelect', ClassesSelect);

  ClassesSelect.$inject = [
    'bfc.Select2',
    'core.ClassesService',
    'CONFIG_SELECT2'
  ];

  function ClassesSelect(Select2,
                         ClassesService,
                         CONFIG_SELECT2) {

    this.select2Config = select2Config;

    function select2Config(options) {
      var metodos = {
        formatResult: formatResult,
        formatSelection: formatSelection,
        formatValue: formatValue,
        initSelection: initSelection,
        result: _.debounce(result, CONFIG_SELECT2.DELAY),
        minimumInputLength : CONFIG_SELECT2.MINIMUMINPUT,
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT('CLASSES'),
        dropdownAutoWidth: false
      };

      function formatResult(classes) {
        return getNomeExibicao(classes);
      }

      function formatSelection(classes) {
        return getNomeExibicao(classes);
      }

      function formatValue(classes) {
        return classes.id;
      }

      function getNomeExibicao(classes) {

        if (classes.text) {
          return classes.text;
        }

        if (classes) {
          return classes.descricao;
        }
      }

      function initSelection(element, callback) {
        var id = $(element).val();
        if (id) {
          ClassesService.get(ClassesService.grupoId, id).then(callback);
        }
      }

      function result(params, callback) {
        params.filter = 'descricao like "' + params.term + '"'
        var result = ClassesService.getListClassesSelect(params, ClassesService.grupoId);
        result.then(function (classes) {
          callback({
            results: classes.content,
            more: classes.hasNext
          });
        });
        return result;
      }

      return Select2.create(angular.extend(metodos, options));
    }
  }
})();
