(function() {
	'use strict';

	angular
	  .module('core.marca')
	  .service('core.MarcaCadService', MarcaCadService);

	MarcaCadService.$inject = ['core.ModalCadServiceFactory'];

	function MarcaCadService(ModalCadServiceFactory) {

		_.assign(this, ModalCadServiceFactory.create({
			templateUrl: 'marca-cad/marca-cad.html',
            controller: 'core.MarcaCadController as marcaCad',
            resolve: {
            	MarcaId: null
            }
		}));
	}
})();
