(function () {
    'use strict';

    angular
        .module('core.cargo')
        .config(configuration);

    configuration.$inject = [
        'bfcStateProvider'
    ];

    function configuration(
        stateProvider) {

        var cargo = {
            route: {
                url: '/cargo-responsavel',
                data: {
                    id: 'CargoPageMapping'
                },
                views: {
                    '@main': {
                        templateUrl: 'cargo.html',
                        controller: 'core.CargoController',
                        controllerAs: 'cargo'
                    }
                }
            }
        };

        stateProvider
            .from('core')
            .create('core-cargo', cargo.route);
    }
})();