(function () {
    'use strict';

    /**
     * @ngdoc controller
     * @name contratos-core.cargo.controller:CargoController
     *
     * @description
     * Controller Modelos de Documentos
     *
     * @requires $scope
     * @requires promiseTracker
     * @requires $filter
     * @requires CargoService
     * @requires CargoModalService
     **/
    angular
        .module('core.cargo')
        .controller('core.CargoController', CargoController);

    CargoController.$inject = [
        '$rootScope',
        '$scope',
        'core.TrackerFactory',
        'core.CargoService',
        'core.CargoModalService',
        'bfc.Notification'
    ];

    function CargoController($rootScope,
                             $scope,
                             TrackerFactory,
                             CargoService,
                             CargoModalService,
                             Notification) {

        var viewModel = this;

        viewModel.cargoService = CargoService;

        viewModel.adicionar = adicionar;
        viewModel.editar = editar;
        viewModel.remover = remover;

        inicializar();

        // Funções inicializadoras
        function inicializar() {
            viewModel.loadings = {};
            inicializaLoadings();
            escutaEventos();
        }

        function escutaEventos() {
            $scope.$on('cargo:actionRemove', function (eventos, params) {
                angular.element('#hrefActionExcluir-' + params.id).triggerHandler('click');
            });
        }

        function inicializaLoadings() {
            viewModel.inicializaLoadings = TrackerFactory.init(['cargosList']);
        }

        function adicionar() {
            CargoModalService.cadastrar();
        }

        function editar(cargo) {
            CargoModalService.editar(cargo);
        }

        function remover(cargo) {
            var promise = CargoService.remove(cargo.id);
            return promise.then(function () {
                Notification.publish('Cargo excluído com sucesso', 'success');
            }, function error() {
                return promise;
            });
        }
    }
})();
