(function() {
	'use strict';
	angular
			.module('core.material')
			.service('core.CodigoBarraCadModalService', CodigoBarraCadModalService);

            CodigoBarraCadModalService.$inject = ['core.AcoesCadastrosModalService'];

	function CodigoBarraCadModalService(AcoesCadastrosModalService) {

		var modal = {
			templateUrl: 'material-cad/codigo-barra-cad/codigo-barra-cad.html',
			controller: 'core.CodigoBarraCadController',
			controllerAs: 'vm',
			params: {
				identificador_cadastro: null
            }
		};

		var service = {
			editar: editar
		};

		angular.extend(this, service);

		function editar(especificacao) {
			return AcoesCadastrosModalService.onAdd(modal, especificacao);
		}
	}
})();