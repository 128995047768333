(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name contratos-core.prazo-entrega.TipoExecucaoSelect
   * @descripition
   * Serviço do select2 para tipo de entrega/execução do prazo de entrega
   * @requires bfc.Select2Config
   * @requires TipoExecucaoSelect
   **/
  angular
    .module('core.prazo-entrega')
    .service('core.TipoExecucaoSelect', TipoExecucaoSelect);

  TipoExecucaoSelect.$inject = [
    '$q',
    'bfc.Select2',
    'core.PrazoEntregaService',
    'core.UtilsService',
    'CONFIG_SELECT2'
  ];

  function TipoExecucaoSelect($q,
                              Select2,
                              PrazoEntregaService,
                              UtilsService,
                              CONFIG_SELECT2) {

    this.select2Config = select2Config;

    function select2Config(options) {
      var methods = {
        id: options.id ? options.id : id,
        formatResult: options.formatResult ? options.formatResult : formatResult,
        formatSelection: options.formatSelection ? options.formatSelection : formatSelection,
        formatValue: options.formatValue ? options.formatValue : formatValue,
        initSelection: options.initSelection ? options.initSelection : initSelection,
        result: _.debounce(options.result ? options.result : result, CONFIG_SELECT2.DELAY),
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT()
      };

      function id(tipoExecucao) {
        return tipoExecucao.description ? tipoExecucao.description : tipoExecucao.key;
      }

      function formatResult(tipoExecucao) {
        return tipoExecucao.description;
      }

      function formatSelection(tipoExecucao) {
        return tipoExecucao && tipoExecucao.description;
      }

      function formatValue(tipoExecucao) {
        return tipoExecucao.key;
      }

      function initSelection(element, callback) {
        var id = element.val();

        if (id) {
          PrazoEntregaService.getTipoExecucao(id).then(callback);
        }
      }

      function result(params, callback) {
        var filter = params && params.term;
        var result = PrazoEntregaService.getTiposExecucao(filter);
        result.then(function (tiposExecucao) {
          callback({
            results: tiposExecucao,
            more: tiposExecucao.hasNext
          });
        });
        return result;
      }

      return Select2.create(angular.extend(methods, options));
    }
  }
})();
