(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name contratos-core.tipo-comprovante.TipoComprovanteService
     * @description Serviço que se comunica com o back-end de tipos de comprovantes
     * @require $q
     * @require Restangular
     **/

    angular
            .module('core.tipo-comprovante')
            .service('core.TipoComprovanteService', TipoComprovanteService);

    TipoComprovanteService.$inject = [
        '$q',
        'Restangular',
        '$filter',
        'ui.components.EventEmitter'
    ];

    function TipoComprovanteService(
            $q,
            Restangular,
            $filter,
            EventEmitter) {

        var meta;
        var uri = ['tiposcomprovante', 'tipocomprovantectb'];

        var orderBy = $filter('orderBy');

        var service = new EventEmitter();

        _.extend(service, TipoComprovanteService);

        service.get = get;
        service.getList = getList;
        service.getDefault = getDefault;
        service.getTypes = getTypes;
        service.salvar = salvar;
        service.excluir = excluir;
        service.getTipoComprovantePadrao = getTipoComprovantePadrao;
        service.getTiposComprovanteCTB = getTiposComprovanteCTB;

        return service;

        function get(id) {
            return Restangular.one(uri[0], id).get();
        }

        function getList(params) {
            params = params || {};

            params.offset = params.offset || 0;

            return Restangular.one(uri[0]).get({
                offset: params.offset,
                limit: params.limit,
                filter: params.filter,
                sort: params.sort
            }).then(function (data) {
                if (params.offset > 0 && data.total <= params.offset) {
                    params.offset = 0;
                    data = getList(params);
                }
                return data;
            });
        }
        
        function getTiposComprovanteCTB(params) {
            params = params || {};

            params.offset = params.offset || 0;

            return Restangular.one(uri[0]).one(uri[1]).get({
                offset: params.offset,
                limit: params.limit,
                filter: params.filter,
                sort: params.sort
            }).then(function (data) {
                if (params.offset > 0 && data.total <= params.offset) {
                    params.offset = 0;
                    data = getTiposComprovanteCTB(params);
                }
                return data;
            });
        }

        function getDefault() {
            return getMetadata().then(function (data) {
                return angular.copy(data.representation.default);
            });
        }

        function getMetadata() {
            var deferred = $q.defer();
            if (!meta) {
                Restangular.all(uri[0]).options().then(function (data) {
                    meta = data;
                    deferred.resolve(meta);
                }, function (data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }

        function getTypes() {
            return getMetadata().then(function (data) {
                return angular.copy(data.types);
            });
        }

        function salvar(tipoComprovante) {
            var rest = Restangular.all(uri[0]);
            if (tipoComprovante.id) {
                return rest.customPUT(tipoComprovante, tipoComprovante.id).then(emitDirty);
            } else {
                return rest.post(tipoComprovante).then(emitDirty);
            }
        }

        function excluir(id) {
            return Restangular.one(uri[0], id).remove().then(emitDirty);
        }

        function emitDirty(data) {
            service.emit('dirty');
            return data;
        }

        function getTipoComprovantePadrao() {
            return getMetadata().then(function (data) {
                var novo = data.enums.TipoComprovantePadraoEnum;
                var tipoPadraoEnum = {};
                angular.forEach(novo.values, function (valor, index) {
                    tipoPadraoEnum[valor.id] = valor;
                });
                tipoPadraoEnum.values = [
                    tipoPadraoEnum.NOTA_FISCAL,
                    tipoPadraoEnum.SENTENCA_JUDICIAL,
                    tipoPadraoEnum.GUIA_RECOLHIMENTO,
                    tipoPadraoEnum.OUTROS,
                    tipoPadraoEnum.RECIBO,
                    tipoPadraoEnum.FATURA,
                    tipoPadraoEnum.BILHETE_PASSAGEM,
                    tipoPadraoEnum.CUPOM_FISCAL,
                    tipoPadraoEnum.CONHECIMENTO
                ];
                return tipoPadraoEnum;
            });
        }
    }
})();