(function () {
  'use strict';

  /**
   * @ngdoc controller
   * @name contratos-core.prazo-entrega.controller:PrazoEntregaCadController
   * @description Controller responsável pelo cadastro de prazo de entrega
   **/
  angular
    .module('core.prazo-entrega')
    .controller('core.PrazoEntregaCadController', PrazoEntregaCadController);

  PrazoEntregaCadController.$inject = [
    '$rootScope',
    '$q',
    '$modalInstance',
    '$modalTracker',
    'bfc.FocusCtrl',
    '$injector'
  ];

  function PrazoEntregaCadController($rootScope,
                                     $q,
                                     $modalInstance,
                                     $modalTracker,
                                     Focus,
                                     $injector) {

    var viewModel = this;

    var TrackerFactory = $injector.get('core.TrackerFactory'),
      Notification = $injector.get('bfc.Notification'),
      Select2Factory = $injector.get('core.Select2Factory'),
      PrazoEntregaService = $injector.get('core.PrazoEntregaService'),
      TipoExecucaoSelect = $injector.get('core.TipoExecucaoSelect');

    var prazoEntregaDefault = {};

    inicializaLoadings();
    inicializa();

    function inicializa() {
      inicializaMetodos();
      inicializaPropriedades();
      inicializaAutocompletes();
      inicializaPromises();
    }


    function inicializaMetodos() {
      viewModel.adicionando = adicionando;
      viewModel.alteraDescricaoNumero = alteraDescricaoNumero;
      viewModel.editando = editando;
      viewModel.habilitaNumDiaMeses = habilitaNumDiaMeses;
      viewModel.descricao = descricao;
      viewModel.isExterno = isExterno;
      viewModel.salvar = salvar;
      viewModel.salvarAdicionar = salvarAdicionar;
      viewModel.emUso = emUso;
      viewModel.excluir = excluir;
      viewModel.existePrazoEntrega = existePrazoEntrega;
    }

    function inicializaPropriedades() {
      viewModel.argumentos = PrazoEntregaCadController.$inject;
      viewModel.enums = {};
      viewModel.representation = {};
      viewModel.types = {};
    }

    function inicializaAutocompletes() {
      viewModel.autocompletes = Select2Factory.init([{
        element: 'tipoExecucao',
        select2: TipoExecucaoSelect
      }]);
    }

    function inicializaLoadings() {
      viewModel.loadings = TrackerFactory.init(['salvar', 'salvarAdicionar', 'excluir']);
    }

    function inicializaPromises() {
      var promise = $q.all([
        inicializaEnums(),
        inicializaRepresentation(),
        inicializaTypes(),
        inicializaPrazoEntrega()
      ]);
      $modalTracker.addPromise(promise);
    }


    function isExterno() {
      return $modalInstance.params.params ? true : false;
    }

    function inicializaEnums() {
      var promise = PrazoEntregaService.getEnums();
      promise.then(function (enums) {
        viewModel.enums = enums;
      });
      return promise;
    }


    function inicializaRepresentation() {
      var promise = PrazoEntregaService.getRepresentation();
      promise.then(function (representation) {
        viewModel.representation = representation;
        return representation;
      });
      return promise;
    }

    function inicializaTypes() {
      var promise = PrazoEntregaService.getTypes();
      promise.then(function (types) {
        viewModel.types = types;
      });
      return promise;
    }

    function inicializaPrazoEntrega() {

      if ($modalInstance.params.identificador_cadastro) {
        promise = PrazoEntregaService.get($modalInstance.params.identificador_cadastro);
        promise.then(function (prazoEntrega) {
          viewModel.prazoEntrega = prazoEntrega;
        });
      } else {
        if (viewModel.representation.default) {
          var promise = $q.defer();
          promise.resolve(function () {
            viewModel.prazoEntrega = angular.copy(viewModel.representation.default);
          });
        } else {
          inicializaRepresentation().then(function (representation) {
            viewModel.prazoEntrega = angular.copy(representation.default);
            if($modalInstance.params.params){
              viewModel.prazoEntrega.descricao = $modalInstance.params.params.descricao;
              viewModel.form.$setDirty();
            }
          });
        }
      }
      return promise;
    }

    // Demais métodos
    function adicionando() {
      return !editando();
    }

    function alteraDescricaoNumero(prazoEntrega) {
      if (viewModel.responsavel) {
        angular.extend(viewModel.responsavel, {
          unidadeEntregaDesc: prazoEntrega.description
        });
      }
    }

    function descricao(unidadeEntrega) {
      var values = viewModel.enums && viewModel.enums.UnidadeEntregaEnum && viewModel.enums.UnidadeEntregaEnum.values;
      if (values) {
        for (var i = 0, length = values.length; i < length; i++) {
          if (unidadeEntrega === values[i].key) {
            return values[i].description;
          }
        }
      }
    }

    function editando() {
      return ($modalInstance.params.identificador_cadastro || viewModel.prazoEntrega && viewModel.prazoEntrega.id);
    }

    function existePrazoEntrega() {
      return Boolean(editando());
    }

    function enviar() {
      $rootScope.$broadcast('prazoEntregaCad:salvar', viewModel.prazoEntrega);
    }

    function habilitaNumDiaMeses() {
      var outros =
        viewModel.enums &&
        viewModel.enums.UnidadeEntregaEnum &&
        viewModel.enums.UnidadeEntregaEnum.values &&
        viewModel.enums.UnidadeEntregaEnum.values[2].key;

      var unidadeEntrega =
        viewModel.prazoEntrega &&
        viewModel.prazoEntrega.unidadeEntrega;

      if (angular.isDefined(unidadeEntrega) && angular.isDefined(outros) && unidadeEntrega === outros) {
        delete viewModel.prazoEntrega.numDiasMeses;
        return false;
      }
      return true;
    }

    function fechar(data) {
      $modalInstance.close(data);
    }

    function novo() {
      $modalInstance.params.identificador_cadastro = undefined;
      inicializa();

      if (viewModel.form) {
        viewModel.form.$setPristine();
        viewModel.form.$setUntouched();
        Focus.set('lbPrazoEntregaCadControllerDescricao');
      }
    }

    function salva() {
      var promise = PrazoEntregaService.save(viewModel.prazoEntrega);
      promise.then(function (prazoEntrega) {

        viewModel.prazoEntrega = prazoEntrega;
        enviar();
        if ($modalInstance.params.identificador_cadastro) {
          Notification.publish('Prazo de entrega alterado com sucesso', 'success');
        } else {
          Notification.publish('Prazo de entrega adicionado com sucesso', 'success');
        }
      });
      return promise;
    }

    function salvar() {
      var promise = salva();
      promise.then(function (data) {
        fechar(data);
      });
      viewModel.loadings.salvar.addPromise(promise);
    }

    function salvarAdicionar() {
      var promise = salva();
      promise.then(function () {
        novo();
      });
      viewModel.loadings.salvarAdicionar.addPromise(promise);
    }

    function emUso() {
      return viewModel.prazoEntrega && viewModel.prazoEntrega.emUso;
    }

    function excluir() {
      $rootScope.$broadcast('prazoEntrega:actionRemove', {id: viewModel.prazoEntrega.id});
      fechar();
    }
  }
})();
