(function() {
    'use strict';

    /**
     * @ngdoc controller
     * @name ctr.local-entrega.controller:LocalEntregaController
     * 
     * @description
     * Controller da página principal de local-entrega
     * 
     * @requires $scope
     * @requires $injector
     **/
    angular
        .module('core.local-entrega')
        .controller('core.LocalEntregaController', LocalEntregaController);

    LocalEntregaController.$inject = [
        '$scope',
        '$injector'
    ];

    function LocalEntregaController($scope,
                                    $injector) {

        var viewModel = this;

        var TrackerFactory = $injector.get('core.TrackerFactory'),
            Notification = $injector.get('bfc.Notification'),
            LocalEntregaService = $injector.get('core.LocalEntregaService'),
            LocalEntregaCadModalService = $injector.get('core.LocalEntregaCadModalService'),
            promiseTracker = $injector.get('promiseTracker');

        inicializaLoadings();
        inicializa();
        
        /**
         * @ngdoc method
         * @name ctr.local-entrega.LocalEntregaController#inicializa
         * @methodOf ctr.local-entrega.controller:LocalEntregaController
         * @description Configurações iniciais do controller
         **/
        function inicializa() {
            inicializaMetodos();
            inicializaPropriedadesPadroes();
            inicializaEscutaEventos();
        }

        /**
         * @ngdoc method
         * @name ctr.local-entrega.LocalEntregaController#inicializaMetodos
         * @methodOf ctr.local-entrega.controller:LocalEntregaController
         * @description Define os métodos do controller
         **/
        function inicializaMetodos() {
            viewModel.cadastrar = cadastrar;
            viewModel.editar = editar;
            viewModel.excluir = excluir;
            viewModel.emUso = emUso;
        }
        
        function inicializaPropriedadesPadroes() {
            viewModel.service = LocalEntregaService;
            viewModel.argumentos = LocalEntregaController.$inject;
            $scope.stopPropagation = false;
        }

        /**
         * @ngdoc method
         * @name ctr.local-entrega.LocalEntregaController#inicializaEscutaEventos
         * @methodOf ctr.local-entrega.controller:LocalEntregaController
         * @description Cria um listener para escutar evento de salvamento do cadastro de Locais de Entrega
         **/
        function inicializaEscutaEventos() {
             $scope.$on('localEntrega:refresh', function (eventos, params) {
                if(viewModel.listaBusca){
                    viewModel.listaBusca.search();
                }
                if (!eventos.defaultPrevented) {
                    eventos.defaultPrevented = true;
                }
            });
            $scope.$on('localEntrega:actionRemove', function (eventos, params) {
                if (!eventos.defaultPrevented) {
                    eventos.defaultPrevented = true;
                    setTimeout(function () {
                        angular.element('#btnLocalEntregaExcluir-' + params.id).triggerHandler('click');
                    }, 10);
                }
            });
        }
        
        /**
         * @ngdoc method
         * @name ctr.local-entrega.LocalEntregaController#inicializaLoadings
         * @methodOf ctr.local-entrega.controller:LocalEntregaController
         * @description Define os 'loadings' da página
         **/
        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init(['listagem', 'exclusao']);
        }


        /**
         * @ngdoc method
         * @name ctr.local-entrega.LocalEntregaController#cadastrar
         * @methodOf ctr.local-entrega.controller:LocalEntregaController
         * @description Adiciona um local de entrega a lista
         * @param {object} localEntrega Local de entrega à ser adicionado a listagem
         **/
        function cadastrar() {
            LocalEntregaCadModalService.cadastrar();
        }

        /**
         * @ngdoc method
         * @name ctr.local-entrega.LocalEntregaController#editar
         * @methodOf ctr.local-entrega.controller:LocalEntregaController
         * @description Edita um local de entrega existente
         * @param {Object} localEntrega  Objeto com a representação de local de entrega para ser editado.
         **/
        function editar(localEntrega) {
            LocalEntregaCadModalService.editar(localEntrega);
        }

        /**
         * @ngdoc method
         * @name ctr.local-entrega.LocalEntregaController#excluir
         * @methodOf ctr.local-entrega.controller:LocalEntregaController
         * @description Excluí um local de entrega
         * @param {Object} localEntrega Local de entrega a ser removido
         **/
        function excluir(localEntrega) {
            var promise = LocalEntregaService.remove(localEntrega.id);
            return promise.then(function() {
                Notification.publish('Local de entrega excluído com sucesso', 'success');
            }, function error() {
                return promise;
            });
        }

        /**
         * @ngdoc method
         * @name ctr.local-entrega.LocalEntregaController#emUso
         * @methodOf ctr.local-entrega.controller:LocalEntregaController
         * @description Excluí um local de entrega
         * @param {Object} uso Local de entrega a ser removido
         **/
        function emUso(uso) {
            return uso;
        }
    }
})();