(function () {
	'use strict';

	angular.module('core.material')
		.directive('coreMaterialCombustivelSelect', MaterialCombustivelSelectDirective);

	MaterialCombustivelSelectDirective.$inject = ['CONFIG_SELECT2'];

	function MaterialCombustivelSelectDirective(CONFIG_SELECT2) {
		return {
			restrict: 'E',
			template: '<ui-select ng-model="ngModel" ng-disabled="ngDisabled" ng-change="ngChange()" bf-required="bfRequired" search="vm.search" format-selection="vm.formatSelection(data)" format-result="vm.formataResultado(data)"></ui-select>',
			scope: {
				ngModel: '=ngModel',
				ngDisabled: '=?',
				bfRequired: '=?',
				hiddenAddValue: '=?',
				ngChange: '&?',
				criterionSearch: '=?',
				multiple: '=?'
			},
			compile: function (element, attributes) {
				var addValue = attributes.hiddenAddValue === 'true';
				if (!addValue) {
					element.children('ui-select').attr('add-value', 'vm.onAdd');
				}
				if (attributes.multiple === 'true') {
					element.children('ui-select').attr('multiple', 'true');
				}				
				element.children('ui-select').attr('minimum-input-length', CONFIG_SELECT2.MINIMUMINPUT);
				element.children('ui-select').attr('debounce-delay', CONFIG_SELECT2.DELAY);
				element.children('ui-select').attr('maximum-input-length', CONFIG_SELECT2.MAXIMUMINPUT('MATERIAL'));
			},
			controller: Controller,
			controllerAs: 'vm'
		};
	}

	Controller.$inject = [
		'$scope',
		'bfc.$$PermissionsService',
		'core.MaterialService',
		'core.MaterialCadModalService',
		'$filter'
	];

	function Controller($scope, PermissionsService, service, modal, $filter) {
		var vm = this;
		vm.search = search;
		vm.formataResultado = formataResultado;
		vm.formatSelection = formatSelection;
		vm.onAdd = !PermissionsService.isRevokedOperation('MaterialPageMapping', 'criar') && onAdd;
		vm.params = $scope.criterionSearch;

		function search(params) {
			var paramsDefault = '(classificacao="COMBUSTIVEL") and (ativo=true) and (situacaoCadastral="OK") and (descricao like "' + params.term + '") or (unidadeCompra.simbolo like "' + params.term + '")';

			params.filter = $scope.criterionSearch ? vm.params({termo: params.term}) : paramsDefault;
			return service.getList(params).then(function (data) {
				return data;
			});
		}

		function formataResultado(value) {
			var unidadeMedidaSimbolo = '';
			if (value.unidadeCompra && value.unidadeCompra.simbolo) {
				unidadeMedidaSimbolo = '<br><span style="font-size: 80%;">Unidade de medida: ' + value.unidadeCompra.simbolo + '</span>';
		  	}

			return value.descricao + unidadeMedidaSimbolo;
		}

		function formatSelection(value) {
		
			return  value.descricao ;
	}


		function onAdd(descricao) {
			var param = {
				descricao: descricao
			};
			return modal.onAdd(param);
		}
	}
})();
