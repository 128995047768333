(function() {
	'use strict';
	 angular
			.module('core.material')
			.controller('core.MaterialController', MaterialController);

	MaterialController.$inject = [
		'$scope',
		'promiseTracker',
		'$filter',
		'core.MaterialService',
		'core.UnidadeMedidaService',
		'bfc.Notification',
		'core.MaterialCadModalService',
		'core.TrackerFactory',
		'$searchExpressionBuilder',
        'core.DesativarModalService',
		'$rootScope',
		'$q',
        'core.Select2Factory',
		'CONFIG_SELECT2',
		'core.ClassesService'
	];

	function MaterialController(
			$scope,
			promiseTracker,
			$filter,
			MaterialService,
			UnidadeMedidaService,
			Notification,
			MaterialCadModalService,
			TrackerFactory,
			$searchExpressionBuilder,
            DesativarModalService,
			$rootScope,
			$q,
            Select2Factory,
			CONFIG_SELECT2,
			ClassesService) {

		    var viewModel = this;

		    viewModel.CONFIG_SELECT2 = CONFIG_SELECT2;


            viewModel.service = MaterialService;
            viewModel.serviceModalDesativar = DesativarModalService;
            viewModel.itensSelecionados = [];
            viewModel.listGridControls = {};
            viewModel.columns = [];
            viewModel.filtro = undefined;
            viewModel.itensSelecionadosAtivos = [];
            viewModel.condensed = false;
            viewModel.tipoMaterial = [];
            viewModel.classificacao = [];
            viewModel.tipoCombustivel = [];
            viewModel.sourceCustomParams = {
                json: ''
            };


            var customBuilders = {
                ativo: function () {
                    var filtroSituacaoConverter = {
                        'ATIVO': true,
                        'INATIVO': false
                    };

                    if (viewModel.filtro) {
                        return 'ativo is ' + filtroSituacaoConverter[viewModel.filtro];
                    }
                }
            };

            viewModel.customBuilder = {
                onBeforeBuild: function () {
                    if (viewModel.filter) {
                        viewModel.filter.others.ativo = undefined;
                    }
                    return $searchExpressionBuilder.build(viewModel.filter, customBuilders);
                }
            };


		    //Usado para teste Unitário
		    viewModel.argumentos = MaterialController.$inject;

            viewModel.cadastrar = cadastrar;
            viewModel.editar = editar;
            viewModel.excluir = excluir;
            viewModel.hasProblem = hasProblem;
            viewModel.getListRefresh = getListRefresh;
            viewModel.ativaItens = ativaItens;
            viewModel.inativaItens = inativaItens;
            viewModel.changeValue = changeValue;
            viewModel.getClasses = getClasses;
            viewModel.buscarUnidadeMedida = buscarUnidadeMedida;
            viewModel.formataResultado = formataResultado;
            viewModel.formataSelecao = formataSelecao;
            viewModel.formatValue = formatValue;
            viewModel.exibirTotalRegistrosFiltro = exibirTotalRegistrosFiltro;
            viewModel.isConsultandoTotalRegistrosFiltro = isConsultandoTotalRegistrosFiltro;



		    init();
		
            function init() {
                viewModel.material = {};
                viewModel.loadings = TrackerFactory.init(['listagem','exclusao']);
                escutaEventos();
            }

            function escutaEventos() {
            $scope.$on('material:actionRemove', function(eventos, params){
                angular.element('#btnExcluir-' + params.id).triggerHandler('click');
            });
            $scope.$on('material:inativaMaterial', function (eventos, param) {
                inativaItens(param);
            });
            }

		function hasProblem(material) {
			return material.situacaoCadastral === 1;
		}

		function cadastrar() {
			MaterialCadModalService.cadastrar();
		}

		function editar(material) {
			MaterialCadModalService.editar(material);
		}

		function excluir(material) {
			var promise = MaterialService.remove(material.id);
			return promise.then(function () {
				MaterialService.mensagemExclusao();
			}, function error() {
				return promise;
			});
		}

        function exibirTotalRegistrosFiltro(filtro) {
            return viewModel.filtro === filtro && !viewModel.loadings.listagem.active();
        }

        function isConsultandoTotalRegistrosFiltro(filtro) {
            return viewModel.filtro === filtro && viewModel.loadings.listagem.active();
        }

		function getListRefresh(filtro) {
            viewModel.itensSelecionados = [];
            viewModel.filtro = filtro;
            $rootScope.$broadcast('materiaisServico:relink-ui-list-grid');
            viewModel.listGridControls.search();
        }

        function ativaItens() {
            viewModel.itensSelecionadosAtivos = [];
            angular.forEach(viewModel.itensSelecionados, function (material) {
                var objeto = {};
                objeto['id'] = material.id;
                objeto['ativo'] = true;
                viewModel.itensSelecionadosAtivos.push(objeto);
            });
            MaterialService.alteraSituacao(viewModel.itensSelecionadosAtivos);
        }

        function inativaItens(dataInativacao) {
            viewModel.itensSelecionadosAtivos = [];
            angular.forEach(viewModel.itensSelecionados, function (material) {
                var objeto = {};
                objeto['id'] = material.id;
                objeto['ativo'] = false;
                objeto['dataInativacao'] = dataInativacao;
                viewModel.itensSelecionadosAtivos.push(objeto);
            });
            MaterialService.alteraSituacao(viewModel.itensSelecionadosAtivos);
        }

		function changeValue() {
            $scope.$watch(
                'lista.material.classe.grupo',
                function changeValue(newValue, oldValue) {
                    if (newValue && oldValue || newValue && !oldValue) {
                        if (newValue && !oldValue) {
                            limpaClasse(viewModel.material.classe.grupo.id, false);
                        } else if(newValue.id !== oldValue.id){
                            limpaClasse(viewModel.material.classe.grupo.id, true);
                        }
                    } else if (!newValue) {
                        limpaClasse(0, true);
                    }
                }
            );
        }

		function limpaClasse(id, param) {
            ClassesService.setGrupoId(id);
            if (viewModel.material.classe && param) {
                viewModel.material.classe.id = undefined;
                viewModel.material.classe.descricao = undefined;
            }
        }

		function getClasses() {
            if (viewModel.material.classe && viewModel.material.classe.grupo) {
                var promise = ClassesService.getListClassesSelect(null, viewModel.material.classe.grupo.id);
                return promise.then(function (data) {
                    return data;
                });
            }
        }

		function buscarUnidadeMedida() {
            var promise = UnidadeMedidaService.getList(null);
                return promise.then(function (data) {
                    return data;
                });
        }

        function formataResultado(value) {
            return getNomeExibicao(value);
        }

        function formataSelecao(unidadeMedida) {
            return getDescricao(unidadeMedida);
        }

		function formatValue(unidadeMedida) {
            return unidadeMedida;
          }

        function getNomeExibicao(unidadeMedida) {
            if (unidadeMedida.text) {
                return unidadeMedida.text;
            }
            if (unidadeMedida) {
                return unidadeMedida.descricao ? unidadeMedida.descricao : unidadeMedida.simbolo + ' - ' + unidadeMedida.nome;
            }
        }

        function getDescricao(unidadeMedida) {
            if (unidadeMedida.text) {
                return unidadeMedida.text;
            }
            if (unidadeMedida) {
                return unidadeMedida.descricao ? unidadeMedida.descricao : unidadeMedida.nome;
            }
        }

		/*--------------- Enum tipoMaterial------------------------------*/

        var tipoMaterialEnum = {
            MATERIAL: {
                "id": "MATERIAL",
                "key": "MATERIAL",
                "value": "MATERIAL",
                "description": "Material"
            },
            PERMANENTE: {
                "id": "PERMANENTE",
                "key": "PERMANENTE",
                "value": "PERMANENTE",
                "description": "Bem Permanente"
            },
            SERVICO: {
                "id": "SERVICO",
                "key": "SERVICO",
                "value": "SERVICO",
                "description": "Serviço"
            }
        };
        tipoMaterialEnum.values = [
            tipoMaterialEnum.MATERIAL,
            tipoMaterialEnum.PERMANENTE,
            tipoMaterialEnum.SERVICO
        ];
        viewModel.tipoMaterial = $q.when(tipoMaterialEnum);

		/*--------------- Enum classificacao ------------------------------*/

        var classificacaoEnum = {
            COMBUSTIVEL: {
                "id": "COMBUSTIVEL",
                "key": "COMBUSTIVEL",
                "value": "COMBUSTIVEL",
                "description": "Combustível"
            },
            GENEROS_ALIMENTICIOS: {
                "id": "GENEROS_ALIMENTICIOS",
                "key": "GENEROS_ALIMENTICIOS",
                "value": "GENEROS_ALIMENTICIOS",
                "description": "Gêneros alimentícios"
            },
            LUBRIFICANTE: {
                "id": "LUBRIFICANTE",
                "key": "LUBRIFICANTE",
                "value": "LUBRIFICANTE",
                "description": "Lubrificante"
            },
            MEDICAMENTO: {
                "id": "MEDICAMENTO",
                "key": "MEDICAMENTO",
                "value": "MEDICAMENTO",
                "description": "Medicamento"
            },
            PNEU: {
                "id": "PNEU",
                "key": "PNEU",
                "value": "PNEU",
                "description": "Pneu"
            },
            OUTROS: {
                "id": "OUTROS",
                "key": "OUTROS",
                "value": "OUTROS",
                "description": "Outros"
            }
        };
        classificacaoEnum.values = [
            classificacaoEnum.COMBUSTIVEL,
            classificacaoEnum.GENEROS_ALIMENTICIOS,
            classificacaoEnum.LUBRIFICANTE,
            classificacaoEnum.MEDICAMENTO,
            classificacaoEnum.PNEU,
            classificacaoEnum.OUTROS
        ];
        viewModel.classificacao = $q.when(classificacaoEnum);

		/*--------------- Enum tipoCombustivel ------------------------------*/

        var tipoCombustivelEnum = {
            BIODIESEL: {
                "id": "BIODIESEL",
                "key": "BIODIESEL",
                "value": "BIODIESEL",
                "description": "Biodiesel"
            },
            DIESEL: {
                "id": "DIESEL",
                "key": "DIESEL",
                "value": "DIESEL",
                "description": "Diesel"
            },
            ETANOL: {
                "id": "ETANOL",
                "key": "ETANOL",
                "value": "ETANOL",
                "description": "Etanol"
            },
            GASOLINA: {
                "id": "GASOLINA",
                "key": "GASOLINA",
                "value": "GASOLINA",
                "description": "Gasolina"
            },
            GNV: {
                "id": "GNV",
                "key": "GNV",
                "value": "GNV",
                "description": "GNV"
            },
            QUEROSENE: {
                "id": "QUEROSENE",
                "key": "QUEROSENE",
                "value": "QUEROSENE",
                "description": "Querosene"
            }
        };
        tipoCombustivelEnum.values = [
            tipoCombustivelEnum.BIODIESEL,
            tipoCombustivelEnum.DIESEL,
            tipoCombustivelEnum.ETANOL,
            tipoCombustivelEnum.GASOLINA,
            tipoCombustivelEnum.GNV,
            tipoCombustivelEnum.QUEROSENE
        ];
        viewModel.tipoCombustivel = $q.when(tipoCombustivelEnum);

	}
})();
