(function() {
    'use strict';
    /**
     * @ngdoc overview
     * @name contratos-core.marca
     * @description
     * # contratos-core.marca
     * Módulo de Marca
     **/
    angular.module('core.marca', ['core', 'core.commons']);
})();
