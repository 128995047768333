(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name contratos-core.responsavel.service:ResponsavelService
     * @descripition
     * Serviço de responsavel
     * @requires Restangular
     * @requires $q
     **/
    angular
        .module('core.responsavel')
        .factory('core.ResponsavelService', ResponsavelService);

    ResponsavelService.$inject = [
        '$q',
        'Restangular',
        'core.UtilsService',
        'bfc.Notification',
        'ui.components.EventEmitter',
        'CamposAdicionaisService'
    ];

    function ResponsavelService($q, Restangular, UtilsService, Notification, EventEmitter, CamposAdicionaisService) {
        var meta;
        var URI = {
            RESPONSAVEIS: 'responsaveis',
            RESPONSAVEIS_PESSOAS: 'responsaveis/pessoas',
            RESPONSAVEIS_CONSULTA: 'responsaveis/consulta',
            RESPONSAVEIS_PAGINACAO: 'paginacao',
            CAMPOS_ADICIONAIS: 'responsaveis/campos-adicionais',
            ALTERACAO_EM_LOTE: 'responsaveis/alteracao-em-lote'
        };

        var service = new EventEmitter();
        _.extend(service, ResponsavelService);

        service.consulta = consulta;
        service.get = get;
        service.getList = getList;
        service.getPessoa = getPessoa;
        service.getPessoasList = getPessoasList;
        service.getEnums = getEnums;
        service.getNaturezaCargo = getNaturezaCargo;
        service.getNaturezasCargo = getNaturezasCargo;
        service.getDefault = getDefault;
        service.getTypes = getTypes;
        service.save = save;
        service.remove = remove;
        service.emitDirty = emitDirty;
        service.mensagemExclusao = mensagemExclusao;
        service.getCamposAdicionais = getCamposAdicionais;
        service.ativarDesativarEmLote = ativarDesativarEmLote;
        service.DesativarEmLote = DesativarEmLote;

        return service;

        function get(id) {
            return Restangular.one(URI.RESPONSAVEIS, id).customGET(null, null, {
                'Accept': 'application/json, application/vnd.betha.lookup.extendido+json'
            });
        }

        function getList(params) {
            params = params || {};

            params.offset = params.offset || 0;
            params.total = params.total;
            debugger;
            return Restangular.one(URI.RESPONSAVEIS + '/' + URI.RESPONSAVEIS_PAGINACAO)
                .get(params);
        }

        function getPessoa(id) {
            return Restangular.one(URI.RESPONSAVEIS_PESSOAS, id).get();
        }

        function getPessoasList(filter) {
            return Restangular.all(URI.RESPONSAVEIS_PESSOAS).getList({
                filter: filter
            }).then(function (todos) {
                _(todos).forEach(function (value, key) {
                    todos[key] = value.plain();
                });
                return todos;
            });
        }

        function getEnums() {
            return getMetadata().then(function (data) {
                return angular.copy(data.enums);
            });
        }

        function getNaturezaCargo(key) {
            return getEnums().then(function (enums) {
                return UtilsService.getValueFromKey(enums.NaturezaCargoEnum.values, key);
            });
        }

        function getNaturezasCargo(filter) {
            return getEnums().then(function (enums) {
                return UtilsService.getFilteredValues(enums.NaturezaCargoEnum.values, filter);
            });
        }

        function getMetadata() {
            var deferred = $q.defer();
            if (!meta) {
                Restangular.all(URI.RESPONSAVEIS).options().then(function (data) {
                    meta = data;
                    deferred.resolve(meta);
                }, function (data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }

        function getDefault() {
            return getMetadata().then(function (data) {
                return angular.copy(data.representation.default);
            });
        }

        function getTypes() {
            return getMetadata().then(function (data) {
                return angular.copy(data.types);
            });
        }

        function save(responsavel) {
            var rest = Restangular.all(URI.RESPONSAVEIS);
            return !responsavel.id ?
                rest.post(responsavel).then(emitDirty) :
                rest.customPUT(responsavel, responsavel.id).then(emitDirty);
        }

        function remove(id) {
            return Restangular.one(URI.RESPONSAVEIS, id).remove().then(emitDirty);
        }

        function consulta(texto) {
            return Restangular.all(URI.RESPONSAVEIS_CONSULTA).getList({
                consulta: texto
            }).then(function (responsaveis) {
                _(responsaveis).forEach(function (value, key) {
                    responsaveis[key] = value.plain();
                });
                return responsaveis;
            });
        }

        function emitDirty(data) {
            service.emit('dirty');
            return data;
        }

        function mensagemExclusao() {
            Notification.publish('Responsável excluído com sucesso', 'success');
        }

        function getCamposAdicionais(responsavelId) {
            return CamposAdicionaisService.get({
                path: URI.CAMPOS_ADICIONAIS,
                origemId: responsavelId
            }).then(function(response) {
                return response.data;
            });
        }

        function ativarDesativarEmLote(idsResponsaveis, data, status) {
            return Restangular.all(URI.ALTERACAO_EM_LOTE)
            .customPUT({
                acao: status ? 'INATIVAR' :'ATIVAR' ,
                ids: idsResponsaveis,
                dataInativacao : data
            });
        }

        function DesativarEmLote(idsResponsaveis, status) {
            return Restangular.all(URI.ALTERACAO_EM_LOTE)
            .customPUT({
                acao: status ? 'INATIVAR' :'ATIVAR' ,
                ids: idsResponsaveis
            });
        }
    }
})();
