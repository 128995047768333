(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name contratos-core.marca.MarcaService
     * @descripition
     * Serviço de marca
     * @requires Restangular
     * @requires $q
     **/
    angular
            .module('core.marca')
            .service('core.MarcaService', MarcaService);

    MarcaService.$inject = ['$q', 'Restangular'];

    function MarcaService($q, Restangular) {
        var meta;
        var uri = 'marcas';

        var service = {
            get: get,
            getList: getList,
            getDefault: getDefault,
            getEnums: getEnums,
            getTypes: getTypes,
            save: save,
            remove: remove
        };

        angular.extend(this, service);

        function get(id) {
            return Restangular.one(uri, id).get();
        }

        function getList(filter) {
            return Restangular.all(uri).getList({
                filter: filter
            });
        }

        function getMetadata() {
            var deferred = $q.defer();
            if (!meta) {
                Restangular.all(uri).options().then(function(data) {
                    meta = data;
                    deferred.resolve(meta);
                }, function(data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }

        function getDefault() {
            return getMetadata().then(function(data) {
                return angular.copy(data.representation.default);
            });
        }

        function getTypes() {
            return getMetadata().then(function(data) {
                return angular.copy(data.types);
            });
        }

        function getEnums() {
            return getMetadata().then(function(data) {
                return angular.copy(data.enums);
            });
        }

        function save(data) {
            var rest = Restangular.all(uri);
            return !data.id ?
                    rest.post(data) :
                    rest.customPUT(data, data.id);
        }

        function remove(id) {
            return Restangular.one(uri, id).remove();
        }
    }
})();
