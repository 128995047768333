(function () {
	'use strict';

	angular.module('core.especificacao')
		.directive('coreEspecificacaoMaterialSelect', EspecificacaoMaterialSelectDirective);

	EspecificacaoMaterialSelectDirective.$inject = ['CONFIG_SELECT2'];

	function EspecificacaoMaterialSelectDirective(CONFIG_SELECT2) {
		return {
			restrict: 'E',
			template: '<ui-select ng-model="ngModel" ng-disabled="ngDisabled" ng-change="ngChange()" search="vm.search" format-result="vm.formataResultado(data)" title="{{vm.titulo}}"></ui-select>',
			scope: {
				ngModel: '=ngModel',
				ngDisabled: '=?',
				bfRequired: '=?',
				hiddenAddValue: '=?',
				ngChange: '&?',
				criterionSearch: '=?',
				multiple: '=?'
			},
			compile: function (element, attributes) {
				var addValue = attributes.hiddenAddValue === 'true';
				if (!addValue) {
					element.children('ui-select').attr('add-value', 'vm.onAdd');
				}

				var bfRequired = attributes.bfRequired === 'true';
				if (!bfRequired) {
					element.children('ui-select').attr('bf-required', 'false');
				}
				
				if (attributes.multiple === 'true') {
					element.children('ui-select').attr('multiple', 'true');
				}
				element.children('ui-select').attr('minimum-input-length', CONFIG_SELECT2.MINIMUMINPUT);
				element.children('ui-select').attr('debounce-delay', CONFIG_SELECT2.DELAY);
				element.children('ui-select').attr('maximum-input-length', CONFIG_SELECT2.MAXIMUMINPUT('ESPECIFICACAO'));
			},
			controller: Controller,
			controllerAs: 'vm'
		};
	}

	Controller.$inject = [
		'$scope',
		'bfc.$$PermissionsService',
		'core.EspecificacaoService',
		'core.MaterialCadModalService',
		'$filter'
	];

	function Controller($scope, PermissionsService, service, modal, $filter) {
		var vm = this;
		vm.search = search;
		vm.formataResultado = formataResultado;
		vm.onAdd = !PermissionsService.isRevokedOperation('MaterialPageMapping', 'criar') && onAdd;
		vm.params = $scope.criterionSearch;

		function search(params) {
			var paramsDefault = '((descricao like "' + params.term + '") or (codigoEspecificacao like "' + params.term + '"))';

			params.filter = $scope.criterionSearch ? vm.params({termo: params.term}) : paramsDefault;
			return service.getList(service.materialId, params).then(function (data) {
				var content = {
					content : data.plain() // simula que o backend está retornando paginado os dados
				};
				return content;
			});
		}

		function formataResultado(value) {
			var codigo = value.codigoEspecificacao ? value.codigoEspecificacao + ' - ': '';
			vm.titulo = codigo + value.descricao;
			return codigo + value.descricao;
		}

		function onAdd(descricao) {
			var param = {
				idMaterial: service.materialId,
				descricao: descricao
			};
			return modal.onAdd(param);
		}
	}
})();
