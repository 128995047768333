(function () {
    'use strict';
    angular
        .module('core.responsavel')
        .service('core.ResponsavelDesativarModalService', ResponsavelDesativarModalService);

    ResponsavelDesativarModalService.$inject = ['core.AcoesCadastrosModalService'];

    function ResponsavelDesativarModalService(AcoesCadastrosModalService) {

        var service = {
            cadastrar: cadastrar,
        };

        angular.extend(this, service);

        function cadastrar(params, status) {

            var modal = {
                templateUrl: 'responsavel-desativar-modal/responsavel-desativar-modal.html',
                controller: 'core.ResponsavelDesativarModalController',
                controllerAs: 'vm',
                params:{listaIds: params,
                    status: status
                    }
            }
            AcoesCadastrosModalService.cadastrar(modal, params);
        }

    }
})();