(function () {
  'use strict';

  angular.module('core.classes')
    .directive('coreClassesSelect', classesSelect);

  classesSelect.$inject = ['CONFIG_SELECT2'];

  function classesSelect(CONFIG_SELECT2) {
    return {
      restrict: 'E',
      template: '<ui-select ng-model="ngModel" bf-required="bfRequired" ng-change="ngChange()" search="vm.search" format-result="vm.formatResult(data)"></ui-select>',
      scope: {
        ngModel: '=ngModel',
        bfRequired: '=?',
        hiddenAddValue: '=?',
        criterionSearch: '=?',
        ngChange: '&?',
        identifierType: '=',
        multiple: '=?',
      },
      compile: function (element, attributes) {
        var addValue = attributes.hiddenAddValue === 'true';
        if (!addValue) {
          element.children('ui-select').attr('add-value', 'vm.onAdd');
        }
        if (attributes.multiple === 'true') {
          element.children('ui-select').attr('multiple', 'true');
        }
        element.children('ui-select').attr('minimum-input-length', CONFIG_SELECT2.MINIMUMINPUT);
        element.children('ui-select').attr('debounce-delay', CONFIG_SELECT2.DELAY);
        element.children('ui-select').attr('maximum-input-length', CONFIG_SELECT2.MAXIMUMINPUT('CLASSES'));
      },
      controller: Controller,
      controllerAs: 'vm'
    };
  }

  Controller.$inject = [
    '$q',
    '$scope',
    'bfc.$$PermissionsService',
    'core.ClassesService',
    'core.ClassesCadModalService'
  ];

  function Controller($q, $scope, PermissionsService, service, modal) {
    var vm = this;
    vm.search = search;
    vm.formatResult = formatResult;
    vm.onAdd = !PermissionsService.isRevokedOperation('ClassePageMapping', 'criar') && onAdd;
    vm.params = $scope.criterionSearch;

    function search(params) {
      var paramsDefault = '(descricao like "' + params.term + '")';
      params.filter = $scope.criterionSearch ? vm.params({termo: params.term}) : paramsDefault;

      if (!$scope.identifierType || $scope.identifierType.length === 0) return $q.reject();

      if ($scope.identifierType.length) {
        const ids = $scope.identifierType.map(function(i) {
          return i.id;
        });
        params.filter = '(' + params.filter + ') and idGrupo in (' + ids.join(',') + ')';
        return service.getList(params).then(function (data) {
          return data;
        });
      }

      return service.getListClassesSelect(params, $scope.identifierType.id).then(function (data) {
        return data;
      });
    }

    function onAdd(descricao) {
      var param = {
        classe: descricao
      };
      var optionsCad = {
        classe: {
          descricao: descricao
        }
      };
      return modal.cadastrarExterno(null, optionsCad, $scope.identifierType.id);
    }

    function formatResult(value) {
      return value.descricao;
      //return value.descricao || value.classificacaoObra.descricao;
    }
  }
})();
