(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name contratacao.local-entrega.service:LocalEntregaCadModalService
     * @description Serviço para abrir o cadastro de locais de entrega
     * @requires ModalCadFactory
     **/
    angular
        .module('core.local-entrega')
        .service('core.LocalEntregaCadModalService', LocalEntregaCadModalService);

    LocalEntregaCadModalService.$inject = ['core.AcoesCadastrosModalService'];

    function LocalEntregaCadModalService(AcoesCadastrosModalService) {

        var modal = {
            templateUrl: 'local-entrega-cad/local-entrega-cad.html',
            controller: 'core.LocalEntregaCadController',
            controllerAs: 'vm',
            params: {
                identificador_cadastro: null
            },
            size: 'lg'
        };

        var service = {
            cadastrar: cadastrar,
            editar: editar,
            onAdd: onAdd
        };

        angular.extend(this, service);

        function abrir(modal) {
            return AcoesCadastrosModalService.abrir(modal);
        }

        function cadastrar() {
            AcoesCadastrosModalService.cadastrar(modal);
        }

        function editar(localEntrega) {
            AcoesCadastrosModalService.editar(modal, localEntrega);
        }

        function onAdd(params) {
            return AcoesCadastrosModalService.onAdd(modal, params);
        }
    }
})();