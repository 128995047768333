(function () {
    'use strict';

    angular
        .module('core.fonte-divulgacao')
        .config(configuration);

    configuration.$inject = [
        'bfcStateProvider'
    ];

    function configuration(
        stateProvider) {

        var fonte = {
            route: {
                url: '/fonte-divulgacao',
                data: {
                    id: 'FonteDivulgacaoPageMapping'
                },
                views: {
                    '@main': {
                        templateUrl: 'fonte-divulgacao.html',
                        controller: 'core.FonteDivulgacaoController',
                        controllerAs: 'fonteDivulgacao'
                    }
                }
            }
        };

        stateProvider
            .from('core')
            .create('core-fonte-divulgacao', fonte.route);
    }
})();