(function () {
  'use strict';

  /**
   * @ngdoc controller
   * @name contratos-core.grupos.controller:GruposController
   * @description Controller de grupos de materiais
   * @requires $scope
   * @requires bfc.dialog.Dialog
   * @requires TrackerFactory
   * @requires GruposService
   * @requires ClassesService
   **/
  angular
    .module('core.grupos')
    .controller('core.GruposController', GruposController);

  GruposController.$inject = [
    '$scope',
    'promiseTracker',
    'core.TrackerFactory',
    'core.GruposService',
    'core.ClassesService',
    'core.GruposModalService',
    'bfc.Notification',
    '$searchExpressionBuilder'
  ];

  function GruposController($scope,
                            promiseTracker,
                            TrackerFactory,
                            GruposService,
                            ClassesService,
                            GruposModalService,
                            Notification,
                            $searchExpressionBuilder) {

    var viewModel = this;

    //Usado para teste unitário
    viewModel.argumentos = GruposController.$inject;
    viewModel.service = GruposService;
    viewModel.serviceClasse = ClassesService;
    viewModel.filterExpression = '';
    viewModel.msgVazio = "Ainda não há grupos por aqui";
    viewModel.itemsPerPage = 20;
    viewModel.currentPage = 1;
    viewModel.searchFilter = {};
    viewModel.columns = [
      {
        id: 'descricao',
        label: 'Descrição',
        model: 'descricao',
        filterable: true
      }
    ];

    viewModel.inicializaGrupos = inicializaGrupos;
    viewModel.remover = remover;
    viewModel.carregaClasses = carregaClasses;
    viewModel.adicionar = adicionar;
    viewModel.editar = editar;
    viewModel.alteraClicado = alteraClicado;

   /* // Métodos do controller
    angular.extend(this, {
      carregarClasses: carregarClasses,
      editar: editar,
      excluir: excluir,
      novo: novo,
      isEmptyList: isEmptyList,
      getShowClass: getShowClass,
      clean: clean,
      isSearchEmpty: isSearchEmpty,
      emUso: emUso,
      preventToggle: preventToggle
    });*/

    inicializar();

    function inicializar() {
      viewModel.grupos = {};
      inicializaLoadings();
      inicializaGrupos();
      inicializarEscutaEventos();
    }

    // --- Métodos inicializadores ---
    function inicializarEscutaEventos() {
      $scope.$on('gruposCad:list', listaGrupos);

      $scope.$on('grupos:actionRemove', function (eventos, params) {
        setTimeout(function () {
          angular.element('#hrefGruposControllerExcluirGrupos-' + params.id).triggerHandler('click');
        }, 10);
      });
    }

    function inicializaLoadings() {
      viewModel.loadings = TrackerFactory.init([
        'inicializaGrupos'
      ]);
    }

    function inicializaGrupos(){
      listaGrupos();
    }

    function listaGrupos() {
      var promise,
        params;
      params = getFiltroPaginacao();
      promise = GruposService.getList(params);
      
      viewModel.loadings.inicializaGrupos.addPromise(promise);

      promise.then(function (data) {
        viewModel.grupos = data.plain();
        gruposPorAgrupador(data.content);
      });
      
    }

    function getFiltroPaginacao(index) {
      viewModel.filterExpression = $searchExpressionBuilder.build(viewModel.searchFilter);
      var currentPage = index >= 0 ? viewModel.currentPageAgrupado[index] - 1 : viewModel.currentPage - 1;
      viewModel.msgVazio = viewModel.filterExpression === '' ? angular.copy(viewModel.msgVazio) : 'Nenhum resultado encontrado para o(s) filtro(s) utilizado(s)';
      var offset;
      if (index >= 0) {
        offset = currentPage > 0 ? currentPage * viewModel.itemsPerPageAgrupado[index] : 0;
      } else {
        offset = currentPage > 0 ? currentPage * viewModel.itemsPerPage : 0
      }
      var params = {
        offset: offset,
        limit: index >= 0 ? viewModel.itemsPerPageAgrupado[index] : viewModel.itemsPerPage,
        filter: viewModel.filterExpression,
        sort: ''
      };
      return params;
    }

    function gruposPorAgrupador(grupos) {
      viewModel.loadings.agrupadoresGrupos = [];
      viewModel.gruposAgrupado = [];
      viewModel.listPage = [];
      viewModel.currentPageAgrupado = [];
      viewModel.itemsPerPageAgrupado = [];
      if (grupos) {
        _.forEach(grupos, function (value, key) {
          viewModel.loadings.agrupadoresGrupos.push(promiseTracker());
          viewModel.currentPageAgrupado[key] = 1;
          viewModel.itemsPerPageAgrupado[key] = 20;
        });
      }
    }

    function remover(grupo) {
      var promise = GruposService.remove(grupo.id);
      return promise.then(function () {
        Notification.publish('Grupo excluído com sucesso', 'success');
        inicializaGrupos();
      }, function error() {
        return promise;
      });
    }

    function carregaClasses(data, index, bfShowHideRender) {
      ClassesService.setGrupoId(data.id);
      alteraClicado(data);
      if (bfShowHideRender){
        viewModel.currentPageAgrupado[index] = 1;
        return;
      }
      ClassesService.setParams(getFiltroPaginacao(index));
      viewModel.gruposAgrupado[index].search();
    }

    function adicionar() {
      ClassesService.setGrupoId(0);
      GruposModalService.cadastrar();
    }

    function editar(grupo) {
      ClassesService.setGrupoId(grupo.id);
      GruposModalService.editar(grupo);
    }

    function alteraClicado(grupo) {
      viewModel.grupos.content.forEach(function(g) {
        g._clicked = false;
      });
      grupo._clicked = true;
    }
  }
})();
