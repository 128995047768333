(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name contratos-core.cargo.CargoService
     * @descripition
     * Serviço de cargo
     * @requires Restangular
     * @requires $q
     **/
    angular
            .module('core.cargo')
            .service('core.CargoService', CargoService);

    CargoService.$inject = [
        '$q',
        'Restangular',
        'ui.components.EventEmitter'
    ];

    function CargoService($q,
            Restangular,
            EventEmitter) {

        var meta;
        var uri = ['cargos'];

        var service = new EventEmitter();

        _.extend(service, CargoService);

        service.get = get;
        service.getDefault = getDefault;
        service.getList = getList;
        service.getTypes = getTypes;
        service.remove = remove;
        service.save = save;
        service.emitDirty = emitDirty;

        return service;

        function get(id) {
            return Restangular.one(uri[0], id).get();
        }

        function getDefault() {
            return getMetadata().then(function (data) {
                return angular.copy(data.representation.default);
            });
        }

        function getList(params) {
            params = params || {};
            params.offset = params.offset || 0;
            return Restangular.one(uri[0])
                    .get(params);
        }

        function getMetadata() {
            var deferred = $q.defer();
            if (!meta) {
                Restangular.all(uri[0]).options().then(function (data) {
                    meta = data;
                    deferred.resolve(meta);
                }, function (data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }

        function getTypes() {
            return getMetadata().then(function (data) {
                return angular.copy(data.types);
            });
        }

        function remove(id) {
            return Restangular.one(uri[0], id)
                    .remove()
                    .then(emitDirty);
        }

        function save(data) {
            var rest = Restangular.all(uri[0]);
            return !data.id ?
                    rest.post(data).then(emitDirty) :
                    rest.customPUT(data, data.id).then(emitDirty);
        }

        function emitDirty(data) {
            service.emit('dirty');
            return data;
        }
    }
})();
