(function () {
    'use strict';

    angular.module('core.tipo-comprovante')
            .directive('coreSelectDescricaoTiposComprovante', SelectDescricaoTiposComprovanteDirective);

    function SelectDescricaoTiposComprovanteDirective() {
        var directive = {
            restrict: 'E',
            templateUrl: 'select-descricao-tipos-comprovante.directive.html',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                onSelect: '&'
            },
            controller: SelectDescricaoTiposComprovanteCtrl,
            controllerAs: 'SelectDescricaoTiposComprovanteCtrl'
        };

        SelectDescricaoTiposComprovanteCtrl.$inject = [
            '$injector',
            '$scope',
            'core.TipoComprovanteService'];

        function SelectDescricaoTiposComprovanteCtrl(
                $injector,
                $scope,
                TipoComprovanteService) {

            var viewModel = this;

            var propriedadesPublicas = {
                search: _search,
                select: _select,
                inputKeyUp: _inputKeyUp,
                descricaoKeyUp: _descricaoKeyUp,
                hideAutoCompleteInput: _hideAutoCompleteInput,
                hideAutoCompleteOver: _hideAutoCompleteOver,
                hideAutoCompleteOut: _hideAutoCompleteOut
            };

            _.extend(viewModel, propriedadesPublicas);

            _search();

            function _search() {
                viewModel.tipos = [];

                if (!$scope.ngModel || $scope.ngModel.id || !$scope.ngModel.descricao)
                    return;

                viewModel.showAutoComplete = true;
                viewModel.showAutoCompleteMouseOver = false;

                var params = {
                    filter: 'descricao like "%' + $scope.ngModel.descricao + '%"'
                };

                console.log('iniciando busca');
                return TipoComprovanteService.getTiposComprovanteCTB(params).then(function (tipos) {
                    console.log('qtd' + tipos.content.length);
                    viewModel.tipos = tipos.content;
                    if (!viewModel.tipos.length) {
                        viewModel.showAutoComplete = false;
                    }
                });
            }

            function _select(tipo) {
                if ($scope.onSelect) {
                    $scope.onSelect({tipo: tipo});
                }

                viewModel.showAutoComplete = false;
            }

            function _inputKeyUp($event) {
                $event.preventDefault();
                if ($event.keyCode === 40 || $event.keyCode === 38) {
                    $('.list-unstyled li:first-child').find('a').focus();
                }
            }

            function _descricaoKeyUp($event) {
                $event.preventDefault();
                if ($event.keyCode === 40) {
                    $($event.target).parent().next().find('a').focus();
                } else if ($event.keyCode === 38) {
                    $($event.target).parent().prev().find('a').focus();
                }
            }
            
            function _hideAutoCompleteInput(){
                viewModel.showAutoComplete = viewModel.showAutoCompleteMouseOver;
            }
            
            function _hideAutoCompleteOver(){
                viewModel.showAutoCompleteMouseOver = true;
            }
            
            function _hideAutoCompleteOut(){
                viewModel.showAutoCompleteMouseOver = false;
            }
        }

        return directive;
    }
})();
