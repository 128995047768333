(function () {
    'use strict';

    angular
        .module('core.tipo-comprovante')
        .config(configuration);

    configuration.$inject = [
        'bfcStateProvider'
    ];

    function configuration(
        stateProvider) {

        var tipo = {
            route: {
                url: '/tipo-comprovante',
                data: {
                    id: 'TipoComprovantePageMapping'
                },
                views: {
                    '@main': {
                        templateUrl: 'tipo-comprovante.html',
                        controller: 'core.TipoComprovanteController',
                        controllerAs: 'tipoComprovante'
                    }
                }
            }
        };

        stateProvider
            .from('core')
            .create('core-tipo-comprovante', tipo.route);
    }
})();