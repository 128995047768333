(function () {
    'use strict';
    /**
     * @ngdoc overview
     * @name contratos-core.grupos
     * @description
     * # contratos-core.grupos
     * Módulo de grupos de material
     **/
    angular
            .module('core.grupos', ['core', 'core.commons', 'core.classes']);
})();
