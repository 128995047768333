(function () {
    'use strict';

    angular
        .module('core.responsavel')
        .config(configuration);

    configuration.$inject = [
        'bfcStateProvider'
    ];

    function configuration(
        stateProvider) {

        var responsavel = {
            route: {
                url: '/responsavel',
                data: {
                    id: 'ResponsavelPageMapping'
                },
                views: {
                    '@main': {
                        templateUrl: 'responsavel.html',
                        controller: 'core.ResponsavelController',
                        controllerAs: 'lista'
                    }
                }
            }
        };

        stateProvider
            .from('core')
            .create('core-responsavel', responsavel.route);
    }
})();