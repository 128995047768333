(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name contratos-core.cargo.CargoSelect
   * @descripition
   * Serviço de cargo do Select2
   * @requires bfc.Select2Config
   * @requires CargoService
   **/
  angular
    .module('core.cargo')
    .service('core.CargoSelect', CargoSelect);

  CargoSelect.$inject = [
    'bfc.Select2',
    'core.CargoService',
    'CONFIG_SELECT2'
  ];

  function CargoSelect(Select2,
                       CargoService,
                       CONFIG_SELECT2) {

    angular.extend(this, {
      select2Config: select2Config
    });

    function select2Config(options) {

      var methods = {
        formatValue: formatValue,
        formatResult: formatResult,
        formatSelection: formatSelection,
        initSelection: initSelection,
        id: id,
        onAdd: onAdd,
        result: _.debounce(result, CONFIG_SELECT2.DELAY),
        minimumInputLength: CONFIG_SELECT2.MINIMUMINPUT,
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT('CARGO'),
        dropdownAutoWidth: false
      };

      function formatValue(cargo) {
        if (cargo && cargo.plain) {
          return cargo.plain();
        }
        return cargo;
      }

      function formatResult(cargo) {
        return cargo.text ? cargo.text : cargo.nome;
      }

      function formatSelection(cargo) {
        return cargo.nome;
      }

      function initSelection(element, callback) {
        var cargo = {
          nome: element.val()
        };
        callback(cargo);
      }

      function id(cargo) {
        return cargo.nome ? cargo.nome : cargo.id;
      }

      function onAdd(value, target) {
        Select2.set(target, value);
        return false;
      }

      function result(params, callback) {
        var parametros = {};
        //parametros.filter = '(nome like "' + params.term + '")';
        var retorno = CargoService.getList(parametros);

        retorno.then(function (cargos) {
          callback({
            results: cargos,
            more: cargos.hasNext
          });
        });
        return retorno;
      }

      return Select2.create(angular.extend(methods, options));
    }
  }
})();
