(function() {
	'use strict';
	/**
	 * @ngdoc overview
	 * @name contratos-core.tipo-comprovante
	 * @description
	 * #contratos-core.tipo-comprovante
	 * Módulo de tipos de comprovantes
	 **/
	angular
	  .module('core.tipo-comprovante', ['core', 'core.commons']);
})();
