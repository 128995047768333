(function () {
    'use strict';

    angular
        .module('core.local-entrega')
        .config(configuration);

    configuration.$inject = [
        'bfcStateProvider'
    ];

    function configuration(
        stateProvider) {

        var local = {
            route: {
                url: '/local-entrega',
                data: {
                    id: 'LocalEntregaPageMapping'
                },
                views: {
                    '@main': {
                        templateUrl: 'local-entrega.html',
                        controller: 'core.LocalEntregaController',
                        controllerAs: 'vm'
                    }
                }
            }
        };

        stateProvider
            .from('core')
            .create('core-local-entrega', local.route);
    }
})();