(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name contratos-core.grupos.GruposService
     * @descripition
     * Serviço de grupos de materiais
     * @requires Restangular
     * @requires $q
     **/
    angular
        .module('core.grupos')
        .service('core.GruposService', GruposService);

    GruposService.$inject = [
        '$q',
        'Restangular',
        'bfc.Notification',
        'ui.components.EventEmitter',
        'CamposAdicionaisService'
    ];

    function GruposService($q,
                           Restangular,
                           Notification,
                           EventEmitter,
                           CamposAdicionaisService) {
        var meta,
            uri = {
                GRUPOS: 'grupos',
                CAMPOS_ADICIONAIS: 'grupos/campos-adicionais'
            },
            service = new EventEmitter();

        _.extend(service, GruposService);

        service.get = get;
        service.getList = getList;
        service.getDefault = getDefault;
        service.save = save;
        service.getTypes = getTypes;
        service.remove = remove;
        service.getCamposAdicionais = getCamposAdicionais;

        return service;

        function get(id) {
            return Restangular.one(uri.GRUPOS, id).get();
        }

        function getList(params) {
            params = params || {};
            params.offset = params.offset || 0;
            return Restangular.one(uri.GRUPOS)
                .get(params);
        }

        function getMetadata() {
            var deferred = $q.defer();
            if (!meta) {
                Restangular.all(uri.GRUPOS).options().then(function (data) {
                    meta = data;
                    deferred.resolve(meta);
                }, function (data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }

        function getDefault() {
            return getMetadata().then(function (data) {
                return angular.copy(data.representation.default);
            });
        }

        function getTypes() {
            return getMetadata().then(function (data) {
                return angular.copy(data.types);
            });
        }

        function save(data) {
            var rest = Restangular.all(uri.GRUPOS);
            return !data.id ?
                rest.post(data).then(emitDirty) :
                rest.customPUT(data, data.id).then(emitDirty);
        }

        function remove(id) {
            return Restangular.one(uri.GRUPOS, id)
                .remove()
                .then(emitDirty);
        }

        function emitDirty(data) {
            service.emit('dirty');
            return data;
        }

        function getCamposAdicionais(grupoId) {
            return CamposAdicionaisService.get({
                path: uri.CAMPOS_ADICIONAIS,
                origemId: grupoId
            }).then(function (response) {
                return response.data;
            });
        }
    }
})();
