(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name contratos-core.cargo.service:CargoModalService
     *
     * @description
     * Serviço para abrir o cadastro de cargos
     *
     * @requires ModalCadFactory
     **/
    angular
            .module('core.cargo')
            .service('core.CargoModalService', CargoModalService);

    CargoModalService.$inject = ['ui.components.Modal'];

    function CargoModalService(uiModal) {

        var modal = {
            templateUrl: 'cargo-cad/cargo-cad.html',
            controller: 'core.CargoCadController',
            controllerAs: 'cargoCad',
            params: {
                CargoId: null
            },
            size: 'xm'
        };

        var service = {
            cadastrar: cadastrar,
            editar: editar,
            onAdd: onAdd
        };

        angular.extend(this, service);

        function abrir(modal) {
            return uiModal.open(modal).result;
        }

        function copia(modal) {
            return angular.copy(modal);
        }

        function onAdd(params) {
            var m = copia(modal);
            m.params = {
                CargoId: null,
                params: params
            };
            return uiModal.open(m).result;
        }

        function cadastrar() {
            var m = copia(modal);
            abrir(m);
        }

        function editar(cargo) {
            var m = copia(modal);
            m.params = {
                CargoId: cargo.id
            };
            abrir(m);
        }
    }
})();
