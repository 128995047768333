(function () {
	'use strict';
	angular
		.module('core.material')
		.controller('core.CodigoBarraCadController', CodigoBarraCadController);

	CodigoBarraCadController.$inject = [
		'$injector',
		'$modalInstance'
	];

	function CodigoBarraCadController(
		$injector,
		$modalInstance) {

		var viewModel = this;

		//Usado para teste Unitário
		viewModel.argumentos = CodigoBarraCadController.$inject;
		viewModel.cadastro = $modalInstance.params.params;
		viewModel.addCodigoBarra = addCodigoBarra;
		viewModel.removeCodigoBarra = removeCodigoBarra;
		viewModel.continuar = continuar;

		function continuar() {
			$modalInstance.close();
		}

		function addCodigoBarra(especificacao) {
			if (angular.isUndefined(especificacao)) {
				especificacao = {
					descricao: undefined
				};
			}

			viewModel.cadastro.codigoBarra.push(especificacao);
		}

		function removeCodigoBarra(index) {
			viewModel.cadastro.codigoBarra.splice(index, 1);
		}
	}
})();
