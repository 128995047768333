(function () {
    'use strict';
    /**
     * @ngdoc overview
     * @name contratos-core.classes
     * @description
     * # contratos-core.classes
     * Módulo de classes de materiais
     * @requires ctr.grupos
     **/
    angular
            .module('core.classes', ['core', 'core.commons', 'core.natureza-despesa', 'core.integracao']);
})();
