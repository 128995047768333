(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name contratos-core.grupos.service:GruposModalService
   * @description Serviço para abrir o cadastro de Grupos
   * @requires ModalCadFactory
   **/
  angular
    .module('core.grupos')
    .service('core.GruposModalService', GruposModalService);

  GruposModalService.$inject = ['core.AcoesCadastrosModalService'];

  function GruposModalService(AcoesCadastrosModalService) {

    var modal = {
      templateUrl: 'grupos-cad/grupos-cad.html',
      controller: 'core.GruposCadController',
      controllerAs: 'gruposCad',
      params: {
        identificador_cadastro: null
      },
      size: 'lg'
    };

    var service = {
      cadastrar: cadastrar,
      editar: editar,
      onAdd: onAdd
    };

    angular.extend(this, service);

    function abrir(modal) {
      return AcoesCadastrosModalService.abrir(modal);
    }

    function cadastrar() {
      AcoesCadastrosModalService.cadastrar(modal);
    }

    function editar(material) {
      AcoesCadastrosModalService.editar(modal, material);
    }

    function onAdd(params) {
      return AcoesCadastrosModalService.onAdd(modal, params);
    }
  }
})();
