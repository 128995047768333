(function () {
	'use strict';

	/**
	 * @ngdoc controller
	 * @name contratos-core.responsavel.controller:ResponsavelController
	 * @description Controller da página principal de responsável
	 * @requires bfc.dialog.Dialog
	 * @requires TrackerFactory
	 **/
	angular
		.module('core.responsavel')
		.controller('core.ResponsavelController', ResponsavelController);

	ResponsavelController.$inject = [
		'$rootScope',
		'$scope',
		'core.TrackerFactory',
		'core.ResponsavelService',
		'core.ResponsavelCadModalService',
		'core.ResponsavelDesativarModalService',
		'ContratosConfirmService',
		'bfc.Notification'

	];

	function ResponsavelController(
		$rootScope,
		$scope,
		TrackerFactory,
		ResponsavelService,
		ResponsavelCadModalService,
		ResponsavelDesativarModalService,
		ContratosConfirmService,
		Notification
	) {
		var viewModel = this;

		// Usado para teste Unitário
		viewModel.argumentos = ResponsavelController.$inject;
		viewModel.service = ResponsavelService;
		viewModel.selectedFilter = 'PADRAO';
		viewModel.isFilterInativo = isFilterInativo;
		viewModel.ativarDesativarRegistros = ativarDesativarRegistros;
		viewModel.isFilterAtivo = isFilterAtivo;
		viewModel.listControls = {};


		inicializa();

		function inicializa() {
			inicializaPropriedades();
			inicializaMetodos();
			inicializaLoadings();
			escutaEventos();
		}

		function escutaEventos() {
			$scope.$on('responsavel:actionRemove', function (eventos, params) {
				angular.element('#btnExcluir-' + params.id).triggerHandler('click');
			});
		}

		function inicializaPropriedades() {
			viewModel.showCheckBox = false;
			viewModel.loadings = {};
			viewModel.argumentos = ResponsavelController.$inject;
			viewModel.responsavel = [];
			viewModel.filter = {};
			viewModel.columns = [
				{
					id: 'nome',
					model: 'nome',
					label: 'Nome',
					filterable: true,
					alwaysVisible: false
				},

				{
					id: 'cpf',
					model: 'cpf',
					label: 'CPF',
					filterable: true,
					alwaysVisible: false
				},

				{
					id: 'situacao',
					model: 'situacao',
					label: 'Situação',
					filterable: false,
					alwaysVisible: true
				}
			];
		}

		function inicializaMetodos() {
			viewModel.cadastrar = cadastrar;
			viewModel.editar = editar;
			viewModel.excluir = excluir;
		}

		function inicializaLoadings() {
			viewModel.loadings = TrackerFactory.init(['listagem', 'getList', 'exclusao']);
			viewModel.loadings.responsavel = [];
		}

		function cadastrar() {
			ResponsavelCadModalService.cadastrar();
		}

		function editar(responsavel) {
			if (!responsavel.padrao) {
				ResponsavelCadModalService.editar(responsavel);
			}
		}

		function excluir(responsavel) {
			var promise = ResponsavelService.remove(responsavel.id);
			return promise.then(function () {
				ResponsavelService.mensagemExclusao();
			}, function error() {
				return promise;
			});
		}


		viewModel.modalConfirmarAtivarDesativarRegistros = function () {
			const ids = viewModel.itensSelecionados.map(function (ids) {
				return ids.id;
			});

			const atualizador = viewModel.listControls;
			ResponsavelDesativarModalService.cadastrar(ids, atualizador)
		}

		function ativarDesativarRegistros(status, params) {
			const ids = viewModel.itensSelecionados.map(function (ids) {
				return ids.id;
			});
			const promise = ResponsavelService.DesativarEmLote(ids, status, params)
			promise.then(function () {
				const acao = status ? 'inativados' : 'ativados' ;
				Notification.publish('Responsáveis ' + acao + ' com sucesso', 'success');
				viewModel.listControls.search();
			});
		}


		function isFilterAtivo() {
			return viewModel.selectedFilter === 'ATIVO';
		}

		function isFilterInativo() {
			return viewModel.selectedFilter === 'INATIVO';
		}



		$scope.$watch('lista.page', function (newValue, oldValue) {
			if (newValue.total == undefined || newValue.total == oldValue.total) {
				return;
			}

			var total = newValue.total
			$rootScope.$broadcast('filtroRegistroPadrao:AtualizarTotal', total)
		})


		viewModel.listCustomBuilder = {
			onBeforeBuild: function () {
				return '';
			},
		}


		$scope.$on('filtroRegistroPadrao:refresh', function (eventos, params) {
			viewModel.listCustomBuilder.onBeforeBuild = function () {
				viewModel.selectedFilter = params.filtro
				return params.criterio;
			};
			var showCheckBox_ = _.includes(['ATIVO', 'INATIVO'], params.filtro);

			if (showCheckBox_ !== viewModel.showCheckBox) {
				viewModel.showCheckBox = showCheckBox_;
				$rootScope.$broadcast('lista:relink-ui-list-grid');
			} else {
				viewModel.listControls.search();
			}
		});
	}

})();
