(function() {
    'use strict';
    /**
     * @ngdoc overview
     * @name contratos-core.prazo-entrega
     * @description
     * # contratos-core.prazo-entrega
     * Módulo de locais de entrega
     **/
    angular
            .module('core.prazo-entrega', ['core', 'core.commons']);
})();
