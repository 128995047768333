(function () {
    'use strict';
    angular
        .module('core.material')
        .controller('core.MaterialDesativarController', MaterialDesativarController);

    MaterialDesativarController.$inject = [
        '$scope',
        'promiseTracker',
        '$filter',
        'core.MaterialService',
        'bfc.Notification',
        'core.MaterialCadModalService',
        'core.TrackerFactory',
        '$searchExpressionBuilder',
        'core.DesativarModalService',
        '$rootScope'
    ];

    function MaterialDesativarController(
        $scope,
        promiseTracker,
        $filter,
        MaterialService,
        Notification,
        MaterialCadModalService,
        TrackerFactory,
        $searchExpressionBuilder,
        DesativarModalService,
        $rootScope) {

        var viewModel = this;
        viewModel.service = MaterialService;
        viewModel.serviceModalDesativar = DesativarModalService;
        viewModel.itensSelecionados = [];
        viewModel.listGridControls = {};
        viewModel.columns = [];
        viewModel.itensSelecionadosAtivos = [];
        viewModel.condensed = false;
        viewModel.sourceCustomParams = {
            json: ''
        };


        var customBuilders = {
            ativo: function () {
                if (viewModel.filtro) {
                    return 'ativo is ' + viewModel.filtro;
                }
            }
        };

        viewModel.customBuilder = {
            onBeforeBuild: function () {
                if (viewModel.filter) {
                    viewModel.filter.others.ativo = undefined;
                }
                return $searchExpressionBuilder.build(viewModel.filter, customBuilders);
            }
        };


        //Usado para teste Unitário
        viewModel.argumentos = MaterialDesativarController.$inject;

        viewModel.inativaItens = inativaItens;
        init();

        function init() {
            viewModel.loadings = TrackerFactory.init(['listagem', 'exclusao', 'listagemEntidadesValores']);
        }


        function inativaItens(dataInativacao) {
            $rootScope.$broadcast('material:inativaMaterial', dataInativacao);
        }

    }
})();
