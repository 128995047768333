(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name contratos-core.material.TipoCombustivelSelect
     * @descripition
     * Serviço do select2 para tipoCombustivel do material ou equipamento
     * @requires bfc.Select2
     * @requires MaterialService
     **/
    angular
        .module('core.material')
        .service('core.TipoCombustivelSelect', TipoCombustivelSelect);

    TipoCombustivelSelect.$inject = [
        'bfc.Select2',
        'core.MaterialService',
        'CONFIG_SELECT2'
    ];

    function TipoCombustivelSelect(Select2,
                                   MaterialService,
                                   CONFIG_SELECT2) {

        this.select2Config = select2Config;

        function select2Config(options) {
            var methods = {
                id: id,
                formatResult: options.formatResult ? options.formatResult : formatResult,
                formatSelection: options.formatSelection ? options.formatSelection : formatSelection,
                formatValue: options.formatValue ? options.formatValue : formatValue,
                initSelection: options.initSelection ? options.initSelection : initSelection,
                result: _.debounce(options.result ? options.result : result, CONFIG_SELECT2.DELAY),
                maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT(),
                dropdownAutoWidth: false
            };

            function id(tipoCombustivel) {
                return tipoCombustivel.description ? tipoCombustivel.description : tipoCombustivel.key;
            }

            function formatResult(tipoCombustivel) {
                return tipoCombustivel.description;
            }

            function formatSelection(tipoCombustivel) {
                return tipoCombustivel && tipoCombustivel.description;
            }

            function formatValue(tipoCombustivel) {
                return tipoCombustivel.key;
            }

            function initSelection(element, callback) {
                var id = element.val();

                if (id) {
                    MaterialService.getTipoCombustivel(id).then(callback);
                }
            }

            function result(params, callback) {
                var filter = params && params.term;
                var result = MaterialService.getTiposCombustiveis(filter);
                result.then(function (tipoCombustivel) {
                    var tipos = angular.copy(tipoCombustivel);
                    tipos.splice(6, 1); //Remove a opção Não se aplica
                    callback({
                        results: tipos,
                        more: tipoCombustivel.hasNext
                    });
                });
                return result;
            }

            return Select2.create(angular.extend(methods, options));
        }
    }
})();