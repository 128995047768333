(function () {
  'use strict';

  /**
   * @ngdoc controller
   * @name contratos-core.grupos.controller:GruposCadController
   * @description
   * GruposCadController de ctr.grupo
   * @requires $rootScope
   * @requires $scope
   * @requires $modalTracker
   * @requires $modalInstance
   * @requires bfc.FocusCtrl
   * @requires TrackerFactory
   * @requires GruposService
   * @requires ClassesService
   * @param {Number} grupoId ID do grupo para editar, quando `undefined` representa que é um cadastro para um novo grupo
   */
  angular
    .module('core.grupos')
    .controller('core.GruposCadController', GruposCadController);

  GruposCadController.$inject = [
    // 1°: Dependências de serviços do Angular
    '$scope',
    '$rootScope',
    '$q',
    '$modalTracker',
    '$modalInstance',
    // 2°: Dependências do BFC
    'bfc.FocusCtrl',
    // 3°: Dependências de ctr.utils
    'core.TrackerFactory',
    // 4°: Dependências do Projeto
    'core.GruposService',
    'core.ClassesService',
    'core.ClassesCadModalService',
    'bfc.Notification',
    'CamposAdicionaisService'
  ];

  function GruposCadController($scope,
                               $rootScope,
                               $q,
                               $modalTracker,
                               $modalInstance,
                               Focus,
                               TrackerFactory,
                               GruposService,
                               ClassesService,
                               ClassesCadModalService,
                               Notification,
                               CamposAdicionaisService) {

    var viewModel = this;
    var grupoDefault = {};

    //Usado para teste Unitário
    viewModel.argumentos = GruposCadController.$inject;

    // Métodos do Controller
    viewModel.getCadModeName = getCadModeName;
    viewModel.isRemoveClasse = isRemoveClasse;
    viewModel.removeClasse = removeClasse;
    viewModel.editarClasse = editarClasse;
    viewModel.cancelRemove = cancelRemove;
    viewModel.addClasse = addClasse;
    viewModel.cancel = cancel;
    viewModel.save = save;
    viewModel.hasClasseId = hasClasseId;
    viewModel.isEmptyClasses = isEmptyClasses;
    viewModel.isEmptyClassesFiltrados = isEmptyClassesFiltrados;
    viewModel.isAddNew = isAddNew;
    viewModel.cantSave = cantSave;
    viewModel.emUso = emUso;
    viewModel.addNewClasse = addNewClasse;
    viewModel.excluir = excluir;
    viewModel.existeGrupo = existeGrupo;
    viewModel.getGrupoId = getGrupoId;
    viewModel.setFocus = setFocus;


    initTrackers();
    init();

    function init() {
      initProperties();
      initModal();
      inicializarEscutaEventos();
      inicializaCamposAdicionais();
      viewModel.visivel = 1;
      viewModel.rowSelected = [];
    }

    // --- Métodos inicializadores ---
    function inicializarEscutaEventos() {
      $scope.$on('grupoClassesCad:UpdateList', function (event) {
        initGrupo();
      });
      $scope.$on('grupoClassesCad:AddClasse', function (event, classe) {
        addClasse(classe);
      });
      $scope.$on('grupoClassesCad:UpdateClasse', function (event, classe, classeIndex) {
        updateClasse(classe, classeIndex);
      });
      $scope.$on('grupoClassesCad:Remove', function (event, classe, index) {
        directRemove(index, classe);
      });

    }

    function initProperties() {
      viewModel.grupo = {};
      viewModel.grupo.classes = {};
    }

    function initTrackers() {
      viewModel.tracker = TrackerFactory.init(['save', 'saveAdd', 'excluir']);
    }

    function initTypes() {
      var promise = GruposService.getTypes();
      promise.then(function (data) {
        viewModel.gruposProperties = data.GrupoBO;
        viewModel.classesProperties = data.ClasseBO;
//                ClassesService.setMetadata(data.ClasseDto);
//                $rootScope.$broadcast('grupoClassesCad:ClassesProperties', viewModel.classesProperties);
      });
      return promise;
    }

    function initGrupo() {
      var promise;
      if ($modalInstance.params.identificador_cadastro) {
        promise = GruposService.get($modalInstance.params.identificador_cadastro);
        promise.then(function (grupos) {
          viewModel.grupo = grupos;
          // Classe adicionada por algum select2
          if ($modalInstance.params.params && $modalInstance.params.params.classe) {
            addClasse($modalInstance.params.params.classe);
          }
        });
      } else {
        promise = GruposService.getDefault();
        promise.then(function (grupo) {
          if ($modalInstance.params.params && $modalInstance.params.params.grupo) {
            grupo = _.merge(grupo, {descricao: $modalInstance.params.params.grupo});
            viewModel.form.$setDirty();
          }
          viewModel.grupo = grupo;
//                    addClasse();
        });
      }
      return promise;
    }

    function initModal() {
      var promise = $q.all([
        initTypes(),
        initGrupo()
      ]);
      $modalTracker.addPromise(promise);
    }

    function inicializaCamposAdicionais() {
      var promise = GruposService.getCamposAdicionais($modalInstance.params.identificador_cadastro)
          .then(function(data) {
            viewModel.campoAdicional = data;
          });

      return promise;
    }

    function addNewClasse(grupoId) {
      ClassesCadModalService.cadastrar(grupoId);
    }

    function editarClasse(classe, grupoId, classeIndex) {
      ClassesCadModalService.editar(classe, classeIndex, grupoId ? grupoId : null);
    }

    function addClasse(classe) {
      if (classe === undefined) {
        classe = {
          descricao: undefined,
          especificacao: undefined
        };
      }
      viewModel.grupo.classes.push(classe);
      viewModel.form.$setDirty();
    }

    function updateClasse(classe, classeIndex) {
      if (classe === undefined)
        return;
      var classeExistente;
      if (classe.id) {
        classeExistente = _.find(viewModel.grupo.classes, {'id': classe.id});
      }
      if (classeExistente) {
        angular.forEach(classeExistente, function (value, key) {
          delete classeExistente[key];
        });

        _.merge(classeExistente, classe);
        viewModel.form.$setDirty();
      } else if ((classeIndex !== undefined || classeIndex !== null) && (viewModel.grupo.classes[classeIndex].id === undefined ||
        viewModel.grupo.classes[classeIndex].id === null)) {
        classeExistente = viewModel.grupo.classes[classeIndex];
        angular.forEach(classeExistente, function (value, key) {
          delete classeExistente[key];
        });

        _.merge(classeExistente, classe);
        viewModel.form.$setDirty();

      }
      //Código para atualizar a máscara da lista de classes
      viewModel.grupo.classes.push(" ");
      setTimeout(function () {
        $scope.$apply(function () {
          viewModel.grupo.classes.pop();
        });
      }, 10);
    }

    function getCadModeName() {
      return (viewModel.grupo.id !== undefined) ? 'Editando' : 'Adicionando';
    }

    function existeGrupo() {
      return Boolean(getGrupoId());
    }

    function getGrupoId() {
      return (viewModel.grupo.id === undefined) ? $modalInstance.params.identificador_cadastro : viewModel.grupo.id;
    }

    function isRemoveClasse() {
      return viewModel.grupo.classes.length >= 1;
    }

    function removeClasse(index, classe) {
      actionRemove(index, classe);
      viewModel.form.$setDirty();
    }

    function directRemove(index, classe) {
      if ($modalInstance.params.identificador_cadastro && classe.id) {
        var promise = ClassesService.remove($modalInstance.params.identificador_cadastro, classe.id);
        promise.then(function () {
          actionRemoveInterface(index);
        });
        viewModel.tracker.excluir.addPromise(promise);
      } else {
        actionRemoveInterface(index);
      }
    }

    function actionRemove(index, classe) {
      /*if ($modalInstance.params.identificador_cadastro && classe.id) {
       classe.registro = 'REMOVIDO';
       } else {*/
      actionRemoveInterface(index);
      //}
    }

    function cancelRemove(index, classe) {
      actionCancelRemove(index, classe);
    }

    function actionCancelRemove(index, classe) {
      if ($modalInstance.params.identificador_cadastro && classe.id) {
        //classe.registro = undefined;
      } else {
        actionRemoveInterface(index);
      }
    }

    function actionRemoveInterface(index) {
      viewModel.grupo.classes.splice(index, 1);
    }

    function hasClasseId(classe) {
      return classe.id;
    }

    function isEmptyClasses() {
      return viewModel.grupo && viewModel.grupo.classes.length === 0;
    }

    function isEmptyClassesFiltrados(filtrado) {
      return viewModel.grupo && filtrado && filtrado.length === 0;
    }

    function cancel(grupo) {
      $modalInstance.close(grupo);
    }

    function sendGrupoList(grupo) {
      $rootScope.$broadcast('gruposCad:list', grupo);
    }

    function save(continuar) {
      var tracker = continuar ? viewModel.tracker.saveAdd : viewModel.tracker.save;

      if (viewModel.campoAdicional)
        viewModel.grupo.campoAdicional = viewModel.campoAdicional;

      var promise = GruposService.save(viewModel.grupo);
      promise.then(function (grupo) {
        ClassesService.setGrupoId(grupo.id);
        if ($modalInstance.params.identificador_cadastro) {
          GruposService.get($modalInstance.params.identificador_cadastro).then(function (grupoClasse) {
            sendGrupoList(grupoClasse);
          });
        } else {
          sendGrupoList(grupo);
        }
        if (continuar) {
          if (viewModel.form) {
            viewModel.form.$setPristine();
            viewModel.form.$setUntouched();
          }
          $modalInstance.params.identificador_cadastro = undefined;
          init();
          Focus.set('lbGruposCadControllerDescricao');
        } else {
          cancel(grupo);
        }
        var mensagem = $modalInstance.params.identificador_cadastro ? 'alterado' : 'salvo';
        Notification.publish('Grupo ' + mensagem + ' com sucesso', 'success');
      });
      tracker.addPromise(promise);
    }

    function cantSave() {
      //return (viewModel.form.$dirty && viewModel.form.$invalid) || viewModel.form.$pristine;
      return (viewModel.form.$dirty && viewModel.form.$invalid)
        || (viewModel.form.$pristine && !$modalInstance.params.identificador_cadastro)
        || !CamposAdicionaisService.isFormValid(viewModel.campoAdicional);
    }

    function isAddNew() {
      return angular.isDefined();
    }

    function emUso() {
      return viewModel.grupo.emUso;
    }

    function excluir() {
      $rootScope.$broadcast('grupos:actionRemove', {id: viewModel.grupo.id});
      cancel();
    }

    function setFocus($index) {
      viewModel.rowSelected = [];
      viewModel.rowSelected[$index] = true;
  }

  }
})();
