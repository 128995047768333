(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ctr.local-entrega.service:LocalEntregaSelect
   *
   * @descripition
   * Serviço de local-entrega do Select2
   *
   * @requires bfc.Select2
   * @requires LocalEntregaService
   **/
  angular
    .module('core.local-entrega')
    .service('core.LocalEntregaSelect', LocalEntregaSelect);

  LocalEntregaSelect.$inject = [
    'bfc.Select2',
    'core.LocalEntregaService',
    'CONFIG_SELECT2'
  ];

  function LocalEntregaSelect(Select2,
                              LocalEntregaService,
                              CONFIG_SELECT2) {

    this.select2Config = select2Config;

    function select2Config(options) {
      var methods = {
        formatResult: options.formatResult ? options.formatResult : formatResult,
        formatSelection: options.formatSelection ? options.formatSelection : formatSelection,
        formatValue: options.formatValue ? options.formatValue : formatValue,
        initSelection: options.initSelection ? options.initSelection : initSelection,
        result: _.debounce(options.result ? options.result : result, CONFIG_SELECT2.DELAY),
        minimumInputLength : CONFIG_SELECT2.MINIMUMINPUT,
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT('LOCAL_ENTREGA'),
        dropdownAutoWidth: false
      };

      function formatResult(localEntrega) {
        return getDescription(localEntrega);
      }

      function formatSelection(localEntrega) {
        return getDescription(localEntrega);
      }

      function formatValue(localEntrega) {
        return localEntrega.id;
      }

      function initSelection(element, callback) {
        var id = element.val();

        if (Number(id)) {
          LocalEntregaService.get(id).then(callback);
        }
      }

      function result(params, callback) {
        var filter;

        if (params.term) {
          filter = '(descricao like "' + params.term.replace(/['"]+/g, '') + '")';
        }
        var result = LocalEntregaService.getList(filter);
        result.then(function (locaisEntrega) {
          callback({
            results: locaisEntrega,
            more: locaisEntrega.hasNext
          });
        });
        return result;
      }

      function getDescription(localEntrega) {
        return (localEntrega && localEntrega.text) ? localEntrega.text : localEntrega.descricao;
      }

      return Select2.create(angular.extend(methods, options));
    }
  }
})();
